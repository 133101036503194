import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getColumnsLocalFreights } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { useForm } from "../helpers/useForm";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Select from "../components/Select";
import Input from "../components/Input";
import "../App";
import "../App.css";
import { parseCat } from "../helpers/utils";

const LocalFreightList = () => {
  const [allData, setAllData] = useState([]);
  const [nestedData, setNestedData] = useState([]);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const [searchSec, setSearchSec] = useState("");
  const navigate = useNavigate();
  const { catPaymentStatus } = useSelector((s) => s.catalogues);
  const [filters, setFilters, resetFilters] = useForm({
    idCatPaymentStatus: "",
  });
  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/localfreight?${filterStr}`, null, "GET");
    setAllData(res.data);
    setData(res.data);
  }, []);
  const handleSecSearch = (lookupField, searchValue) => {
    setSearchSec(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields =
      lookupField === "all"
        ? [
            "emmiter",
            "statuspayment",
            "ejido",
            "freighter",
            "folioBallot",
            "infoVehicle",
          ]
        : [lookupField];
    nestedData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const handleFirstSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    setSearchSec("");
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields =
      lookupField === "all"
        ? [
            "emmiter",
            "statuspayment",
            "ejido",
            "freighter",
            "folioBallot",
            "infoVehicle",
          ]
        : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
    setNestedData(filteredData);
  };
  const dtFuncs = {};
  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);

  return (
    <>
      <Menu />
      <div
        className="grid grid-cols-1 gap-4 mx-auto mt-8 containerList"
        style={{ width: "90%" }}
      >
        <div className="grid grid-cols-2 containerTituloBtn">
          <PageTitle>LISTA DE FLETES LOCALES</PageTitle>
          <div className="w-full">
            <Button
              style={{ display: "none" }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 inputsForm">
          <Input
            label="Primer filtro"
            onChange={(e) => {
              handleFirstSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Búsqueda alfanumérica"
          />
          <Input
            label="Segundo filtro"
            onChange={(e) => {
              handleSecSearch("all", e.target.value);
            }}
            name="searchSec"
            required={false}
            value={searchSec}
            placeholder="Búsqueda alfanumérica"
          />
          {
            <Select
              arrOptions={[
                { value: "", label: "Todos" },
                ...parseCat(catPaymentStatus, "name", "idCatPaymentStatus"),
              ]}
              required={true}
              name="idCatPaymentStatus"
              onChange={setFilters}
              value={filters.idCatPaymentStatus}
              label="Estatus de pago"
            />
          }
          <Button
            style={{ placeSelf: "end" }}
            className={"w-4/5 px-1 mt-14 mb-2 align-right text-center"}
          >
            Generar pago
          </Button>
        </div>
        <NtaDataTable
          data={data}
          columnsDef={getColumnsLocalFreights(dtFuncs)}
        />
      </div>
    </>
  );
};

export default LocalFreightList;
