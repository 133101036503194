import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import { calculateFreightSchema, behaviors } from "../helpers/schemas";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  parseCat,
  parseReverseOrigin,
  parseReverseDestination,
  parseOrigin,
  parseDestination,
} from "../helpers/utils";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import { Request } from "../helpers/api";
import { useSelector } from "react-redux";
import "../App";
import "../App.css";

const FreightCalculationInsert = () => {
  const [
    newCalculation,
    newCalculationChange,
    newCalculationReset,
    setAllCalculation,
  ] = useForm(calculateFreightSchema);

  const { id: idFreight } = useParams();
  const navigate = useNavigate();
  const [destinations, setDesintations] = useState([]);
  const [origins, setOrigins] = useState([]);
  const catalogues = useSelector((s) => s.catalogues);
  const { catWarehouse, ejidos, catVehicles } = catalogues;
  const [isLoading, setIsLoading] = useState(false);
  const [behavior, setBehavior] = useState(behaviors.insert);

  //Funcion para parsear el catalogo de origen y destino, ya que pueden ser ejidos o warehouse

  const fetchData = useCallback(async () => {
    const resC = await Request("/client/destinations", null, "GET");
    const parsedWarehouses = parseCat(
      catWarehouse?.map(({ idWarehouse, name }) => ({
        v: `${idWarehouse}_WAREHOUSE`,
        l: name,
      })),
      "l",
      "v"
    );
    const catOrigin = [
      { label: "-", value: "" },
      ...parseCat(
        ejidos
          ?.filter((e) => e.isActive)
          ?.map((e) => ({ v: `${e.idEjido}_EJIDO`, l: e.name })),
        "l",
        "v"
      ),
      ...parsedWarehouses,
    ];
    const catDestinations = [
      { label: "Elige un destino", value: "" },
      ...parseCat(
        resC.destinations.map((d) => ({
          v: d.idDestination + "_CLIENT",
          l: d.clientDestination,
        })),
        "l",
        "v"
      ),
      ...parsedWarehouses,
    ];
    setDesintations(catDestinations);
    setOrigins(catOrigin);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchInfo = useCallback(async () => {
    const res = await Request(
      `/boarding/freightquota/${idFreight}`,
      undefined,
      "GET"
    );
    const { ok, data } = res;
    if (ok) {
      setAllCalculation({
        ...data,
        ...parseReverseOrigin(data),
        ...parseReverseDestination(data),
      });
    }
  });
  useEffect(() => {
    if (idFreight) {
      setBehavior(behaviors.edit);
      fetchInfo();
    }
  }, []);

  const insertFreightCalculation = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    newCalculation["vehicle"] = newCalculation.idCatVehicle;
    const res = await Request(
      `/boarding/freightquota${
        behavior === behaviors.insert ? "" : "/" + idFreight
      }`,
      {
        ...newCalculation,
        ...parseOrigin(newCalculation),
        ...parseDestination(newCalculation),
      },
      behavior === behaviors.insert ? "POST" : "PUT"
    );
    setIsLoading(false);
    if (res.ok) {
      Swal.fire({
        title: `${
          behavior === behaviors.insert
            ? "Registro exitoso"
            : "Actualización exitosa"
        }`,
        icon: "success",
      }).then(() => {
        navigate("/lista-cuotas");
      });
    }
  };

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>CÁLCULO DE CUOTAS DE FLETES</PageTitle>
      </div>
      <NtaForm onSubmit={insertFreightCalculation}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
          <div>
            <Select
              arrOptions={origins}
              required={true}
              name="idOrigin"
              onChange={newCalculationChange}
              value={newCalculation.idOrigin}
              label="Origen"
            />
            <Input
              type="text"
              required={true}
              placeholder="Tonelaje"
              label="Tonelaje"
              name="tonnage"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.tonnage}
            />
          </div>
          <div>
            <Select
              arrOptions={destinations}
              name="idDestination"
              onChange={newCalculationChange}
              value={newCalculation.idDestination}
              label="Destino"
            />
            <Select
              arrOptions={[...parseCat(catVehicles, "name", "idCatVehicle")]}
              required={true}
              name="idCatVehicle"
              onChange={newCalculationChange}
              value={newCalculation.idCatVehicle}
              label="Vehículo"
            />
          </div>
        </div>

        <h3 className="grid w-4/5 mx-auto grid-cols-1 mt-12 text-xl font-bold text-ntaBlue">
          DATOS DEL FLETE
        </h3>

        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Input
              type="number"
              required={true}
              placeholder="Kilómetros"
              label="Kilómetros"
              name="kilometres"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.kilometres}
            />
            <Input
              type="number"
              required={true}
              placeholder="Operador"
              label="Operador"
              name="operator"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.operator}
            />
            <Input
              type="number"
              required={true}
              placeholder="Rendimiento"
              label="Rendimiento"
              name="performance"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.performance}
            />
            <Input
              type="number"
              required={true}
              placeholder="Desgaste/mantenimiento"
              label="Desgaste/mantenimiento"
              name="maintenance"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.maintenance}
            />
            <Input
              type="number"
              required={true}
              placeholder="Leasing"
              label="Leasing"
              name="leasing"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.leasing}
            />
            <Input
              type="number"
              required={true}
              placeholder="Adicionales"
              label="Adicionales"
              name="additional"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.additional}
            />
          </div>

          <div>
            <Input
              type="number"
              required={true}
              placeholder="Casetas"
              label="Casetas"
              name="tollbooth"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.tollbooth}
            />
            <Input
              type="number"
              required={true}
              placeholder="Diesel"
              label="Diesel"
              name="diesel"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.diesel}
            />
            {/*<Input
                type="number"
                required={true}
                placeholder="Vehículo"
                label="Vehículo"
                name="vehicle"
                maxLength={100}
                className={"grid w-full"}
                onChange={newCalculationChange}
                value={newCalculation.vehicle}
              />*/}

            <Select
              arrOptions={[...parseCat(catVehicles, "name", "idCatVehicle")]}
              required={true}
              name="idCatVehicle"
              onChange={newCalculationChange}
              value={newCalculation.idCatVehicle}
              label="Vehículo"
            />

            <Input
              type="number"
              required={true}
              placeholder="Seguro"
              label="Seguro"
              name="insurance"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.insurance}
            />
            <Input
              type="number"
              required={true}
              placeholder="Utilidad"
              label="Utilidad"
              name="utility"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.utility}
            />
            <Input
              type="number"
              required={true}
              placeholder="Básculas"
              label="Básculas"
              name="scales"
              maxLength={100}
              className={"grid w-full"}
              onChange={newCalculationChange}
              value={newCalculation.scales}
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4 inputsForm">
          <div />
          <div />
          <Button type="submit" disabled={isLoading}>
            {behavior === behaviors.insert
              ? "Añadir cálculo"
              : "Editar cálculo"}
          </Button>
        </div>
      </NtaForm>
    </>
  );
};

export default FreightCalculationInsert;
