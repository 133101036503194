import { GENERAL_PLATFORM } from "../actions";
import { initialPlatformSchema } from "../../helpers/schemas";

const INITIAL_STATE = { ...initialPlatformSchema };
const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GENERAL_PLATFORM:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default reducer;
