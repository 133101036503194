import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import noop from "../../helpers/noop";
import Button from "../Button";
import PageTitle from "../PageTitle";
import NtaForm from "../NtaForm";

const FreightPrinter = ({
  isOpen,
  toggle,
  onConfirm = noop,
  onCancel = noop,
}) => {
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} className={"mt-10"}>
      <NtaForm
        onSubmit={(e) => {
          e.preventDefault();
          toggle();
        }}
      >
        <ModalHeader>
          <div className="text-center my-6 place-items-end">
            <PageTitle className="mb-4 text-3xl">IMPRESIÓN DE EMBARQUE</PageTitle>
          </div>
        </ModalHeader>
        <ModalBody className="modalQualityCertificate my-10">

            {/*--------Datos de impresión--------*/}
            <div className="grid grid-cols-2">
                <div className="text-ntaBlue my-2 text-left text-xl ml-12">
                    <div className="my-4">
                        <span className="font-bold"> UNIDAD: </span>
                        <span className="font-normal"> MORELOS </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> ALMACÉN: </span>
                        <span className="font-normal"> MORELOS </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> TIPO DE CARGA: </span>
                        <span className="font-normal"> PARCIAL </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> PRODUCTO: </span>
                        <span className="font-normal"> SORGO </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> ORIGEN: </span>
                        <span className="font-normal"> AHUEHUEYO </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> DESTINO: </span>
                        <span className="font-normal"> TLAYECAC </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> CLIENTE: </span>
                        <span className="font-normal"> ALEJANDRO VIDAURRI </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> RFC: </span>
                        <span className="font-normal"> ALE938109YYY </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> ESTADO: </span>
                        <span className="font-normal"> MORELOS </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> C.P.: </span>
                        <span className="font-normal"> 59200 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> CONTACTO: </span>
                        <span className="font-normal"> FRANCISCO FLORES </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> TELÉFONO: </span>
                        <span className="font-normal"> 55 4444 9999 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> TICKET: </span>
                        <span className="font-normal"> 83291 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> PESO BRUTO: </span>
                        <span className="font-normal"> 34,970 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> PESO NETO: </span>
                        <span className="font-normal"> 12,330 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> TARA: </span>
                        <span className="font-normal"> 28,930 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> HUMEDAD: </span>
                        <span className="font-normal"> 13.7 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold mb-4"> OBSERVACIONES: </span><br/>
                        <span className="font-normal top-4 relative"> No se encontró ningún defecto </span>
                    </div>

                </div>

                {/*--------FECHA--------*/}
                <div className="text-ntaBlue my-2 text-xl mr-8 text-right">
                    <div className="my-4">
                        <span className="font-bold"> FECHA DE EMBARQUE: </span>
                        <span className="font-normal"> 05/07/2022 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> NO. CONSECUTIVO: </span>
                        <span className="font-normal"> 00295 </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> TIPO DE VEHÍCULO: </span>
                        <span className="font-normal"> TORTÓN </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> PLACAS: </span>
                        <span className="font-normal"> 19AB6R </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> PROPIETARIO: </span>
                        <span className="font-normal"> JEAN PADILLA </span>
                    </div>
                    <div className="my-4">
                        <span className="font-bold"> CHOFER: </span>
                        <span className="font-normal"> FERNANDO RIVERA </span>
                    </div>
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
          <div className="grid grid-cols-2 gap-5 relative float-right right-8">
            <Button
              type="button"
              onClick={onCancel}
              className={"bg-ntaGray text-lg"}
            >
              Cancelar
            </Button>
            <Button type="submit" className="text-lg">
              Imprimir
            </Button>
          </div>
        </ModalFooter>
      </NtaForm>
    </Modal>
  );
};

FreightPrinter.propTypes = {};

export default FreightPrinter;
