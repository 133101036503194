// External imports
import { Provider } from "react-redux";
import { store } from "./redux/store";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// Internal imports
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFromSession, validateSession } from "./helpers/storageHandler";
import { initialFetch } from "./redux/actions/auth";
import Login from "./Modules/Login";
import Home from "./Modules/Home";
import PersonInsert from "./Modules/PersonInsert";
import PersonList from "./Modules/PersonList";
import ClientInsert from "./Modules/ClientInsert";
import ClientList from "./Modules/ClientList";
import SupplierInsert from "./Modules/SupplierInsert";
import SupplierList from "./Modules/SupplierList";
import BallotList from "./Modules/BallotList";
import BallotGenerate from "./Modules/BallotGenerate";
import BallotCapture from "./Modules/BallotCapture";
import UnitInsert from "./Modules/UnitInsert";
import UnitList from "./Modules/UnitList";
import ProductInsert from "./Modules/ProductInsert";
import ProductList from "./Modules/ProductList";
import InactiveWarehouseList from "./Modules/InactiveWarehouseList";
import ProductReception from "./Modules/ProductReception";
import EjidoInsert from "./Modules/EjidoInsert";
import EjidoList from "./Modules/EjidoList";
import ReceptionList from "./Modules/ReceptionList";
import Inventory from "./Modules/Inventory";
import Destinations from "./Modules/Destinations";
import FreightInsert from "./Modules/FreightInsert";
import FreightList from "./Modules/FreightList";
import LocalFreightList from "./Modules/LocalFreightList";
import FreightCalculationInsert from "./Modules/FreightCalculationInsert";
import FreightCalculationList from "./Modules/FreightCalculationList";
import FreughtQuotationInsert from "./Modules/FreightQuotaInsert";
// Rutas de Finanzas
import RelationIssuersBallots from "./Modules/Finances/RelationIssuersBallots";
import BallotsIssuersPaymentsList from "./Modules/Finances/BallotsIssuersPaymentsList";
import AddPayment from "./Modules/Finances/AddPayment";
import PaymentsList from "./Modules/Finances/PaymentsList";
import InvociesList from "./Modules/Finances/InvoicesList";
import ApplyDiscountsBonuses from "./Modules/Finances/ApplyDiscountsBonuses";
import DepositsIssuers from "./Modules/Finances/DepositsIssuers";
import AssingQuotasList from "./Modules/Finances/AssignQuotasList";
import AssingQuotasInsert from "./Modules/Finances/AssingQuotasInsert";
// Rutas de Ventas
import OrderCapture from "./Modules/Sales/OrderCapture";
import CapturedOrdersList from "./Modules/Sales/CapturedOrdersList";
// Assets imports
import "./App.css";
import SeasonInsert from "./Modules/SeasonInsert";
import SeasonList from "./Modules/SeasonList";
import SalesList from "./Modules/Sales/SalesList";

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

const AppRouter = () => {
  const dispatch = useDispatch();
  const jwt = getFromSession("jwt");

  useEffect(() => {
    if (jwt) {
      dispatch(initialFetch(jwt));
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={validateSession() ? "/inicio" : "/login"} />}
        />
        <Route exact path="/inicio" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/nueva-persona" element={<PersonInsert />} />
        <Route exact path="/editar-persona/:id" element={<PersonInsert />} />
        <Route exact path="/personas" element={<PersonList />} />
        <Route exact path="/clientes" element={<ClientList />} />
        <Route exact path="/nuevo-cliente" element={<ClientInsert />} />
        <Route exact path="/editar-cliente/:id" element={<ClientInsert />} />
        <Route exact path="/proveedores" element={<SupplierList />} />
        <Route exact path="/nuevo-proveedor" element={<SupplierInsert />} />
        <Route
          exact
          path="/editar-proveedor/:id"
          element={<SupplierInsert />}
        />
        <Route exact path="/boletas" element={<BallotList />} />
        <Route exact path="/generar-boleta" element={<BallotGenerate />} />
        <Route exact path="/capturar-boleta/:id" element={<BallotCapture />} />
        <Route exact path="/editar-boleta/:id" element={<BallotCapture />} />
        <Route exact path="/nueva-unidad" element={<UnitInsert />} />
        <Route exact path="/editar-unidad/:id" element={<UnitInsert />} />
        <Route exact path="/unidades" element={<UnitList />} />
        <Route exact path="/nuevo-producto" element={<ProductInsert />} />
        <Route exact path="/editar-producto/:id" element={<ProductInsert />} />
        <Route exact path="/productos" element={<ProductList />} />
        <Route exact path="/nuevo-ejido" element={<EjidoInsert />} />
        <Route exact path="/ejidos" element={<EjidoList />} />
        <Route exact path="/editar-ejido/:id" element={<EjidoInsert />} />
        <Route exact path="/alta-cuotas" element={<FreughtQuotationInsert />} />
        <Route
          exact
          path="/almacenes-inactivos"
          element={<InactiveWarehouseList />}
        />
        <Route
          exact
          path="/recepcion-producto"
          element={<ProductReception />}
        />
        <Route
          exact
          path="/editar-recepcion/:id"
          element={<ProductReception />}
        />
        <Route exact path="/lista-recepciones" element={<ReceptionList />} />
        <Route exact path="/inventario" element={<Inventory />} />
        <Route exact path="/destinos" element={<Destinations />} />
        <Route exact path="/lista-embarques" element={<FreightList />} />
        <Route exact path="/alta-embarques" element={<FreightInsert />} />
        <Route exact path="/editar-embarque/:id" element={<FreightInsert />} />
        <Route
          exact
          path="/lista-fletes-locales"
          element={<LocalFreightList />}
        />
        <Route
          exact
          path="/calculo-cuotas"
          element={<FreightCalculationInsert />}
        />
        <Route
          exact
          path="/editar-calculo-cuotas/:id"
          element={<FreightCalculationInsert />}
        />
        <Route
          exact
          path="/lista-cuotas"
          element={<FreightCalculationList />}
        />
        <Route exact path="/temporalidad" element={<SeasonInsert />} />
        <Route exact path="/temporalidad/:id" element={<SeasonInsert />} />
        <Route exact path="/temporalidades" element={<SeasonList />} />
        {/*Rutas Finanzas */}
        <Route
          exact
          path="/relacion-emisores-boletas"
          element={<RelationIssuersBallots />}
        />
        <Route
          exact
          path="/boletas-emisores-pagos"
          element={<BallotsIssuersPaymentsList />}
        />
        <Route exact path="/generar-pago" element={<AddPayment />} />
        <Route exact path="/lista-pagos" element={<PaymentsList />} />
        <Route exact path="/lista-facturas" element={<InvociesList />} />
        <Route
          exact
          path="/descuentos-bonificaciones"
          element={<ApplyDiscountsBonuses />}
        />
        <Route exact path="/depositos-emisores" element={<DepositsIssuers />} />
        <Route
          exact
          path="/lista-asignar-cuotas"
          element={<AssingQuotasList />}
        />
        <Route exact path="/asignar-cuota" element={<AssingQuotasInsert />} />
        <Route
          exact
          path="/editar-cuota/:id"
          element={<AssingQuotasInsert />}
        />
        {/*Rutas Ventas */}
        <Route exact path="/capturar-pedidos" element={<OrderCapture />} />
        <Route exact path="/editar-pedido/:id" element={<OrderCapture />} />
        <Route
          exact
          path="/lista-pedidos-capturados"
          element={<CapturedOrdersList />}
        />
        <Route exact path="/lista-ventas" element={<SalesList />} />
      </Routes>
    </Router>
  );
};

export default App;
