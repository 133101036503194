import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { parseObjectDate } from "../../helpers/utils";
import Button from "../Button";
import noop from "../../helpers/noop";
import PageTitle from "../PageTitle";

const SupplierDetails = ({ isOpen, data, toggle, onConfirm = noop, onCancel = noop }) => {
  const rolesEmitter = {
    EMITTER: "Emisor",
    NONEMITTER: "No Emisor",
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>
        <PageTitle>DETALLE DE PROVEEDOR</PageTitle>
      </ModalHeader>
      <ModalBody>
        <table className="ntaTableDataSet">
          <tbody>
            <tr>
              <td>
                <strong>Rol: </strong>
                <span>{rolesEmitter[data.roleSupplier]}</span>
              </td>
              <td>
                <strong>Fecha de creación: </strong>
                <span>{parseObjectDate(new Date(data.createdAt), 3)}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Unidad: </strong>
                <span>{data.unit}</span>
              </td>
              <td>
                <strong>Ejido: </strong>
                <span>{data.ejido}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Nombre / Razón social: </strong>
                <span>{data.name}</span>
              </td>
              <td>
                <strong>RFC: </strong>
                <span>{data.identityKey}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Teléfono: </strong>
                <span>{data.phone}</span>
              </td>
              <td>
                <strong>Monto máximo: </strong>
                <span>$ {data.defBillingLimit}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Dirección: </strong>
                <span>{data.address}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Banco: </strong>
                <span>{data.nameBank}</span>
              </td>
              {data.idBank !== null && (
                <td>
                  <strong>Tipo de cuenta: </strong>
                  <span>{data.bankAccountType}</span>
                </td>
              )}
            </tr>
            {data.idBank !== null && (
              <tr>
                <td colSpan={2}>
                  <strong>Cuenta bancaria: </strong>
                  <span>{data.bankAccountKey}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 w-full btnModal">
          <div />
          <div />
          <Button
            onClick={() => {
              toggle(false);
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

SupplierDetails.propTypes = {};

export default SupplierDetails;
