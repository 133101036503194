import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "../helpers/useForm";
import { startLogin } from "../redux/actions/auth";
import logo from "../css/img/logoNTA.svg";
import visible from "../css/img/visible.svg";
import "../App";
import "../App.css";
import Swal from "sweetalert2";
import { Request } from "../helpers/api";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [keepSession, setKeepSession] = useState(false);
  const [formLoginValues, formOnChange] = useForm({
    username: "administrador@dgmx.com",
    password: "123456",
  });
  const { username, password } = formLoginValues;

  const handleLogin = async (e) => {
    e.preventDefault();
    await dispatch(
      startLogin(username, password, keepSession, () => {
        navigate("/inicio");
      })
    );
  };

  const handleRecover = async () => {
    Swal.fire({
      title: "¿OLVIDÓ SU CONTRASEÑA?",
      text: "Ingresa tu email",
      input: "text",
      confirmButtonText: "Solicitar",
      cancelButtonText: "Cancelar",
      inputValue: username,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Debes introducir un email";
        }
      },
    }).then(async ({ isConfirmed, value: email }) => {
      if (isConfirmed) {
        const res = await Request(
          `/user-recover-password?email=${email}`,
          null,
          "GET"
        );
        if (res.ok) {
          Swal.fire({
            title: "Ingresa el código que enviamos a tu email",
            input: "password",
            showCancelButton: false,
            inputValidator: (value) => {
              if (!value) {
                return "Debes introducir un codigo";
              }
            },
          }).then(async ({ isConfirmed, value: code }) => {
            if (isConfirmed) {
              const res = await Request(
                `/user-recover-password`,
                { email, code },
                "POST"
              );
              if (res.ok) {
                Swal.fire({
                  title: "Ingresa tu nueva contraseña",
                  input: "password",
                  showCancelButton: false,
                  inputValidator: (value) => {
                    if (!value) {
                      return "Debes introducir un codigo";
                    }
                  },
                }).then(async ({ isConfirmed, value: newPassword }) => {
                  if (isConfirmed) {
                    const res = await Request(
                      `/user-recover-password`,
                      { email, code, newPassword },
                      "PATCH"
                    );
                    if (res.ok) {
                      Swal.fire({
                        title: "Contraseña actualizada con éxito",
                        icon: "success",
                      });
                    }
                  }
                });
              }
            }
          });
        }
      }
    });
  };

  return (
    <div className="bgLogin fixed w-full h-full">
      <div className="w-2/3 mx-auto my-8 p-4 loginWrapper">
        <img
          src={logo}
          className="mx-auto px-2 logo"
          style={{ width: "30vw" }}
        />
        <h2
          className="font-bold text-ntaBlue text-2xl text-center mx-auto py-2"
          style={{ fontSize: "2vw" }}
        >
          INICIAR SESIÓN
        </h2>
        <p
          className="w-3/5 text-lg text-ntaGray text-justify mx-auto py-2"
          style={{ fontSize: "1.2vw", margin: "0.5vw 0vw" }}
        >
          Plataforma Administrativa de NTA. Inicie sesión introduciendo su
          nombre de usuario y contraseña.
        </p>
        <form className="mx-auto w-3/5 mt-2" onSubmit={handleLogin}>
          <div className="mx-auto w-full">
            <label
              className="text-basic-gray text-xl labelForm pt-2 font-semibold text-ntaBlue"
              style={{ fontSize: "1.3vw" }}
            >
              Correo electrónico
            </label>
            <input
              style={{ fontSize: "1.1vw", padding: "1vw" }}
              value={username}
              onChange={formOnChange}
              required
              name="username"
              type="email"
              placeholder="Correo electrónico"
              className="inputBorder placeholder-ntaGray bg-ntaInput my-2 rounded-lg w-full opacity-70 focus:ring-ntaGray focus:outline-none focus:border-ntaGray"
            />
          </div>
          <div className="mx-auto w-full">
            <label
              className="text-basic-gray text-xl labelForm pt-2 font-semibold text-ntaBlue"
              style={{ fontSize: "1.3vw" }}
            >
              Contraseña
            </label>
            <input
              style={{ fontSize: "1.1vw", padding: "1vw" }}
              required
              onChange={formOnChange}
              value={password}
              type="password"
              name="password"
              placeholder="Contraseña"
              className="inputBorder placeholder-ntaGray bg-ntaInput my-2 rounded-lg w-full opacity-70 focus:ring-ntaGray focus:outline-none focus:border-ntaGray"
            ></input>
            <div
              className="flex items-end justify-end passEyeContainer"
              style={{ marginTop: "-3vw" }}
            >
              <span className="flex items-center leading-normal rounded rounded-l-none border-0 px-3 whitespace-no-wrap">
                <img
                  src={visible}
                  style={{ width: "1.5vw" }}
                  className="relative right-2 -mt-1 cursor-pointer passEye"
                />
              </span>
            </div>
          </div>
          <div
            className="flex justify-start items-start relative float-left checkContainer"
            style={{ marginTop: "2vw", marginBottom: "1vw" }}
          >
            <div className="w-full">
              <label className="block text-ntaGray w-full relative float-left">
                <input
                  className="relative float-left inputBorder border-ntaGray mb-1 mr-2 p-2 -top-1 bg-ntaInput opacity-70 rounded-sm leading-tight focus:ring-ntaGray focus:outline-none focus:border-ntaGray"
                  type="checkbox"
                  name="keepSession"
                  onChange={(e) => {
                    setKeepSession(e.target.checked);
                  }}
                ></input>
                <span
                  className="text-lg w-3/4 whitespace-nowrap -top-2 float-left relative rememberSession"
                  style={{ fontSize: "1vw" }}
                >
                  Mantener la sesión iniciada
                </span>
              </label>
            </div>
          </div>
          <div className="mx-auto w-2/5 mt-2">
            <button
              style={{
                fontSize: "1.5vw",
                padding: "1vw",
              }}
              type="submit"
              className="w-full text-lg appearance-none border-transparent text-white bg-ntaBlue rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-slate-800 btnLogin"
            >
              Entrar
            </button>
          </div>
        </form>
        <div className="mx-auto text-ntaBlue text-base text-center font-semibold pt-3 w-2/5 cursor-pointer forgotPassContainer">
          <p
            className="forgotPass"
            onClick={handleRecover}
            style={{ fontSize: "1vw" }}
          >
            ¿Olvidó su contraseña? Dé clic aquí.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
