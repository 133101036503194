import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "./Select.css";

const NtaSelect = ({ arrOptions = [], label = "", name, value, required = false, disabled, isValid, onChange, onBlur, className = "", containerClassName = "" }) => {
  const selectLabel = arrOptions.find((a) => a.value === value)?.label;
  return (
    <div className={`mx-auto w-full mt-6 ${containerClassName}`}>
      {label && (
        <label className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">
          {label} {required ? "*" : ""}
        </label>
      )}
      <Select
        className={`inputBorder placeholder-ntaGray bg-ntaInput my-2 rounded-lg w-full focus:ring-ntaGray focus:outline-none focus:border-ntaGray ${className}`}
        {...{
          name,
          onBlur,
          isDisabled: disabled,
          isRequired: required,
        }}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        value={{ value, label: selectLabel }}
        onChange={(e) => {
          onChange({ target: { ...e, name } });
        }}
        options={arrOptions}
      />
    </div>
  );
};

Select.propTypes = {
  arrOptions: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};
/* 
    props:
        *label: Label del select
        *arrOptions: Arreglo de opciones [{text, value}]
    *Obligatorios
*/

export default NtaSelect;
