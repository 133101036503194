import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Input from "../components/Input";
import "../App";
import "../App.css";
import { getColumnsProducts } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import ActiveFilter from "../components/ActiveFilter";
import Swal from "sweetalert2";

const ProductList = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const handleActive = (isActive) => {
    let filteredData = allData.filter((ad) => ad.isActive === isActive);
    setIsActive(isActive);
    setData(filteredData);
  };
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields = lookupField === "all" ? ["name", "product", "warehouse"] : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/product?${filterStr}`, null, "GET");
    setAllData(res.data);
  }, []);
  useEffect(() => {
    fetchData({});
  }, [fetchData]);
  const funcs = {
    edit: (row) => {
      navigate("/editar-producto/" + row.idProduct);
    },
    delete: (row) => {
      const { idProduct: id } = row;
      Swal.fire({
        title: `¿Deseas ${row.isActive ? "in" : ""}activar este producto?`,
        icon: "question",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request("/product/" + id, { isActive: !row.isActive }, "PATCH");
          if (res.ok) {
            fetchData({});
            await Swal.fire({ title: "Modificación exitosa", icon: "success" });
          }
        }
      });
    },
  };
  useEffect(() => {
    handleActive(isActive);
  }, [allData]);
  return (
    <>
      <Menu />
      <div className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList">
        <div className="grid grid-cols-3 containerTituloBtn inputsForm">
          <PageTitle>LISTA DE PRODUCTOS</PageTitle>
          <ActiveFilter isActive={isActive} handleActive={handleActive} />
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/nuevo-producto");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/nueva-producto");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por nombre del producto"
          />
          <Button className={"w-1/3 px-0 mt-14 mb-2 align-right text-center"} style={{ placeSelf: "end", width: "6rem" }}>
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsProducts(funcs)} />
      </div>
    </>
  );
};

export default ProductList;
