import React from "react";
import PropTypes from "prop-types";
import { useForm } from "../helpers/useForm";

const NtaForm = ({ onSubmit, children = [] }) => {
  const initialV = {};
  const createDataset = () => {
    children.forEach((c, ix) => {
      if (["Input", "Select"].includes(c.type.name)) {
        const props = c.props;
        initialV[props.name] = false;
      }
    });
  };
  const [formVValues, handleFromVChange, reset] = useForm(initialV);
  return (
    <form onSubmit={onSubmit} className="formGeneral">
      {children}
    </form>
  );
};

NtaForm.propTypes = {};

export default NtaForm;
