import React, { useState } from "react";
import PropTypes from "prop-types";
import "./ProducerBallotCard.css";
import ButtonIcon from "./ButtonIcon";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const ProducerBallotCard = ({ producer, recivedHumidity }) => {
  const { nameProducer } = producer;
  const [isOpen, setIsOpen] = useState(true);
  return (
    <section className="w-full rounded-lg my-2 py-2 px-4 producerBallotCard">
      <p className="text-center mb-4">
        <strong>{nameProducer}</strong>
        {isOpen ? (
          <ButtonIcon
            style={{ float: "right" }}
            icon={faMinus}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        ) : (
          <ButtonIcon
            style={{ float: "right" }}
            icon={faPlus}
            onClick={() => {
              setIsOpen(true);
            }}
          />
        )}
      </p>
      {isOpen && (
        <div className="w-fullgrid-rows-2 producerBallotCardBody">
          <div className="w-full grid grid-cols-7">
            <p>
              <strong>{producer.recivedHumidity || recivedHumidity} %</strong>
              <span>Humedad</span>
            </p>
            {parseFloat(producer.hopper) === 0 ? (
              <>
                <p>
                  <strong>{producer.tara} Kg</strong>
                  <span>Tara</span>
                </p>
                <p>
                  <strong>{producer.gross} Kg</strong>
                  <span>Bruto</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  <strong>
                    {producer.hopperInt == 0 ? "" : producer.hopperInt + "  - "}
                    {producer.hopperNum + " / " + producer.hopperDen}
                  </strong>
                  <span>Tolvas</span>
                </p>
                <p>
                  <strong>{producer.additionalWeight} Kg</strong>
                  <span>Adicional</span>
                </p>
              </>
            )}
            <p>
              <strong>{producer.net} Kg</strong>
              <span>Neto</span>
            </p>
            <p>
              <strong>{producer.ticketNo || "-"}</strong>
              <span>Ticket</span>
            </p>
            <p>
              <strong>{producer.treshedSurface} H </strong>
              <span>Superficie</span>
            </p>
            <p>
              <strong>$ {producer.price} </strong>
              <span>Precio</span>
            </p>
            <p />
          </div>
        </div>
      )}
    </section>
  );
};

ProducerBallotCard.propTypes = {
  producer: PropTypes.object,
  recivedHumidity: PropTypes.string,
};

export default ProducerBallotCard;
