import React, { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import { Request } from "../helpers/api";
import { behaviors, clientSchema } from "../helpers/schemas";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { clientInvoiceTypes, statesMx, weekDays } from "../helpers/enums";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Button from "../components/Button";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import "../App";
import "../App.css";
import NtaSelect from "../components/Select";
import "./ClientInsert.css";

const ClientInsert = () => {
  const { id: idClient } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [newClient, newClientChange, newClientReset, setAllClient] =
    useForm(clientSchema);
  const [behavior, setBehavior] = useState(behaviors.insert);
  const insertClient = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await Request(
      `/client${behavior === behaviors.insert ? "" : "/" + idClient}`,
      newClient,
      behavior === behaviors.insert ? "POST" : "PUT"
    );
    setIsLoading(false);
    if (res.ok) {
      Swal.fire({
        title: `${
          behavior === behaviors.insert
            ? "Registro exitoso"
            : "Actualización exitosa"
        }`,
        icon: "success",
      }).then(() => {
        navigate("/clientes");
      });
    }
  };
  const fetchData = useCallback(async () => {
    const res = await Request(`/client/${idClient}`, undefined, "GET");
    if (res.ok) {
      setAllClient(res.data);
    }
  });
  useEffect(() => {
    if (idClient) {
      setBehavior(behaviors.edit);
      fetchData();
    }
  }, []);
  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>
          {" "}
          {behavior === behaviors.insert
            ? "ALTA DE CLIENTES"
            : "EDITAR CLIENTE"}
        </PageTitle>
      </div>
      <NtaForm onSubmit={insertClient}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <Input
            type="text"
            required={true}
            label="Razón social"
            placeholder="Nombre Completo"
            name="companyName"
            maxLength={100}
            onChange={newClientChange}
            value={newClient.companyName}
          />
          <Input
            type="text"
            required={true}
            label="RFC"
            name="rfc"
            maxLength={13}
            minLength={12}
            onChange={newClientChange}
            value={newClient.rfc}
          />
          <Input
            type="text"
            required={true}
            label="Calle y número"
            name="address"
            minLength={10}
            maxLength={200}
            onChange={newClientChange}
            value={newClient.address}
          />
          <Input
            type="text"
            required={false}
            label="Código Postal"
            name="zipCode"
            maxLength={200}
            onChange={newClientChange}
            value={newClient.zipCode}
          />
          <NtaSelect
            arrOptions={[{ value: "", label: "Elige un estado" }, ...statesMx]}
            name="state"
            onChange={newClientChange}
            value={newClient.state}
            label="Estado"
          />
          <Input
            type="text"
            required={false}
            label="Colonia"
            name="suburb"
            maxLength={100}
            onChange={newClientChange}
            value={newClient.suburb}
          />
          <Input
            type="text"
            required={true}
            label="Nombre del contacto"
            placeholder="Dirección"
            name="contactName"
            maxLength={100}
            onChange={newClientChange}
            value={newClient.contactName}
          />
          <Input
            type="email"
            required={true}
            label="Correo del contacto"
            placeholder="juan.perez@email.com"
            name="email"
            onChange={newClientChange}
            value={newClient.email}
          />
          <Input
            type="tel"
            required={true}
            label="Teléfono de contacto"
            name="phone"
            maxLength={10}
            minLength={10}
            onChange={newClientChange}
            value={newClient.phone}
          />
          <Input
            type="number"
            required={true}
            step={0.1}
            label="Límite de crédito"
            name="creditLimit"
            min={0}
            onChange={newClientChange}
            value={newClient.creditLimit}
          />
          <Input
            type="number"
            btw
            required={true}
            step={1}
            label="Humedad permisible %"
            name="permissibleHumidity"
            min={8}
            max={30}
            onChange={newClientChange}
            value={newClient.permissibleHumidity}
          />
          <NtaSelect
            arrOptions={clientInvoiceTypes}
            name="invoiceType"
            onChange={newClientChange}
            value={newClient.invoiceType}
            label="Tipo de facturación"
          />
          <div>
            <label className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">
              Días disponibles
            </label>
            <br />
            <br />
            <div className="grid grid-cols-7">
              {weekDays.map((wd) => {
                const accessKey = wd.value + "Available";
                return (
                  <div
                    onClick={() => {
                      newClientChange({
                        target: {
                          value: !newClient[accessKey],
                          name: accessKey,
                        },
                      });
                    }}
                    className={`clientAvailableDay ${
                      newClient[accessKey] ? "active" : "inactive"
                    }Day`}
                  >
                    <span>{wd.shortLabel}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div />
          <Button disabled={isLoading}>Continuar</Button>
        </div>
      </NtaForm>
    </>
  );
};

export default ClientInsert;
