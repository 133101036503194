import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import { behaviors, ejidoSchema, originFieldSchema } from "../helpers/schemas";
import { parseCat } from "../helpers/utils";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import "../App";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { initialFetch } from "../redux/actions/auth";
import { getFromSession } from "../helpers/storageHandler";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../helpers/api";
import ButtonIcon from "../components/ButtonIcon";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const EjidoInsert = () => {
  const { id: idEjido } = useParams();
  const [newEjido, newEjidoChange, newEjidoReset, setAllValues] = useForm(ejidoSchema);
  const [isLoading, setIsLoading] = useState(false);
  const [behavior, setBehavior] = useState(behaviors.insert);
  const catalogues = useSelector((s) => s.catalogues);
  const { catWarehouse, units } = catalogues;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onWarehouseChange = (e, ix) => {
    const { warehouses } = newEjido;
    const warehouse = { ...warehouses[ix] };
    warehouse[e.target.name] = e.target.value;
    warehouses[ix] = warehouse;
    newEjidoChange({
      target: { name: "warehouses", value: warehouses },
    });
  };

  const onFieldChange = (e, ix) => {
    const { origin_fields } = newEjido;
    const item = { ...origin_fields[ix] };
    item[e.target.name] = e.target.value;
    origin_fields[ix] = item;
    newEjidoChange({
      target: { name: "origin_fields", value: origin_fields },
    });
  };

  const warehouseManage = (willDetele = false, ix = null) => {
    const { warehouses } = newEjido;
    if (willDetele) {
      warehouses.splice(ix, 1);
    } else {
      warehouses.push({ idWarehouse: "" });
    }
    newEjidoChange({
      target: { name: "warehouses", value: warehouses },
    });
  };

  const fieldManage = (willDetele = false, ix = null) => {
    const { origin_fields } = newEjido; // TODO: REPLACE KEY WITH originFields TO MATCH WITH camelCase
    if (willDetele) {
      origin_fields.splice(ix, 1);
    } else {
      origin_fields.push({ ...originFieldSchema });
    }
    newEjidoChange({
      target: { name: "origin_fields", value: origin_fields },
    });
  };

  const fetchData = useCallback(async () => {
    const res = await Request(`/ejido/${idEjido}`, undefined, "GET");
    if (res.ok) {
      setAllValues({ ...res.data });
    }
  });

  const insertEditEjido = async (e) => {
    e.preventDefault();
    const selectValidation = ["idUnit"];
    const hasMissingFields = selectValidation.find((sv) => newEjido[sv] === "");
    if (hasMissingFields || newEjido.warehouses.length === 0 || newEjido.warehouses.find((w) => w.idWarehouse === "")) {
      Swal.fire("Error", "Debes ingresar todos los campos requeridos antes de continuar y al menos un almacén", "warning");
    } else {
      setIsLoading(true);
      const res = await Request(`/ejido${behavior === behaviors.edit ? "/" + idEjido : ""}`, newEjido, behavior === behaviors.insert ? "POST" : "PUT");
      setIsLoading(false);
      if (res.ok) {
        newEjidoReset();
        await dispatch(initialFetch(getFromSession("jwt")));
        Swal.fire({
          title: behavior === behaviors.insert ? "Registro exitoso" : "Actualización exitosa",
          icon: "success",
        }).then(() => {
          navigate("/ejidos");
        });
      }
    }
  };

  useEffect(() => {
    if (idEjido) {
      setBehavior(behaviors.edit);
      fetchData();
    }
    newEjidoChange({
      target: { name: "warehouses", value: [] },
    });
  }, []);

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>{behavior === behaviors.insert ? "ALTA DE EJIDO" : "EDITAR EJIDO"}</PageTitle>
      </div>
      <NtaForm onSubmit={insertEditEjido}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Select
              arrOptions={[
                ...parseCat(
                  units?.filter((u) => u.isActive),
                  "name",
                  "idUnit"
                ),
              ]}
              required={true}
              name="idUnit"
              disabled={behavior === behaviors.edit || newEjido.warehouses.length > 0}
              onChange={newEjidoChange}
              value={newEjido.idUnit}
              label="Unidad"
            />
          </div>
          <div>
            <Input
              type="text"
              required={true}
              placeholder="Nombre del ejido"
              label="Nombre del ejido"
              name="name"
              maxLength={100}
              className={"grid w-full"}
              onChange={newEjidoChange}
              value={newEjido.name}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 w-4/5 mx-auto mt-10 inputsForm">
          <div>
            <div className="grid grid-cols-2 gap-2 w-full h-14" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span className="text-basic-gray text-xl labelForm p-2 font-bold text-ntaBlue">ALMACENES ASOCIADOS</span>
              <ButtonIcon
                className={"text-white bg-ntaBlue hover:bg-ntaGray w-14 h-14 relative float-left place-self-start place-content-center grid"}
                icon={faPlus}
                onClick={() => {
                  warehouseManage();
                }}
              />
            </div>
            <div>
              {newEjido.warehouses.map((w, ix) => (
                <div className="w-full mx-auto">
                  <div className="grid grid-cols-2 gap-2 w-full" style={{ display: "flex", alignItems: "flex-end" }}>
                    <Select
                      arrOptions={[
                        ...parseCat(
                          catWarehouse?.filter((e) => (e.idUnit === newEjido.idUnit && !newEjido.warehouses.find((ew) => ew.idWarehouse === e.idWarehouse)) || e.idWarehouse === w.idWarehouse),
                          "name",
                          "idWarehouse"
                        ),
                      ]}
                      disabled={newEjido.idUnit === ""}
                      required={true}
                      name="idWarehouse"
                      onChange={(e) => {
                        onWarehouseChange(e, ix);
                      }}
                      value={w.idWarehouse}
                      label="Almacén"
                    />
                    <ButtonIcon
                      style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "0.6rem" }}
                      className={"text-ntaBlue w-14 h-14 grid buttonTrash"}
                      icon={faTrash}
                      onClick={() => {
                        warehouseManage(true, ix);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2 gap-2 w-full h-14" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span className="text-basic-gray text-xl labelForm p-2 font-bold text-ntaBlue">CAMPOS ASOCIADOS</span>
              <ButtonIcon
                className={"text-white bg-ntaBlue hover:bg-ntaGray w-14 h-14 relative float-left place-self-start place-content-center grid"}
                icon={faPlus}
                onClick={() => {
                  fieldManage();
                }}
              />
            </div>
            <div>
              {newEjido.origin_fields.map((w, ix) => (
                <div className="w-full mx-auto">
                  <div className="grid grid-cols-2 gap-2 w-full" style={{ display: "flex", alignItems: "flex-end" }}>
                    <Input
                      type="text"
                      placeholder="Nombre del campo"
                      value={w.name}
                      name="name"
                      label="Campo"
                      onChange={(e) => {
                        onFieldChange(e, ix);
                      }}
                    />
                    <ButtonIcon
                      style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "0.6rem" }}
                      className={"text-ntaBlue w-14 h-14 grid buttonTrash"}
                      icon={faTrash}
                      onClick={() => {
                        fieldManage(true, ix);
                      }}
                    />
                  </div>
                  <div></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4 inputsForm">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            {behavior === behaviors.insert ? "Dar de alta" : "Actualizar"}
          </Button>
        </div>
      </NtaForm>
    </>
  );
};

export default EjidoInsert;
