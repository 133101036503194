import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { parseObjectDate } from "../../helpers/utils";
import Button from "../Button";
import noop from "../../helpers/noop";
import PageTitle from "../PageTitle";

const PersonDetails = ({ isOpen, data, toggle, onConfirm = noop, onCancel = noop }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>
        <PageTitle>DETALLE DE PERSONA</PageTitle>
      </ModalHeader>
      <ModalBody>
        <table className="ntaTableDataSet">
          <tbody>
            <tr>
              <td>
                <strong>Unidad: </strong>
                <span>{data.unit}</span>
              </td>
              <td>
                <strong>Fecha de creación: </strong>
                <span>{parseObjectDate(new Date(data.createdAt), 3)}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Ejido: </strong>
                <span>{data.ejido}</span>
              </td>
              <td>
                <strong>Teléfono: </strong>
                <span>{data.phone}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Número de INE: </strong>
                <span>{data.identityKey}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Nombre completo (INE): </strong>
                <span>{data.name}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Dirección: </strong>
                <span>{data.address}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Roles: </strong>
                <ul className="rolesList">
                  {data.cat_suppliers?.map((cs) => (
                    <li>{cs.name}</li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Banco: </strong>
                <span>{data.nameBank}</span>
              </td>
              {data.idBank !== null && (
                <td>
                  <strong>Tipo de cuenta: </strong>
                  <span>{data.bankAccountType}</span>
                </td>
              )}
            </tr>
            {data.idBank !== null && (
              <tr>
                <td colSpan={2}>
                  <strong>Cuenta bancaria: </strong>
                  <span>{data.bankAccountKey}</span>
                </td>
              </tr>
            )}
            {data.cat_suppliers?.find((r) => r.idCatSupplier === 5) && (
              <tr>
                <td colSpan={2}>
                  <strong>Placas: </strong>
                  <span>
                    <table className="vehicles-table w-full searchable">
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Placa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.vehicles?.map((v) => (
                          <tr>
                            <td>{v.nameCatVehicle + " - " + v.descriptionCatVehicle}</td>
                            <td>{v.plates}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 w-full btnModal">
          <div />
          <div />
          <Button
            onClick={() => {
              toggle(false);
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

PersonDetails.propTypes = {};

export default PersonDetails;
