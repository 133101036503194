import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheckCircle,
  faCircleCheck,
  faEdit,
  faEye,
  faFileCircleCheck,
  faGear,
  faXmark,
  faArrowRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../components/ButtonIcon";
import {
  getBallotStatusColor,
  parseCat,
  parseObjectDate,
  santizeDateTime,
} from "./utils";
import { Checkbox } from "@mui/material";
import Input from "../components/Input";
import NtaSelect from "../components/Select";
import { loadTypeEnum } from "./enums";

const customCell = (
  selector,
  alignment = "left",
  renderText = (text) => {
    return text;
  }
) => {
  return (row) => (
    <div style={{ textAlign: alignment, width: "100%" }}>
      {renderText(row[selector]) || "-"}
    </div>
  );
};

export const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "22px",
      fontWeight: "bold",
      padding: "8px 0",
      backgroundColor: "#071C49",
      color: "#fff",
      textAlign: "center",
    },
  },
  cells: {
    style: {
      paddingLeft: "2%",
      paddingRight: "2%",
      fontSize: "20px",
      color: "#071C49",
    },
  },
};

export const getColumnsPersons = (funcs) => [
  {
    name: "Unidad",
    center: true,
    width: "20%",
    key: "unit",
    cell: customCell("unit"),
  },
  {
    name: "Ejido",
    width: "20%",
    center: true,
    key: "ejido",
    cell: customCell("ejido"),
  },
  {
    name: "Nombre y Rol",
    width: "28%",
    center: true,
    key: "name",
    cell: customCell("name"),
  },
  {
    name: "Teléfono",
    width: "17%",
    center: true,
    key: "phone",
    cell: customCell("phone"),
  },
  {
    name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
    width: "15%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        <ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.details(row);
          }}
          icon={faEye}
        />
        <ButtonIcon
          className="px-2"
          onClick={() => {
            funcs.edit(row);
          }}
          icon={faEdit}
        />
        <ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.delete(row);
          }}
          icon={faBan}
        />
      </>
    ),
  },
];

export const getColumnsUnits = (funcs) => [
  {
    name: "Unidad",
    center: true,
    width: "40%",
    key: "unit",
    cell: customCell("unit"),
  },
  {
    name: "Almacén",
    width: "40%",
    center: true,
    key: "name",
    cell: customCell("name"),
  },

  {
    name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
    width: "20%",
    center: true,
    cell: (row) => (
      <>
        {row.unitIsActive ? (
          <ButtonIcon
            className="px-2"
            onClick={() => {
              funcs.edit(row);
            }}
            icon={faEdit}
          />
        ) : (
          <></>
        )}
        <ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.delete(row);
          }}
          icon={row.unitIsActive ? faBan : faCircleCheck}
        />
      </>
    ),
  },
];

export const getColumnsEjidos = (funcs, extData) => {
  const { catProducts = [] } = extData;
  const productsColumns = catProducts?.map((cp) => ({
    name: cp.name,
    center: true,
    width: 56 / catProducts.length + "%",
    key: cp.idProduct,
    cell: customCell(cp.idProduct, "center", (t) => (t ? t + " Kg" : "-")),
  }));
  return [
    {
      name: "Unidad",
      center: true,
      width: "12%",
      key: "unit",
      cell: customCell("unit"),
    },
    {
      name: "Almacén",
      width: "12%",
      center: true,
      key: "warehouse",
      cell: customCell("warehouse"),
    },
    {
      name: "Ejido",
      width: "12%",
      center: true,
      key: "name",
      cell: customCell("name"),
    },
    ...productsColumns,
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "8%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            className="px-2"
            onClick={() => {
              funcs.edit(row);
            }}
            icon={faEdit}
          />
          <ButtonIcon
            className="px-1"
            onClick={() => {
              funcs.delete(row);
            }}
            icon={row.isActive ? faBan : faCircleCheck}
          />
        </>
      ),
    },
  ];
};

export const getColumnsSuppliers = (funcs) => [
  {
    name: "Unidad",
    center: true,
    width: "20%",
    key: "unit",
    cell: customCell("unit"),
  },
  {
    name: "Rol",
    center: true,
    width: "20%",
    key: "roleSupplier",
    cell: (row) => (
      <div style={{ textAlign: "center", width: "100%" }}>
        {row.roleSupplier === "EMITTER" ? "Emisor" : "No Emisor"}
      </div>
    ),
  },
  {
    name: "Límite de facturación",
    center: true,
    width: "20%",
    key: "defBillingLimit",
    cell: customCell("defBillingLimit", "right", (text) => "$ " + text),
  },
  {
    name: "Razón social y RFC",
    center: true,
    width: "30%",
    key: "unit",
    cell: (row) => (
      <div style={{ textAlign: "left", width: "100%" }}>
        {row.name} <br />
        {row.identityKey}
      </div>
    ),
  },
  {
    name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
    width: "10%",
    center: true,
    cell: (row) => (
      <>
        <ButtonIcon
          icon={faEye}
          onClick={() => {
            funcs.details(row);
          }}
        />
        <ButtonIcon
          className="px-2"
          onClick={() => {
            funcs.edit(row);
          }}
          icon={faEdit}
        />
        <ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.delete(row);
          }}
          icon={faBan}
        />
      </>
    ),
  },
];

export const getColumnsClients = (funcs) => [
  {
    name: "Razón social y RFC",
    center: true,
    width: "25%",
    key: "companyName",
    cell: (row) => (
      <div style={{ textAlign: "left", width: "100%" }}>
        {row.companyName}
        <br />
        {row.rfc}
      </div>
    ),
  },
  {
    name: "Dirección",
    center: true,
    width: "25%",
    key: "address",
    cell: customCell("address"),
  },
  {
    name: "Teléfono",
    center: true,
    width: "20%",
    key: "phone",
    cell: customCell("phone"),
  },
  {
    name: "Contacto",
    center: true,
    width: "20%",
    key: "contactName",
    cell: (row) => (
      <div style={{ textAlign: "left", width: "100%" }}>
        {row.contactName || ""}
        <br />
        {row.email}
      </div>
    ),
  },
  {
    name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
    width: "10%",
    center: true,
    cell: (row) => (
      <>
        <ButtonIcon
          icon={faEye}
          onClick={() => {
            funcs.details(row);
          }}
        />
        <ButtonIcon
          className="px-2"
          onClick={() => {
            funcs.edit(row);
          }}
          icon={faEdit}
        />
        <ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.delete(row);
          }}
          icon={faBan}
        />
      </>
    ),
  },
];

export const getColumnsBallots = (funcs, extData) => {
  return [
    {
      name: (
        <Checkbox
          className="ntaTableCheckbox"
          checked={extData.allChecked}
          onChange={(e) => {
            funcs.handleSelection(e.target.checked);
          }}
        />
      ),
      center: true,
      width: "4%",
      key: "checked",
      cell: (row, ix) => (
        <Checkbox
          onChange={(e) => {
            funcs.handleSelection(e.target.checked, ix, row.idProduct);
          }}
          checked={row.checked}
        />
      ),
    },
    {
      name: "No. Consecutivo",
      center: true,
      width: "15%",
      key: "folio",
      cell: customCell("folio", "center"),
    },
    {
      name: "Ejido / Producto",
      center: true,
      width: "16%",
      key: "ejido",
      cell: (row) => (
        <div style={{ textAlign: "left", width: "100%" }}>
          {row.ejido}
          <br />
          <strong>{row.product?.name}</strong>
        </div>
      ),
    },
    {
      name: "Fecha Generación",
      center: true,
      width: "18%",
      key: "createdAt",
      cell: customCell("createdAt", "center", (t) =>
        parseObjectDate(new Date(t), 3)
      ),
    },
    {
      name: "Estatus",
      center: true,
      width: "16%",
      key: "estatus",
      cell: (row) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <span
            className="py-1 px-3 rounded-lg"
            style={{
              backgroundColor: getBallotStatusColor(row.idCatBallotStatus),
            }}
          >
            {row.status}
          </span>
        </div>
      ),
    },
    {
      name: "Placas",
      center: true,
      width: "16%",
      key: "foio",
      cell: (row) => (
        <div style={{ textAlign: "left", width: "100%" }}>
          {row.vehicle?.plates}
          <br />
          {row.vehicle?.cat_vehicle?.name +
            " " +
            row.vehicle?.cat_vehicle?.description}
        </div>
      ),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "15%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEye}
            onClick={() => {
              funcs.details(row);
            }}
          />
          {[1, 2].includes(row.idCatBallotStatus) && (
            <ButtonIcon
              icon={faFileCircleCheck}
              onClick={() => {
                funcs.capture(row);
              }}
            />
          )}
          {[3].includes(row.idCatBallotStatus) && (
            <ButtonIcon
              className="px-2"
              onClick={() => {
                funcs.edit(row);
              }}
              icon={faEdit}
            />
          )}
          {row.idCatBallotStatus === 1 && (
            <ButtonIcon
              icon={faBan}
              onClick={() => {
                funcs.delete(row);
              }}
            />
          )}
        </>
      ),
    },
  ];
};

export const getColumnsProducts = (funcs) => {
  return [
    {
      name: "Producto",
      center: true,
      width: "42%",
      key: "name",
      cell: customCell("name", "left"),
    },
    {
      name: "Código",
      center: true,
      width: "42%",
      key: "code",
      cell: customCell("code", "center"),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "16%",
      center: true,
      cell: (row) => (
        <>
          {/*<ButtonIcon
            icon={faEye}
            onClick={() => {
              funcs.details(row);
            }}
          />*/}
          <ButtonIcon
            className="px-2"
            onClick={() => {
              funcs.edit(row);
            }}
            icon={faEdit}
          />
          <ButtonIcon
            className="px-1"
            onClick={() => {
              funcs.delete(row);
            }}
            icon={row.isActive ? faBan : faCircleCheck}
          />
        </>
      ),
    },
  ];
};

export const getColumnsInactiveWarehouses = (funcs) => {
  return [
    {
      name: "Unidad",
      center: true,
      width: "28%",
      key: "name",
      cell: customCell("name", "left"),
    },
    {
      name: "Almacén",
      center: true,
      width: "28%",
      key: "name",
      cell: customCell("name", "center"),
    },
    {
      name: "Código",
      center: true,
      width: "28%",
      key: "code",
      cell: customCell("code", "center"),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "16%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            className="px-2"
            onClick={() => {
              funcs.edit(row);
            }}
            icon={faCheckCircle}
          />
        </>
      ),
    },
  ];
};

export const getColumnsInventory = (funcs) => {
  return [
    {
      name: "Unidad",
      center: true,
      width: "11%",
      key: "unit",
      cell: (row) => (
        <span style={{ opacity: row.isActiveUnit === 0 ? 0.3 : 1 }}>
          {row.unit}
        </span>
      ),
    },
    {
      name: "Almacén",
      width: "12%",
      center: true,
      key: "warehouse",
      cell: (row) => (
        <span style={{ opacity: row.isActiveWarehouse === 0 ? 0.3 : 1 }}>
          {row.warehouse}
        </span>
      ),
    },
    {
      name: "Producto existente",
      width: "12%",
      center: true,
      key: "product",
      cell: customCell("product"),
    },
    {
      name: "Entradas",
      width: "10%",
      center: true,
      key: "entries",
      cell: customCell("entries"),
    },
    {
      name: "Salidas",
      width: "10%",
      center: true,
      key: "exits",
      cell: customCell("exits"),
    },
    {
      name: "Inventario",
      width: "10%",
      center: true,
      key: "inventory",
      cell: customCell("inventory"),
    },
    {
      name: "Precio ponderado",
      width: "10.5%",
      center: true,
      key: "weightedPrice",
      cell: customCell("weightedPrice"),
    },
    {
      name: "Precio (- 1ras entradas)",
      width: "10.5%",
      center: true,
      key: "priceMinusFirstEntries",
      cell: customCell("priceMinusFirstEntries"),
    },
    {
      name: "Último precio de compra",
      width: "10%",
      center: true,
      key: "lastPurchasePrice",
      cell: customCell("lastPurchasePrice"),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEye}
            onClick={() => {
              funcs.details(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsReceptions = (funcs) => {
  return [
    {
      name: "Unidad",
      center: true,
      width: "11%",
      key: "unit",
      cell: (row) => (
        <span style={{ opacity: row.isActiveUnit === 0 ? 0.3 : 1 }}>
          {row.unit}
        </span>
      ),
    },
    {
      name: "Almacén",
      width: "11%",
      center: true,
      key: "warehouse",
      cell: (row) => (
        <span style={{ opacity: row.isActiveWarehouse === 0 ? 0.3 : 1 }}>
          {row.warehouse}
        </span>
      ),
    },
    {
      name: "Producto",
      width: "12%",
      center: true,
      key: "product",
      cell: customCell("product"),
    },
    {
      name: "Folio",
      width: "9%",
      center: true,
      key: "folio",
      cell: customCell("folio"),
    },
    {
      name: "Número de factura",
      width: "10%",
      center: true,
      key: "invoiceNumber",
      cell: customCell("invoiceNumber"),
    },
    {
      name: "Proveedor",
      width: "11%",
      center: true,
      key: "supplier",
      cell: customCell("supplier"),
    },
    {
      name: "Fecha de embarque",
      width: "11%",
      center: true,
      key: "freightDate",
      cell: customCell("freightDate"),
    },
    {
      name: "Kilogramos",
      width: "12%",
      center: true,
      key: "weight",
      cell: customCell("weight"),
    },
    {
      name: "Precio",
      width: "8%",
      center: true,
      key: "price",
      cell: customCell("price"),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEye}
            onClick={() => {
              funcs.details(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsArrivals = (funcs) => {
  const entriesEnum = {
    BALLOT: "Boleta",
    WITHOUTCONTRACT: "Entrada sin contrato",
    TRANSFER: "Transferencia",
  };
  return [
    {
      name: "Folio",
      center: true,
      width: "20%",
      key: "folio",
      cell: (row) => {
        return row.folio || row.inventoryOutputType;
      },
    },
    {
      name: "Humedad",
      center: true,
      width: "15%",
      key: "humidity",
      cell: customCell("humidity", "center", (t) => t + "%"),
    },
    {
      name: "Fecha",
      center: true,
      width: "20%",
      key: "date",
      cell: customCell("date", "center", (t) => {
        return t;
      }),
    },
    {
      name: "Cantidad",
      center: true,
      width: "20%",
      key: "weight",
      cell: customCell("weight", "center", (t) => t + " Kg"),
    },
    {
      name: "Precio",
      center: true,
      width: "20%",
      key: "price",
      cell: customCell("price", "center", (t) => "$ " + t),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faBan}
            onClick={() => {
              funcs.delete(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsFreightQuotas = (funcs, { onChange, catalogues }) => {
  const { ejidos = [], catWarehouse = [], destinations = [] } = catalogues;
  const renderOriginField = (row, ix) => {
    if (row.idOrigin) {
      const [id, type] = row.idOrigin?.split("_");
      if (type === "WAREHOUSE") {
        const warehouse = catWarehouse.find((w) => w.idWarehouse == id);
        if (warehouse) {
          return (
            <Input
              disabled={true}
              onChange={() => {}}
              value={warehouse.name}
              containerClassName="mt-0"
              name="idOriginField"
            />
          );
        } else {
          return null;
        }
      } else {
        const ejido = ejidos?.find((e) => e.idEjido == id);
        const fields = ejido?.origin_fields;
        if (fields) {
          const fieldsParsed = parseCat(fields, "name", "idOriginField");
          if (fields.length === 0) {
            return (
              <Input
                disabled={true}
                onChange={(e) => {}}
                value={ejido.name}
                containerClassName="mt-0"
                name="idOriginField"
              />
            );
          } else if (fields.length === 1) {
            if (fieldsParsed[0].value !== row.idOriginField) {
              onChange(
                {
                  target: {
                    value: fieldsParsed[0].value,
                    name: "idOriginField",
                  },
                },
                ix
              );
            }
            return (
              <NtaSelect
                value={row.idOriginField}
                containerClassName="mt-0"
                onChange={(e) => {
                  onChange(e, ix);
                }}
                disabled={true}
                arrOptions={fieldsParsed}
                name="idOriginField"
              />
            );
          } else {
            return (
              <NtaSelect
                value={row.idOriginField}
                containerClassName="mt-0"
                onChange={(e) => {
                  onChange(e, ix);
                }}
                arrOptions={[
                  { label: "Elige un campo", value: "" },
                  ...fieldsParsed,
                ]}
                name="idOriginField"
              />
            );
          }
        } else {
          return null;
        }
      }
    }
  };
  return [
    {
      name: "Origen",
      center: true,
      width: "13.3%",
      key: "idOrigin",
      cell: (row, ix) => (
        <NtaSelect
          value={row.idOrigin}
          containerClassName="mt-0"
          onChange={(e) => {
            onChange(e, ix);
          }}
          arrOptions={[
            { label: "-", value: "" },
            ...parseCat(
              ejidos
                .filter((e) => e.isActive)
                .map((e) => ({ v: `${e.idEjido}_EJIDO`, l: e.name })),
              "l",
              "v"
            ),
            ...parseCat(
              catWarehouse.map(({ idWarehouse, name }) => ({
                v: `${idWarehouse}_WAREHOUSE`,
                l: name,
              })),
              "l",
              "v"
            ),
          ]}
          name="idOrigin"
        />
      ),
    },
    {
      name: "Campo de origen",
      center: true,
      width: "13.3%",
      key: "originField",
      cell: (row, ix) => renderOriginField(row, ix),
    },
    {
      name: "Destino",
      center: true,
      width: "13.3%",
      key: "date",
      cell: (row, ix) => (
        <NtaSelect
          value={row.idDestination}
          containerClassName="mt-0"
          onChange={(e) => {
            onChange(e, ix);
          }}
          arrOptions={[
            { label: "Elige un destino", value: "" },
            ...parseCat(
              destinations.map((d) => ({
                v: d.idDestination + "_CLIENT",
                l: d.clientDestination,
              })),
              "l",
              "v"
            ),
            ...parseCat(
              catWarehouse.map(({ idWarehouse, name }) => ({
                v: `${idWarehouse}_WAREHOUSE`,
                l: name,
              })),
              "l",
              "v"
            ),
          ]}
          name="idDestination"
        />
      ),
    },
    {
      name: "Pick up",
      center: true,
      width: "8%",
      key: "pickup",
      cell: (row, ix) => (
        <Input
          value={row.pickup}
          onChange={(e) => {
            onChange(e, ix);
          }}
          containerClassName="mt-0"
          min={0}
          name="pickup"
        />
      ),
    },
    {
      name: "Volteo",
      center: true,
      width: "8%",
      key: "volteo",
      cell: (row, ix) => (
        <Input
          value={row.volteo}
          onChange={(e) => {
            onChange(e, ix);
          }}
          containerClassName="mt-0"
          min={0}
          name="volteo"
        />
      ),
    },
    {
      name: "Rabón",
      center: true,
      width: "8%",
      key: "rabon",
      cell: (row, ix) => (
        <Input
          value={row.rabon}
          onChange={(e) => {
            onChange(e, ix);
          }}
          containerClassName="mt-0"
          min={0}
          name="rabon"
        />
      ),
    },
    {
      name: "Tortón volteo",
      center: true,
      width: "8%",
      key: "tortonVolteo",
      cell: (row, ix) => (
        <Input
          value={row.tortonVolteo}
          onChange={(e) => {
            onChange(e, ix);
          }}
          containerClassName="mt-0"
          min={0}
          name="tortonVolteo"
        />
      ),
    },
    {
      name: "Tortón redilas",
      center: true,
      width: "8%",
      key: "tortonRedilas",
      cell: (row, ix) => (
        <Input
          value={row.tortonRedilas}
          onChange={(e) => {
            onChange(e, ix);
          }}
          containerClassName="mt-0"
          min={0}
          name="tortonRedilas"
        />
      ),
    },
    {
      name: "Trailer",
      center: true,
      width: "8%",
      key: "trailer",
      cell: (row, ix) => (
        <Input
          value={row.trailer}
          onChange={(e) => {
            onChange(e, ix);
          }}
          containerClassName="mt-0"
          min={0}
          name="trailer"
        />
      ),
    },
    {
      name: "Cuota adicional",
      center: true,
      width: "8%",
      key: "additionalQuota",
      cell: (row, ix) => (
        <Input
          value={row.additionalQuota}
          onChange={(e) => {
            onChange(e, ix);
          }}
          containerClassName="mt-0"
          min={0}
          name="additionalQuota"
        />
      ),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "4%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faCheckCircle}
            onClick={() => {
              funcs.save(row);
            }}
          />
        </>
      ),
    },
  ];
};
export const getColumnsFreights = (funcs) => {
  return [
    {
      name: "No",
      center: true,
      width: "10%",
      key: "number",
      cell: (row) => (
        <div>
          <input type="checkbox" className="p-2 mx-1 -mt-1"></input>
          <span
            className="ml-1"
            style={{ opacity: row.isActiveUnit === 0 ? 0.3 : 1 }}
          >
            {row.folio}
          </span>
        </div>
      ),
    },
    {
      name: "Tipo de carga",
      width: "7%",
      center: true,
      key: "folio",
      cell: customCell(
        "uploadType",
        "center",
        (t) => loadTypeEnum.find((en) => en.value === t).label
      ),
    },
    {
      name: "Origen",
      width: "8%",
      center: true,
      key: "origin",
      cell: (row) => {
        const origin = row.origin || " - ";
        const [name, type] = origin.split(" - ");
        return (
          <div>
            <span>{name || "-"}</span> <br />
            <span className=" text-sm ">{type || "-"}</span>
          </div>
        );
      },
    },
    {
      name: "Destino",
      width: "8%",
      center: true,
      key: "destination",
      selector: "destination",
    },
    {
      name: "Peso origen",
      width: "7%",
      center: true,
      key: "originWeight",
      selector: "originWeight",
    },
    {
      name: "Cuota",
      width: "7%",
      center: true,
      selector: "quota",
    },
    {
      name: "Chofer",
      width: "14%",
      center: true,
      key: "freighter",
      selector: "freighter",
    },
    {
      name: "Fecha de embarque",
      width: "9%",
      center: true,
      key: "boardingDate",
      cell: customCell("boardingDate", "center", (t) => {
        if (t) {
          const [date] = t?.split("T");
          return parseObjectDate(new Date([date, "12:00:00"].join("T")), 3);
        }
        return "-";
      }),
    },
    {
      name: "Fecha de entrega",
      width: "9%",
      center: true,
      key: "deliveryDate",
      cell: customCell("deliveryDate", "center", (t) => {
        if (t) {
          const [date] = t?.split("T");
          return parseObjectDate(new Date([date, "12:00:00"].join("T")), 3);
        }
        return "-";
      }),
    },
    {
      name: "Producto",
      width: "8%",
      center: true,
      key: "product",
      selector: "product",
    },
    {
      name: "Estatus flete",
      width: "8%",
      center: true,
      key: "catStatusBoarding",
      cell: (row) => (
        <span className="p-1 bg-green-600 rounded-lg text-black">
          {row.catStatusBoarding}
        </span>
      ),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEdit}
            onClick={() => {
              funcs.edit(row);
            }}
          />
          <ButtonIcon
            icon={faBan}
            onClick={() => {
              funcs.delete(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsLocalFreights = (funcs) => {
  return [
    {
      name: "Emisor",
      center: true,
      width: "10%",
      key: "emmiter",
      cell: (row) => (
        <div>
          <input
            type="checkbox"
            className="p-2 mx-1 mt-2 float-left relative"
          ></input>
          <span
            className="ml-1 float-left relative w-3/4"
            style={{ opacity: row.isActiveUnit === 0 ? 0.3 : 1 }}
          >
            {row.emmiter}
          </span>
        </div>
      ),
    },
    {
      name: "Estatus de pago",
      width: "10%",
      center: true,
      key: "statuspayment",
      cell: customCell("statuspayment", "center"),
    },
    {
      name: "# Boleta, Ejido",
      width: "10%",
      center: true,
      key: "folioBallot",
      cell: (row) => (
        <div style={{ textAlign: "left", width: "95%" }}>
          {row.folioBallot}
          <br />
          {row.ejido}
        </div>
      ),
    },
    {
      name: "Cuota",
      width: "7%",
      center: true,
      key: "ejido",
      cell: customCell("quota", "center"),
    },
    {
      name: "Neto",
      width: "7%",
      center: true,
      key: "net",
      cell: customCell("net", "center"),
    },
    {
      name: "Importe flete",
      width: "8%",
      center: true,
      key: "amountPayable",
      cell: customCell("amountPayable", "center"),
    },
    {
      name: "Dif flete",
      width: "9%",
      center: true,
      key: "valueFreightDifference",
      cell: customCell("valueFreightDifference", "center"),
    },
    {
      name: "Bonos",
      width: "7%",
      center: true,
      key: "freightAmount",
      cell: customCell("freightAmount", "center"),
    },
    {
      name: "Por Pagar",
      width: "7%",
      center: true,
      key: "amountPayable",
      cell: customCell("amountPayable", "center"),
    },
    {
      name: "Vehículo",
      width: "10%",
      center: true,
      key: "infoVehicle",
      cell: customCell("infoVehicle", "center"),
    },
    {
      name: "Chofer",
      width: "10%",
      center: true,
      key: "freighter",
      selector: "freighter",
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEdit}
            onClick={() => {
              funcs.edit(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsCalculationFreights = (funcs) => {
  return [
    {
      name: "Origen",
      center: true,
      width: "13%",
      key: "origen",
      cell: customCell("origen"),
    },
    {
      name: "Destino",
      center: true,
      width: "13%",
      key: "destino",
      cell: customCell("destino"),
    },
    {
      name: "Kilómetros",
      width: "10%",
      center: true,
      key: "kilometres",
      cell: customCell("kilometres"),
    },
    {
      name: "Tipo de vehículo",
      width: "10%",
      center: true,
      key: "vehicleType",
      cell: customCell("vehicleType"),
    },
    {
      name: "Tonelaje asignado",
      width: "10%",
      center: true,
      key: "ton",
      cell: customCell("ton"),
    },
    {
      name: "Precio",
      width: "7%",
      center: true,
      key: "price",
      cell: customCell("price"),
    },
    {
      name: "Precio por tonelada",
      width: "10%",
      center: true,
      key: "tonPrice",
      cell: customCell("tonPrice"),
    },
    {
      name: "Utilidades",
      width: "10%",
      center: true,
      key: "utility",
      cell: customCell("utility"),
    },
    {
      name: "Casetas",
      width: "7%",
      center: true,
      key: "tollbooth",
      cell: customCell("tollbooth"),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "10%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEdit}
            onClick={() => {
              funcs.edit(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsSeasons = (funcs) => [
  {
    name: "Unidad",
    center: true,
    width: "25%",
    key: "name",
    cell: customCell("name", "left"),
  },
  {
    name: "Fecha de inicio",
    center: true,
    width: "30%",
    key: "startDate",
    cell: customCell("startDate", "center", (t) =>
      parseObjectDate(new Date(t), 3)
    ),
  },
  {
    name: "Fecha de fin",
    center: true,
    width: "30%",
    key: "endDate",
    cell: customCell("endDate", "center", (t) =>
      parseObjectDate(new Date(t), 3)
    ),
  },
  {
    name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
    width: "15%",
    center: true,
    cell: (row) => (
      <>
        <ButtonIcon
          icon={faEye}
          onClick={() => {
            funcs.details(row);
          }}
        />
        <ButtonIcon
          className="px-2"
          onClick={() => {
            funcs.edit(row);
          }}
          icon={faEdit}
        />
      </>
    ),
  },
];

export const getColumnsSeasonDetails = () => {
  return [
    {
      name: "Producto",
      center: true,
      width: "12.5%",
      key: "unit",
      cell: customCell("unit", "left"),
    },
    {
      name: "Volumen commpra",
      center: true,
      width: "12.5%",
      key: "startDate",
      cell: customCell("startDate", "center", (t) =>
        parseObjectDate(new Date(t), 3)
      ),
    },
    {
      name: "Precio Prom",
      center: true,
      width: "12.5%",
      key: "endDate",
      cell: customCell("endDate", "center", (t) =>
        parseObjectDate(new Date(t), 3)
      ),
    },
    {
      name: "Ventas",
      center: true,
      width: "12.5%",
      key: "endDate",
      cell: customCell("endDate", "center", (t) =>
        parseObjectDate(new Date(t), 3)
      ),
    },
    {
      name: "Precio de Venta",
      center: true,
      width: "12.5%",
      key: "endDate",
      cell: customCell("endDate", "center", (t) =>
        parseObjectDate(new Date(t), 3)
      ),
    },
    {
      name: "Utilidad",
      center: true,
      width: "12.5%",
      key: "endDate",
      cell: customCell("endDate", "center", (t) =>
        parseObjectDate(new Date(t), 3)
      ),
    },
    ,
    {
      name: "Gastos",
      center: true,
      width: "12.5%",
      key: "endDate",
      cell: customCell("endDate", "center", (t) =>
        parseObjectDate(new Date(t), 3)
      ),
    },
    ,
    {
      name: "Costos",
      center: true,
      width: "12.5%",
      key: "endDate",
      cell: customCell("endDate", "center", (t) =>
        parseObjectDate(new Date(t), 3)
      ),
    },
  ];
};

export const getColumnsAssignQuotas = (funcs, extData) => {
  return [
    {
      name: "Ejido",
      center: true,
      width: "15%",
      key: "ejido",
      cell: customCell("ejido", "left"),
    },
    {
      name: "Campo",
      center: true,
      width: "15%",
      key: "originField",
      cell: customCell("originField", "left"),
    },
    {
      name: "Rol",
      center: true,
      width: "15%",
      key: "role",
      cell: customCell("role", "left"),
    },
    {
      name: "Persona",
      center: true,
      width: "15%",
      key: "supplier",
      cell: customCell("supplier", "left"),
    },
    {
      name: "Producto",
      center: true,
      width: "15%",
      key: "product",
      cell: customCell("product", "left"),
    },
    {
      name: "Cuota",
      center: true,
      width: "15%",
      key: "quota",
      cell: customCell("quota", "center"),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "10%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEdit}
            onClick={() => {
              funcs.edit(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsRelationIssuersBallots = (funcs, extData) => {
  return [
    {
      name: (
        <Checkbox
          className="ntaTableCheckbox"
          checked={extData.allChecked}
          onChange={(e) => {
            funcs.handleSelection(e.target.checked);
          }}
        />
      ),
      center: true,
      width: "4%",
      key: "checked",
      cell: (row, ix) => (
        <Checkbox
          onChange={(e) => {
            funcs.handleSelection(e.target.checked, ix, row.idProduct);
          }}
          checked={row.checked}
        />
      ),
    },
    {
      name: "Emisor",
      center: true,
      width: "12%",
      key: "emisor",
      id: "emisor",
      cell: customCell("name", "left"),
    },
    {
      name: "# Boleta",
      center: true,
      width: "10%",
      key: "numBoleta",
      cell: customCell("name", "left"),
    },
    {
      name: "Productores",
      center: true,
      width: "18%",
      key: "productor",
      // cell: customCell("createdAt", "center", (t) => parseObjectDate(new Date(t), 3)),
    },
    {
      name: "Producto",
      center: true,
      width: "10%",
      key: "producto",
      // cell: (row) => (
      //   <div style={{ textAlign: "center", width: "100%" }}>
      //     <span
      //       className="py-1 px-3 rounded-lg"
      //       style={{
      //         backgroundColor: getBallotStatusColor(row.idCatBallotStatus),
      //       }}
      //     >
      //       {row.status}
      //     </span>
      //   </div>
      // ),
    },
    {
      name: "Kilos",
      center: true,
      width: "12%",
      key: "kilos",
      // cell: (row) => (
      //   <div style={{ textAlign: "left", width: "100%" }}>
      //     {row.vehicle?.plates}
      //     <br />
      //     {row.vehicle?.cat_vehicle?.name + " " + row.vehicle?.cat_vehicle?.description}
      //   </div>
      // ),
    },
    {
      name: "Monto Total",
      center: true,
      width: "12%",
      key: "montoTotal",
      // cell: (row) => (
      //   <div style={{ textAlign: "left", width: "100%" }}>
      //     {row.vehicle?.plates}
      //     <br />
      //     {row.vehicle?.cat_vehicle?.name + " " + row.vehicle?.cat_vehicle?.description}
      //   </div>
      // ),
    },
    {
      name: "Estatus",
      center: true,
      width: "12%",
      key: "estatus",
      // cell: (row) => (
      //   <div style={{ textAlign: "center", width: "100%" }}>
      //     <span
      //       className="py-1 px-3 rounded-lg"
      //       style={{
      //         backgroundColor: getBallotStatusColor(row.idCatBallotStatus),
      //       }}
      //     >
      //       {row.status}
      //     </span>
      //   </div>
      // ),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "10%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEye}
            onClick={() => {
              funcs.details(row);
            }}
          />
          <ButtonIcon
            icon={faArrowRotateLeft}
            onClick={() => {
              funcs.reassing(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsInvoiceDetails = (funcs, extData) => {
  return [
    {
      name: "# Boleta",
      center: true,
      width: "8%",
      key: "ejido",
      id: "ejido",
    },
    {
      name: "Producto",
      center: true,
      width: "10%",
      key: "producto",
    },
    {
      name: "Kilos",
      center: true,
      width: "10%",
      key: "kilos",
    },
    {
      name: "Monto Producto",
      center: true,
      width: "10%",
      key: "monto",
    },
    {
      name: "Flete",
      center: true,
      width: "10%",
      key: "flete",
    },
    {
      name: "Boletero",
      center: true,
      width: "10%",
      key: "boletero",
    },
    {
      name: "Representante",
      center: true,
      width: "11%",
      key: "representante",
    },
    {
      name: "Adicional",
      center: true,
      width: "8%",
      key: "adicional",
    },
    {
      name: "Precio",
      center: true,
      width: "8%",
      key: "boletero",
    },
    {
      name: "TOTAL",
      center: true,
      width: "11%",
      key: "total",
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faXmark}
            onClick={() => {
              funcs.details(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsAssignSATPurchase = (funcs, extData) => {
  return [
    {
      name: "# Boleta",
      center: true,
      width: "18%",
      key: "boleta",
      id: "boleta",
    },
    {
      name: "Producto",
      center: true,
      width: "17%",
      key: "producto",
    },
    {
      name: "Kilos",
      center: true,
      width: "17%",
      key: "kilos",
    },
    {
      name: "Monto Producto",
      center: true,
      width: "18%",
      key: "monto",
    },
    {
      name: "TOTAL",
      center: true,
      width: "20%",
      key: "total",
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "10%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEye}
            onClick={() => {
              funcs.details(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsCapturedOrders = (funcs, extData) => {
  return [
    {
      name: (
        <Checkbox
          className="ntaTableCheckbox"
          checked={extData.allChecked}
          onChange={(e) => {
            funcs.handleSelection(e.target.checked);
          }}
        />
      ),
      center: true,
      width: "4%",
      key: "checked",
      cell: (row, ix) => (
        <Checkbox
          onChange={(e) => {
            funcs.handleSelection(e.target.checked, ix, row.idProduct);
          }}
          checked={row.checked}
        />
      ),
    },
    {
      name: "Pedido",
      center: true,
      key: "pedido",
      id: "pedido",
      cell: customCell("folio", "center"),
    },
    {
      name: "Origen",
      center: true,
      key: "origen",
      cell: customCell("origin", "center"),
    },
    {
      name: "Destino",
      center: true,
      key: "destination",
      cell: customCell("destination", "center"),
    },
    {
      name: "Cliente",
      center: true,
      key: "cliente",
      cell: customCell("client", "center"),
    },
    {
      name: "Producto",
      center: true,
      key: "producto",
      cell: customCell("product", "center"),
    },
    {
      name: "Humedad",
      center: true,
      key: "humedad",
      cell: customCell("humidity", "center"),
    },
    {
      name: "Kilogramos",
      center: true,
      key: "kilogramos",
      cell: customCell("kilograms", "center"),
    },
    {
      name: "Precio en Facturación",
      center: true,
      key: "precioFac",
      cell: customCell("invoicePrice", "center"),
    },
    {
      name: "Fecha de Carga",
      center: true,
      key: "fechaCarga",
      cell: customCell("uploadDate", "center", (t) =>
        t ? parseObjectDate(new Date(santizeDateTime(t)), 3) : "-"
      ),
    },
    {
      name: "Frecuencia y Fecha de Recepción",
      center: true,
      key: "frecuenciaFecha",
      cell: (row) => (
        <div style={{ textAlign: "left", width: "100%" }}>
          {row.frequency === "1 - MONTH" ? "Mensual" : "Semanal"} <br />
          {parseObjectDate(new Date(row.receptionDate), 3)}
        </div>
      ),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEdit}
            onClick={() => {
              funcs.edit(row);
            }}
          />
          <ButtonIcon
            icon={faBan}
            onClick={() => {
              funcs.delete(row);
            }}
          />
        </>
      ),
    },
  ];
};

export const getColumnsSales = (funcs) => {
  return [
    {
      name: "Número de embarque",
      center: true,
      width: "9%",
      key: "folioBoarding",
      cell: customCell("folioBoarding"),
    },
    {
      name: "Factura",
      center: true,
      width: "9%",
      key: "invoice",
      cell: customCell("invoice"),
    },
    {
      name: "Cliente",
      center: true,
      width: "9%",
      key: "companyName",
      cell: customCell("companyName"),
    },
    {
      name: "Origen",
      center: true,
      width: "9%",
      key: "origin",
      cell: customCell("origin"),
    },
    {
      name: "Destino",
      center: true,
      width: "9%",
      key: "destination",
      cell: customCell("destination"),
    },
    {
      name: "Grano",
      center: true,
      width: "8%",
      key: "product",
      cell: customCell("product"),
    },
    {
      name: "Kilogramos",
      center: true,
      width: "8%",
      key: "kilograms",
      cell: customCell("kilograms", "center", (t) => `${t} Kg`),
    },
    {
      name: "Precio producto",
      center: true,
      width: "9%",
      key: "productPrice",
      cell: customCell("productPrice", "center", (t) => `$ ${t}`),
    },
    {
      name: "Precio producto con flete",
      center: true,
      width: "9%",
      key: "freightProductPrice",
      cell: customCell("freightProductPrice", "center", (t) => `$ ${t}`),
    },
    {
      name: "Precio producto con flete / IVA",
      center: true,
      width: "8%",
      key: "freightProductPriceIva",
      cell: customCell("freightProductPriceIva", "center", (t) => `$ ${t}`),
    },
    {
      name: "Total",
      center: true,
      width: "8%",
      key: "total",
      cell: customCell("total", "center", (t) => `$ ${t}`),
    },
    {
      name: <FontAwesomeIcon className="text-3xl" icon={faGear} />,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <ButtonIcon
            icon={faEye}
            onClick={() => {
              funcs.details(row);
            }}
          />
          {/*<ButtonIcon
            icon={faEdit}
            onClick={() => {
              funcs.edit(row);
            }}
          />*/}
        </>
      ),
    },
  ];
};
