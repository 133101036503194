import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import {
  behaviors,
  destinationItemSchema,
  destinationSchema,
  destinationsSchema,
} from "../helpers/schemas";
import { parseCat } from "../helpers/utils";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import "../App";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../helpers/api";
import ButtonIcon from "../components/ButtonIcon";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const Destinations = () => {
  const [
    newDestination,
    newDestinationChange,
    newDestinationReset,
    setAllValues,
  ] = useForm(destinationSchema);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    const res = await Request(`/client`);
    if (res.ok) {
      setClients(res.data);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleDestinationChange = (name) => {
    const value = newDestination[name];
    if (name === "idClient") {
      newDestinationChange({ target: { name: "rfc", value } });
    } else {
      newDestinationChange({ target: { name: "idClient", value } });
    }
  };

  const handleDestinationItemChange = (e, ix) => {
    const { destinations } = newDestination;
    const destination = destinations[ix];
    destination[e.target.name] = e.target.value;
    newDestinationChange({
      target: { name: "destinations", value: destinations },
    });
  };

  const manageDestination = (willDetele = false, ix = null) => {
    const { destinations } = newDestination;
    if (willDetele) {
      destinations.splice(ix, 1);
    } else {
      destinations.push({ ...destinationItemSchema });
    }
    newDestinationChange({
      target: { name: "destinations", value: destinations },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { idClient, destinations } = newDestination;
    if (idClient) {
      const res = await Request(
        `/client/${idClient}/destinations`,
        {
          destinations,
        },
        "PUT"
      );
      if (res.ok) {
        newDestinationReset();
        Swal.fire("Datos registrados correctamente", "", "success").then(() => {
          navigate("/alta-cuotas");
        });
      }
    } else {
      Swal.fire("Debes elegir un cliente antes de continuar", "", "error");
    }
  };

  const fetchClientData = async (idClient) => {
    console.log(idClient, newDestination.idClient);
    const res = await Request("/client/" + idClient + "/destinations");
    if (res.ok) {
      const destinations = res.destinations.map((d) => ({
        ...d,
        destinationType: d.clientDestination === "" ? "WAREHOUSE" : "CLIENT",
      }));
      setAllValues({ ...newDestination, destinations, idClient });
    }
  };

  useEffect(() => {
    handleDestinationChange("idClient");
  }, [newDestination.idClient]);

  useEffect(() => {
    handleDestinationChange("rfc");
  }, [newDestination.rfc]);

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>DESTINOS</PageTitle>
      </div>
      <NtaForm onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
          <Select
            arrOptions={[
              ...parseCat(
                clients?.filter((u) => u.isActive),
                "companyName",
                "idClient"
              ),
            ]}
            required={true}
            name="idClient"
            onChange={async (e) => {
              await fetchClientData(e.target.value);
            }}
            value={newDestination.idClient}
            label="Cliente"
          />
          <Select
            arrOptions={[
              ...parseCat(
                clients?.filter((u) => u.isActive),
                "rfc",
                "idClient"
              ),
            ]}
            required={true}
            name="rfc"
            onChange={(e) => {
              newDestinationChange(e);
            }}
            value={newDestination.rfc}
            label="RFC"
          />
          <div className="grid grid-cols-2 gap-2 w-full inputAndButtonDestination h-14">
            <span className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">
              Destinos
            </span>
            <ButtonIcon
              style={{ justifySelf: "flex-end" }}
              onClick={() => {
                manageDestination();
              }}
              className={
                "buttonPlus text-white bg-ntaBlue hover:bg-ntaGray w-14 h-14 relative float-left place-self-start place-content-center grid mt-0"
              }
              icon={faPlus}
            />
          </div>
          <div className="grid grid-cols-2 gap-2 w-full inputAndButtonDestination h-14">
            {newDestination.destinations.map((d, ix) => (
              <>
                <Input
                  type="text"
                  required={true}
                  placeholder="Destnino"
                  label=""
                  name="clientDestination"
                  maxLength={100}
                  className={"grid w-full"}
                  onChange={(e) => {
                    handleDestinationItemChange(e, ix);
                  }}
                  value={d.clientDestination}
                />

                <ButtonIcon
                  onClick={() => {
                    manageDestination(true, ix);
                  }}
                  className={
                    "text-ntaBlue w-14 h-14 relative float-left place-self-start place-content-center grid buttonTrash"
                  }
                  icon={faTrash}
                />
              </>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4 inputsForm">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            Añadir
          </Button>
        </div>
      </NtaForm>
    </>
  );
};

export default Destinations;
