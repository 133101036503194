import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import noop from "../../helpers/noop";
import Domo from "../../css/img/DomoCertificate.png";
import Logo from "../../css/img/logoNTA.svg";
import Button from "../Button";
import PageTitle from "../PageTitle";
import NtaForm from "../NtaForm";
import { TODAY } from "../../helpers/utils";

const QualityCertificate = ({
  isOpen,
  data = { idProduct: "" },
  catalogues = { products: [] },
  toggle,
  onConfirm = noop,
  onCancel = noop,
  manageSingleWarehouse,
  onWarehouseProductChange,
}) => {
  const { products = [], vehicles = [], persons = [] } = catalogues;
  const {
    idProduct,
    density,
    humidity,
    broken,
    impurities,
    firedGrain,
    color,
    smell,
    volumetricWeight,
    aflatoxinas,
    certificateSigningManager,
    tara,
    gross,
    net,
    idVehcle,
    idFreighter,
    comments,
  } = data;
  const product = products.find((p) => p.idProduct === idProduct) || {};
  const vehicle = vehicles.find((p) => p.idVehcle === idVehcle) || {};
  const freighter = persons.find((p) => p.idSupplier === idFreighter) || {};
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} className={"mt-10"}>
      <NtaForm
        onSubmit={(e) => {
          e.preventDefault();
          toggle();
        }}
      >
        <ModalHeader>
          <div className="text-center my-6 grid grid-cols-3 titleCertificate place-items-end">
            <img src={Domo} className="w-3/4 -mr-4" />
            <PageTitle className="mb-4 mr-8">
              INDUSTRIAS DOMO, S.A. DE C.V.
            </PageTitle>
            <img src={Logo} className="w-6/7 float-right mb-20 mr-2" />
          </div>
        </ModalHeader>
        <ModalBody className="modalQualityCertificate my-10">
          <h3 className="text-3xl text-center text-ntaBlue my-2 font-bold">
            CERTIFICADO DE CALIDAD
          </h3>

          {/*--------CRITERIOS DE CALIDAD--------*/}
          <div className="grid grid-cols-2 certificateProduct">
            <div className="text-ntaBlue my-2 text-left text-xl ml-12">
              <div className="my-4">
                <span className="font-bold"> PRODUCTO: </span>
                <span className="font-normal"> {product.name} </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> HUMEDAD: </span>
                <span className="font-normal"> {humidity} </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> GRANO QUEBRADO: </span>
                <span className="font-normal"> {broken}% MÁXIMO </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> IMPUREZAS: </span>
                <span className="font-normal"> {impurities}% MÁXIMO </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> PESO VOLUMÉTRICO: </span>
                <span className="font-normal">
                  {volumetricWeight} HL MÍNIMO
                </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> AFLATOXINAS: </span>
                <span className="font-normal"> {aflatoxinas} </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> OLOR: </span>
                <span className="font-normal"> {smell} </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> CHOFER: </span>
                <span className="font-normal"> {freighter.name} </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> PLACAS: </span>
                <span className="font-normal"> {vehicle.plates} </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> PESO BRUTO: </span>
                <span className="font-normal"> {gross} </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> TARA: </span>
                <span className="font-normal"> {tara} </span>
              </div>
              <div className="my-4">
                <span className="font-bold"> PESO NETO: </span>
                <span className="font-normal"> {net} </span>
              </div>
              <div className="my-4">
                <span className="font-bold mb-4"> OBSERVACIONES: </span>
                <br />
                <span className="font-normal top-4 relative"> {comments} </span>
              </div>

              {/*--------ATENTAMENTE--------*/}
              <div className="mt-12 top-20 relative">
                <span className="font-bold "> ATENTAMENTE </span>
                <br />
                <span className="font-normal top-4 relative">
                  {" "}
                  {certificateSigningManager}{" "}
                </span>
              </div>
            </div>

            {/*--------FECHA--------*/}
            <div className="text-ntaBlue my-2 text-left text-xl ml-4">
              <div className="my-4">
                <span className="font-bold"> FECHA: </span>
                <span className="font-normal"> {TODAY} </span>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="grid grid-cols-2 gap-5 relative float-right right-8">
            <Button
              type="button"
              onClick={onCancel}
              className={"bg-ntaGray text-lg"}
            >
              Cancelar
            </Button>
            <Button type="submit" className="text-lg">
              Imprimir
            </Button>
          </div>
        </ModalFooter>
      </NtaForm>
    </Modal>
  );
};

QualityCertificate.propTypes = {};

export default QualityCertificate;
