import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getColumnsEjidos } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Input from "../components/Input";
import "../App";
import "../App.css";
import Swal from "sweetalert2";
import ActiveFilter from "../components/ActiveFilter";

const EjidoList = () => {
  const [allData, setAllData] = useState([]);
  const [totals, setTotals] = useState({ grand: {}, filtered: {} });
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const [searchEjido, setSearchEjido] = useState("");
  let catalogues = useSelector((s) => s.catalogues);
  let { products: catProducts = [] } = catalogues;
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);

  const handleSearch = (lookupField, searchValue) => {
    const filteredData = [];
    const searchLower = searchValue.toLowerCase();
    const searchFields = lookupField === "all" ? ["unit", "name", "warehouse"] : [lookupField];

    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const fetchData = useCallback(async () => {
    const grand = {};
    const res = await Request(`/ejido`, null, "GET");
    const trasnformedData = res.data.map((d) => {
      const stocks = d.idProductsStocks?.split(",") || [];
      const stocksOb = {};
      stocks.forEach((s) => {
        const stockById = s.split("_");
        stocksOb[stockById[0]] = stockById[1];
      });
      Object.keys(stocksOb).forEach((k) => {
        const floatStock = parseFloat(stocksOb[k]);
        d[k] = floatStock;
        grand[k] = grand[k] ? parseFloat(grand[k]) + floatStock : floatStock;
      });
      return d;
    });
    setTotals({ ...totals, grand });
    setAllData(trasnformedData);
    const filteredData = [];
    trasnformedData.forEach((d) => {
      if (d.isActive) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  }, []);

  const dtFuncs = {
    details: (row) => {
      /*setSingleData(row);
      setModalOpen(true);*/
    },
    edit: (row) => {
      const { idEjido } = row;
      navigate("/editar-ejido/" + idEjido);
    },
    delete: (row) => {
      const { idEjido } = row;
      Swal.fire({
        title: `¿Deseas ${row.isActive ? "in" : ""}activar este ejido?`,
        icon: "question",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request("/ejido/" + idEjido, { isActive: !row.isActive }, "PATCH");
          if (res.ok) {
            await Swal.fire({ title: "Modificación exitosa", icon: "success" });
            setIsActive(true);
            fetchData();
          }
        }
      });
    },
  };

  const handleActive = (isActive) => {
    let filteredData = allData.filter((ad) => ad.isActive == isActive);
    setIsActive(isActive);
    setData(filteredData);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, catalogues]);

  useEffect(() => {
    const filtered = {};
    data.forEach((d) => {
      const stocks = d.idProductsStocks?.split(",") || [];
      const stocksOb = {};
      stocks.forEach((s) => {
        const stockById = s.split("_");
        stocksOb[stockById[0]] = stockById[1];
      });
      Object.keys(stocksOb).forEach((k) => {
        const floatStock = parseFloat(stocksOb[k]);
        d[k] = floatStock;
        filtered[k] = filtered[k] ? parseFloat(filtered[k]) + floatStock : floatStock;
      });
      return d;
    });
    setTotals({ ...totals, filtered });
  }, [data]);

  return (
    <>
      <Menu />
      <div className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList" style={{ width: "90%" }}>
        <div className="grid grid-cols-3 inputsForm">
          <PageTitle>LISTA DE EJIDOS</PageTitle>
          <ActiveFilter isActive={isActive} handleActive={handleActive} />
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/nuevo-ejido");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/nueva-ejido");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-5 inputsForm">
          <Input
            label="Buscar por Almacén"
            onChange={(e) => {
              setSearch(e.target.value);
              handleSearch("warehouse", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por nombre almacén"
          />
          <Input
            label="Buscar por Ejido"
            onChange={(e) => {
              setSearchEjido(e.target.value);
              handleSearch("name", e.target.value);
            }}
            name="searchEjido"
            required={false}
            value={searchEjido}
            placeholder="Buscar por nombre de ejido"
          />
          <Button
            style={{ placeSelf: "end", width: "6rem" }}
            className={"w-1/3 px-0 mt-14 mb-2 align-right text-center"}
            onClick={() => {
              fetchData();
              setSearch("");
              setIsActive(true);
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsEjidos(dtFuncs, { catProducts })} />

        <table className="vehicles-table" style={{ marginBottom: "2rem" }}>
          <thead>
            <tr>
              <th width="8rem">TOTAL</th>
              {catProducts.map((p) => (
                <th width={`${58 / catProducts.length}rem`}>{totals.grand[p.idProduct] || " - "} Kg</th>
              ))}
              <th width="2rem" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TOTAL FILTRADO</td>
              {catProducts.map((p) => (
                <td>{totals.filtered[p.idProduct] || " - "} Kg</td>
              ))}
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EjidoList;
