import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import noop from "../../helpers/noop";
import PropTypes from "prop-types";
import Button from "../Button";
import PageTitle from "../PageTitle";
import { useForm } from "../../helpers/useForm";
import NtaForm from "../NtaForm";
import Input from "../Input";
import NtaDataTable from "../NtaDataTable";
import {
  getColumnsArrivals,
  getColumnsDepartures,
} from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { TODAY } from "../../helpers/utils";
import Swal from "sweetalert2";

const Kardex = ({ isOpen, data = {}, toggle, onCancel = noop }) => {
  const [kardex, setKardex] = useState({
    entries: [],
    exits: [],
  });
  const [filters, setFilters, clearFilters, setAllFilters] = useForm({
    startDate: TODAY,
    endDate: TODAY,
  });
  const dtFuncs = {
    details: (row) => {},
    delete: (row) => {
      Swal.fire({
        title: "¿Deseas cancelar la entrada?",
        text: "Escribe el motivo de cancelación (ésta acción es irreversible)",
        icon: "warning",
        input: "text",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debes introducir un motivo de cancelación";
          }
        },
      }).then(async ({ isConfirmed, value: cancellationComment }) => {
        if (isConfirmed) {
          const res = await Request(
            `/entrie/${row.idEntrie}`,
            { cancellationComment },
            "PATCH"
          );
          if (res.ok) {
            Swal.fire({
              title: "Entrada cancelada con éxito",
              icon: "success",
            }).then(() => {
              const { idInventory } = data;
              fetchKardex(idInventory, filters);
            });
          }
        }
      });
    },
  };

  const fetchKardex = useCallback(async (idInventory, filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(
      "/inventory/" + idInventory + "?" + filterStr,
      null,
      "GET"
    );
    if (res.ok) {
      setKardex(res.data);
    }
  }, []);

  useEffect(() => {
    const { startDate, endDate, idInventory } = data;
    if (idInventory && isOpen) {
      setAllFilters({ startDate, endDate });
      fetchKardex(idInventory, filters);
    }
  }, [isOpen]);

  useEffect(() => {
    const { idInventory } = data;
    if (idInventory) {
      fetchKardex(idInventory, filters);
    }
  }, [filters]);

  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} className={"mt-10"}>
      <NtaForm>
        <ModalHeader>
          <div className="text-left ml-4 mt-3">
            <PageTitle>KARDEX</PageTitle>
            <h3 className="text-2xl text-left text-ntaBlue mt-2 mb-0 font-bold">
              UNIDAD: <span className="font-normal">{kardex.unit}</span>
            </h3>
            <h3 className="text-2xl text-left text-ntaBlue mt-2 mb-0 font-bold">
              ALMACÉN: <span className="font-normal">{kardex.warehouse}</span>
            </h3>
            <h3 className="relative float-right right-10 text-2xl text-ntaBlue font-bold px-1 -mt-8">
              PRODUCTO: <span className="font-normal">{kardex.product}</span>
            </h3>
          </div>
        </ModalHeader>
        <ModalBody className="modalKardexBody -mt-4 ml-4">
          <div className="grid grid-cols-4 gap-4">
            <Input
              type="date"
              required={false}
              label="Fecha inicio"
              name="startDate"
              max={TODAY}
              onChange={setFilters}
              value={filters.startDate}
            />
            <Input
              type="date"
              required={false}
              label="Fecha Fin"
              name="endDate"
              max={TODAY}
              onChange={setFilters}
              value={filters.endDate}
            />
            <div />
            <div />
          </div>
          <div className="w-full mt-8">
            <h4 className="text-xl text-ntaBlue font-bold my-2">Entradas</h4>
            <NtaDataTable
              data={kardex.entries}
              pagination={false}
              columnsDef={getColumnsArrivals(dtFuncs)}
              className="mx-auto"
            />
          </div>
          <div className="w-full mt-8">
            <h4 className="text-xl text-ntaBlue font-bold my-2">Salidas</h4>
            <NtaDataTable
              data={kardex.exits}
              pagination={false}
              columnsDef={getColumnsArrivals(dtFuncs)}
              className="mx-auto"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="grid grid-cols-1 gap-5 relative float-right">
            <Button
              type="button"
              onClick={onCancel}
              className={"bg-ntaGray text-lg"}
            >
              Cerrar
            </Button>
          </div>
        </ModalFooter>
      </NtaForm>
    </Modal>
  );
};

Kardex.propTypes = {};

export default Kardex;
