import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { parseObjectDate } from "../../helpers/utils";
import Button from "../Button";
import noop from "../../helpers/noop";
import PageTitle from "../PageTitle";

const SalesDetails = ({
  isOpen,
  data,
  toggle,
  onConfirm = noop,
  onCancel = noop,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        <PageTitle>DETALLE DE VENTA</PageTitle>
      </ModalHeader>
      <ModalBody>
        <div className="py-6 border-y-2 border-ntaBlue">
          <p className="text-3xl my-2">
            <b>NÚMERO DE EMBARQUE: </b> {data.folioBoarding}
          </p>
          <div className="grid grid-cols-2 gap-4">
            <p className="text-xl my-2">
              <b>FECHA DE EMBARQUE: </b>{" "}
              {parseObjectDate(new Date(data.boardingDate), 2)}
            </p>
            <p className="text-xl my-2">
              <b>FECHA DE ENTREGA: </b>{" "}
              {data.deliveryDate
                ? parseObjectDate(new Date(data.deliveryDate), 2)
                : "-"}
            </p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <p className="text-xl my-2">
              <b>ORIGEN</b> {data.origin}
            </p>
            <p className="text-xl my-2">
              <b>DESTINO</b> {data.destination}
            </p>
            <p className="text-xl my-2">
              <b>CLIENTE</b> {data.companyName || "-"}
            </p>
          </div>
        </div>
        <div className="py-6">
          <p className="text-3xl mt-2">
            <b>GRANO: </b> {data.product}
          </p>
          <p className="text-3xl mb-2">
            <b>KILOGRAMOS: </b> {data.kilograms} Kg
          </p>
          <div className="grid grid-cols-2">
            <div className="text-left">
              <p className="text-xl my-2">
                <b>PESO ORIGEN: </b> {data.originWeight} Kg
              </p>
              <p className="text-xl my-2">
                <b>PESO DESTINO: </b> {data.destinationWeight} Kg
              </p>
              <p className="text-xl my-2">
                <b>CHOFER: </b> {data.freighter}
              </p>
            </div>
            <div className="text-right">
              <p className="text-xl my-2">
                <b>PEDIDO: </b> $ {data.productPrice}
              </p>
              <p className="text-xl my-2">
                <b>ENTREGADO: </b> $ {data.freightProductPrice}
              </p>
              <p className="text-xl my-2">
                <b>SALDO: </b> $ {data.total}
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 w-full btnModal">
          <div />
          <div />
          <Button
            onClick={() => {
              toggle();
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

SalesDetails.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SalesDetails;
