import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Input from "../components/Input";
import Swal from "sweetalert2";
import { getColumnsInactiveWarehouses } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import "../App";
import "../App.css";

const InactiveWarehouseList = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const fetchData = useCallback(async () => {
    const res = await Request(`/warehouse?isActive=false`, undefined, "GET");
    if (res.ok) {
      setAllData(res.data);
      setData(res.data);
    }
  });
  const reactiveWarehouse = async (idWarehouse) => {
    Swal.fire({
      title: "¿Estás seguro de reactivar éste almacén?",
      text: "El almacén volverá a estar disponible",
      icon: "info",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const res = await Request("/warehouse/" + idWarehouse, { isActive: true }, "PATCH");
        if (res.ok) {
          await Swal.fire({ title: "Modificación exitosa", icon: "success" });
          fetchData();
        }
      }
    });
  };
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields = lookupField === "all" ? ["name", "unit"] : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const funcs = {
    edit: (row) => {
      reactiveWarehouse(row.idWarehouse);
    },
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Menu />
      <div className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8">
        <div className="grid grid-cols-2 titleWarehouseList">
          <PageTitle>LISTA DE ALMACENES INACTIVOS</PageTitle>
          <div className="w-full">
            <a
              className="addProductsLink pt-8 pb-6 mt-14 font-bold right relative right-16"
              onClick={() => {
                navigate("/unidades");
              }}
            >
              <span className="text-ntaBlue text-lg pr-2 -mt-4">Regresar a unidades</span>
            </a>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por Unidad o Almacén"
          />
          <Button className={"w-1/4 px-0 mt-14 mb-2 align-right text-center"} style={{ placeSelf: "end", width: "6rem" }}>
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsInactiveWarehouses(funcs)} />
      </div>
    </>
  );
};

export default InactiveWarehouseList;
