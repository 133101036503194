import { weekDays } from "./enums";

export const parseCat = (arr, strTxt, strVal) => {
  if (arr) {
    return arr.map((n) => {
      return {
        label: n[strTxt],
        value: n[strVal],
      };
    });
  }
  return [];
};

export const getAccountProps = (bankAccountType) => {
  switch (bankAccountType) {
    case "TARJETAC":
      return {
        pattern:
          "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$",
        maxLength: 16,
        minLength: 15,
        placeholder: "16 dígitos",
      };
    case "TARJETAD":
      return {
        pattern:
          "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$",
        maxLength: 16,
        minLength: 16,
        placeholder: "16 dígitos",
      };
    case "CLABE":
      return {
        pattern: "^([0-9]{18})",
        maxLength: 18,
        minLength: 18,
        placeholder: "18 dígitos",
      };
    case "CUENTA":
      return {
        pattern: "^([0-9]{10})",
        maxLength: 10,
        minLength: 10,
        placeholder: "10 dígitos",
      };
  }
};

export const parseObjectDate = (date, format = 1) => {
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let r;
  switch (format) {
    case 1: // DD/MM/YYYY
      r = [
        date.getDate().toString().padStart(2, "0"),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getFullYear(),
      ].join("/");
      break;
    case 2: // Dia Mes YY
      r = [
        date.getDate().toString().padStart(2, "0"),
        meses[date.getMonth()],
        date.getFullYear().toString().substring(2, 4) + "'",
      ].join(" ");
      break;

    case 3: // DD/MM/YYYY
      r = [
        date.getDate().toString().padStart(2, "0"),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getFullYear(),
      ].join("/");
      break;
    case 4: // YYYY-MM-DD
      r = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
      ].join("-");
      break;
    default:
  }
  return r;
};

export const getBallotStatusColor = (status) => {
  let r;
  switch (status) {
    case 1:
      r = "#DDA30F";
      break;
    case 2:
      r = "#929292";
      break;
    case 3:
      r = "#58AE30";
      break;
    case 4:
      r = "#52B6FF";
      break;
    case 5:
      r = "#FF5C52";
      break;
    default:
      r = "#000";
  }
  return r;
};

export const parseVoidFloat = (v) => {
  return v === "" ? 0 : parseFloat(v);
};

export const parseVoidZeroFloat = (v) => {
  return v === "" || v === 0 ? 1 : parseFloat(v);
};

export const parseFloatPq = (x) => {
  if (x == 0) return [0, 1];
  if (x < 0) x = -x;
  const tolerance = 0.0001;
  var num = 1,
    den = 1;
  const iterate = () => {
    var R = num / den;
    if (Math.abs((R - x) / x) < tolerance) return;
    if (R < x) num++;
    else den++;
    iterate();
  };
  iterate();
  return [num, den];
};

export const TODAY = parseObjectDate(new Date(), 4);

export const getNet = (data, successFunc = () => {}) => {
  const { gross = "", tara = "" } = data;
  if (gross !== "" && tara !== "") {
    successFunc(parseInt(gross - tara));
  }
};

export const parseOrigin = (data) => {
  const [id, originType = ""] = data.idOrigin.split("_");
  const idEjidoOrigin = originType === "EJIDO" ? parseInt(id) : "";
  const idWarehouseOrigin = originType === "WAREHOUSE" ? parseInt(id) : "";
  return { idEjidoOrigin, idWarehouseOrigin, originType };
};

export const parseReverseOrigin = (data) => {
  const { idEjidoOrigin, idWarehouseOrigin, originType } = data;
  return {
    idOrigin: `${
      idEjidoOrigin && idEjidoOrigin !== 0 ? idEjidoOrigin : idWarehouseOrigin
    }_${originType}`,
  };
};

export const parseDestination = (data) => {
  const [id, destinationType = ""] = data.idDestination.split("_");
  const idClientDestination = destinationType === "CLIENT" ? parseInt(id) : "";
  const idWarehouseDestination =
    destinationType === "WAREHOUSE" ? parseInt(id) : "";
  return { idClientDestination, idWarehouseDestination, destinationType };
};

export const parseReverseDestination = (data) => {
  const { idClientDestination, idWarehouseDestination, destinationType } = data;
  return {
    idDestination: `${
      idClientDestination || idWarehouseDestination
    }_${destinationType}`,
  };
};

export const parseInputDate = (inputDate) => {
  let parts = inputDate.split("-");
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

export const parseAvailableDaysToArray = (objData, posfix = "Available") => {
  return (
    weekDays
      .map(({ value }, ix) => {
        const accessKey = value + posfix;
        const isAvailable =
          typeof objData[accessKey] === "boolean" && objData[accessKey];
        return isAvailable ? ix : null;
      })
      ?.filter((fv) => fv !== null) || []
  );
};

export const santizeDateTime = (dateStr) => {
  return dateStr.split("T")[0] + "T12:00:00";
};
