import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTitle from "../components/PageTitle";
import Menu from "../components/Menu";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Button from "../components/Button";
import NtaSelect from "../components/Select";
import { initialPlatformSchema } from "../helpers/schemas";
import { useForm } from "../helpers/useForm";
import { useDispatch, useSelector } from "react-redux";
import { parseCat } from "../helpers/utils";
import Swal from "sweetalert2";
import { GENERAL_PLATFORM } from "../redux/actions";
import { getFromSession } from "../helpers/storageHandler";
import { initialFetch } from "../redux/actions/auth";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const idUnit = getFromSession("idUnit");
  const idSeason = getFromSession("idSeason");
  const [data, dataChange, dataReset, setAllData] = useForm({
    ...initialPlatformSchema,
  });
  const { units = [] } = useSelector((s) => s.catalogues);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { idSeason, idUnit } = data;
    const unit = units.find((u) => u.idUnit == idUnit);
    const season = unit.seasons.find((s) => s.idSeason == idSeason);
    const { startDate, name, endDate } = season;
    if (idSeason !== "" && idUnit !== "") {
      localStorage.setItem("idUnit", idUnit);
      localStorage.setItem("idSeason", idSeason);
      localStorage.setItem("startDate", startDate);
      localStorage.setItem("endDate", endDate);
      localStorage.setItem("name", name);
      dispatch({ type: GENERAL_PLATFORM, payload: { ...data, ...season } });
      dispatch(initialFetch(getFromSession("jwt")));
      Swal.fire(
        "Excelente",
        "Unidad y temporada seleccionadas con éxito, ya puedes utilizar la plataforma",
        "success"
      );
    } else {
      Swal.fire("Error", "Unidad y temporada son requeridos", "info");
    }
  };

  useEffect(() => {
    const value = data.idUnit;
    const unit = units.find((u) => u.idUnit == value) || {};
    if (value === "" || unit.seasons?.length === 0) {
      dataChange({ target: { value: "", name: "idSeason" } });
    }
  }, [data.idUnit]);

  useEffect(() => {
    if (
      idUnit &&
      idUnit !== "" &&
      idSeason &&
      idSeason !== "" &&
      units.length > 0
    ) {
      setAllData({ idSeason: parseInt(idSeason), idUnit: parseInt(idUnit) });
    }
  }, [units]);

  useEffect(() => {
    const { idUnit, idSeason } = data;
    const unit = units.find((u) => u.idUnit == idUnit);
    if (unit) {
      const season = unit.seasons.find((s) => s.idSeason == idSeason);
      setAllData({ ...data, ...season });
    }
  }, [idSeason]);

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>INICIO</PageTitle>
      </div>
      <div className="textoContainerHome">
        <p className="text-basic-gray text-2xl labelForm p-2 font-semibold text-ntaGray mt-5 text-justify">
          Para usar el sistema, es necesario seleccionar la temporalidad y la
          unidad sobre la cual estará trabajando. Puede cambiar estos campos en
          cualquier momento si desea trabajar con otros diferentes.
        </p>
      </div>
      <NtaForm onSubmit={handleSubmit}>
        <div>
          <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
            <div>
              <div
                className="font-bold text-ntaBlue text-xl mx-auto text-left mt-10 subtituloForm"
                style={{ width: "90%" }}
              >
                SELECCIONE UNA UNIDAD
              </div>
              <NtaSelect
                arrOptions={[
                  { label: "Seleccione una unidad", value: "" },
                  ...parseCat(units, "name", "idUnit"),
                ]}
                required={true}
                name="idUnit"
                onChange={dataChange}
                value={data.idUnit}
                label="Unidad"
              />
            </div>
            <div>
              <div
                className="font-bold text-ntaBlue text-xl mx-auto text-left mt-10 subtituloForm"
                style={{ width: "90%" }}
              >
                SELECCIONE UNA TEMPORALIDAD
              </div>
              <NtaSelect
                arrOptions={[
                  { label: "Seleccione una temporalidad", value: "" },
                  ...parseCat(
                    units.find((u) => u.idUnit === data.idUnit)?.seasons || [],
                    "name",
                    "idSeason"
                  ),
                ]}
                disabled={data.idUnit === ""}
                required={true}
                name="idSeason"
                onChange={dataChange}
                value={data.idSeason}
                label="Temporalidad"
              />
            </div>
          </div>
          <div
            className="grid grid-cols-4 gap-4 w-4/5 mx-auto inputsForm"
            style={{ marginTop: "2rem" }}
          >
            <div />
            <div />
            <div />
            <Button disabled={isLoading} type="submit">
              Aceptar
            </Button>
          </div>
        </div>
      </NtaForm>
    </>
  );
};

Home.propTypes = {};

export default Home;
