import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getColumnsRelationIssuersBallots } from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { useForm } from "../../helpers/useForm";
import { parseCat } from "../../helpers/utils";
import NtaDataTable from "../../components/NtaDataTable";
import Menu from "../../components/Menu";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import Select from "../../components/Select";
import Input from "../../components/Input";
import "../../App";
import "../../App.css";
import InvoiceDetails from "../../components/modals/InvoiceDetails";
import AssingSATPurchase from "../../components/modals/AssingSATPurchase";
import ReassingIssuers from "../../components/modals/ReassingIssuers";
import Swal from "sweetalert2";

const RelationIssuersBallots = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters, resetFilters] = useForm({
    idUnit: "all",
    idEjido: "all",
    idCatBallotStatus: "all",
  });
  const catalogues = useSelector((s) => s.catalogues);
  const { ejidos = [], catBallotStatus = [] } = catalogues;
  const [productSelected, setProductSelected] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const navigate = useNavigate();
  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(
      `/supplier/reassignemitter?${filterStr}`,
      null,
      "GET"
    );
    const mappedData = res.data.map((d) => ({ ...d, checked: false }));
    setData(mappedData);
    setAllData(mappedData);
  }, []);
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields =
      lookupField === "all"
        ? [
            "folio",
            "tresherName",
            "ticketCollectorName",
            "comissionAgentName",
            "freighterName",
          ]
        : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      // Filtrar por productores
      d.producers.forEach((p) => {
        if (p.nameProducer?.toLowerCase().indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      // Filtrar por places
      const plates = d.vehicle?.plates.toLowerCase() || "";
      if (plates.indexOf(searchLower) !== -1) {
        found = true;
      }
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const dtFuncs = {
    handleSelection: (checked, ix = "all", idProduct = null) => {
      let isValid = true;
      if (ix === "all") {
        if (checked) {
          const productListSelected = data[0].idProduct;
          isValid =
            typeof data.find((d) => d.idProduct !== productListSelected) !==
            "object";
        }
        if (isValid) {
          setAllChecked(checked);
          setData(data.map((d) => ({ ...d, checked })));
        } else {
          Swal.fire(
            "¡Producto distinto!",
            "La lista contiene productos distintos, para seleccionar todos la lista debe contener solo un tipo de producto",
            "error"
          );
        }
      } else {
        if (checked) {
          if (productSelected === null) {
            setProductSelected(idProduct);
          } else {
            isValid = productSelected === idProduct;
          }
        } else {
          if (data.filter((d) => d.checked).length === 1) {
            setProductSelected(null);
          }
        }
        if (isValid) {
          const modData = [...data];
          modData[ix].checked = checked;
          setData(modData);
        } else {
          Swal.fire(
            "¡Producto distinto!",
            "Esta boleta contiene un producto distinto al seleccionado, por favor elige uno distinto",
            "error"
          );
        }
      }
    },
    details: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    reassing: (row) => {
      setSingleData(row);
      setModalOpen3(true);
    },
    capture: (row) => {
      navigate("../capturar-boleta/" + row.idBallot);
    },
    delete: (row) => {
      Swal.fire({
        title: "¿Deseas cancelar la boleta?",
        text: "Escribe el motivo de cancelación (ésta acción es irreversible)",
        icon: "warning",
        input: "text",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debes introducir un motivo de cancelación";
          }
        },
      }).then(async ({ isConfirmed, value: cancellationComment }) => {
        if (isConfirmed) {
          const res = await Request(
            `/ballot/${row.idBallot}`,
            { cancellationComment },
            "PATCH"
          );
          if (res.ok) {
            Swal.fire({
              title: "Boleta cancelada con éxito",
              icon: "success",
            }).then(() => {
              fetchData(filters);
            });
          }
        }
      });
    },
    edit: (row) => {
      navigate("../editar-boleta/" + row.idBallot);
    },
  };
  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);

  return (
    <>
      <Menu />
      <div
        className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList"
        style={{ width: "90%" }}
      >
        <div className="grid grid-cols-2">
          <PageTitle>RELACIÓN EMISORES Y BOLETAS</PageTitle>
          <div className="w-full grid grid-cols-3 gap-4 containerMoreBtns">
            <Button
              className="w-full mt-0 text-xl btnList"
              onClick={() => setModalOpen2(true)}
            >
              # Compra
            </Button>
            <Button
              className="w-full mt-0 text-xl btnList"
              onClick={() => setModalOpen2(true)}
            >
              Folio SAT
            </Button>
            <Button
              className="w-full mt-0 text-xl btnList"
              onClick={() => setModalOpen(true)}
            >
              Asignar Folio
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/generar-boleta");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por folio o persona"
          />
          <Select
            arrOptions={[
              { label: "Todos", value: "all" },
              ...parseCat(
                ejidos.filter((e) => e.isActive),
                "name",
                "idEjido"
              ),
            ]}
            required={true}
            name="idEjido"
            onChange={setFilters}
            value={filters.idEjido}
            label="Ejido"
          />
          <Select
            arrOptions={[
              { label: "Todos", value: "all" },
              ...parseCat(catBallotStatus, "name", "idCatBallotStatus"),
            ]}
            required={true}
            name="idCatBallotStatus"
            onChange={setFilters}
            value={filters.idCatBallotStatus}
            label="Estatus"
          />

          <Button
            style={{ placeSelf: "end", width: "6rem" }}
            onClick={() => {
              fetchData();
              resetFilters();
            }}
            className={"w-1/2 px-0 mt-14 mb-2 align-right text-center"}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable
          data={data}
          columnsDef={getColumnsRelationIssuersBallots(dtFuncs, { allChecked })}
        />
      </div>
      <InvoiceDetails
        isOpen={modalOpen}
        data={singleData}
        toggle={setModalOpen}
      />
      <AssingSATPurchase
        isOpen={modalOpen2}
        data={singleData}
        toggle={setModalOpen2}
      />
      <ReassingIssuers
        isOpen={modalOpen3}
        data={singleData}
        toggle={setModalOpen3}
      />
    </>
  );
};

export default RelationIssuersBallots;
