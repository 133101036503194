import {
  faAddressBook,
  faClipboardCheck,
  faClipboardList,
  faCommentMedical,
  faTruckField,
  faTruckMedical,
  faUserPlus,
  faUsers,
  faWheatAwn,
  faBuildingUser,
  faWarehouse,
  faBuildingWheat,
  faBuildingCircleXmark,
  faBoxOpen,
  faTractor,
  faWheatAlt,
  faBoxesStacked,
  faLocationDot,
  faTruckPickup,
  faTruck,
  faMoneyCheckDollar,
  faTruckArrowRight,
  faLocationPin,
  faTruckFast,
  faTruckFront,
  faCalendarPlus,
  faCalendarDays,
  faPowerOff,
  faDotCircle,
  faBox,
  faCirclePlus,
  faMoneyCheck,
  faReceipt,
  faDollarSign,
  faTableList,
  faFileInvoiceDollar,
  faIdCardClip,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import logo from "../css/img/logoCortoNTA.svg";
import "./Menu.css";
import ButtonIcon from "./ButtonIcon";
import { endSession } from "../helpers/storageHandler";
import { useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import Swal from "sweetalert2";
const menuSchema = {
  ABASTECIMIENTOS: false,
  INVENTARIOS: false,
  FINANZAS: false,
  "LOGÍSTICA Y TRANSPORTES": false,
  VENTAS: false,
  REPORTES: false,
};
const Menu = () => {
  const items = {
    ABASTECIMIENTOS: {
      sub: {
        "Lista de personas": {
          to: "/personas",
          icon: faUsers,
        },
        "Alta de personas": {
          to: "/nueva-persona",
          icon: faUserPlus,
        },
        "Lista de proveedores": {
          to: "/proveedores",
          icon: faTruckField,
        },
        "Alta de proveedores": {
          to: "/nuevo-proveedor",
          icon: faTruckMedical,
        },
        "Lista de clientes": {
          to: "/clientes",
          icon: faAddressBook,
        },
        "Alta de clientes": {
          to: "/nuevo-cliente",
          icon: faCommentMedical,
        },
        "Lista de boletas": {
          to: "/boletas",
          icon: faClipboardList,
        },
        "Generación de boletas": {
          to: "/generar-boleta",
          icon: faClipboardCheck,
        },
      },
    },
    INVENTARIOS: {
      sub: {
        "Lista de unidades": {
          to: "/unidades",
          icon: faBuildingUser,
        },
        "Alta de unidades": {
          to: "/nueva-unidad",
          icon: faWarehouse,
        },
        "Alta de productos": {
          to: "/nuevo-producto",
          icon: faWheatAwn,
        },
        "Lista de productos": {
          to: "/productos",
          icon: faBuildingWheat,
        },
        "Lista de almacenes inactivos": {
          to: "/almacenes-inactivos",
          icon: faBuildingCircleXmark,
        },
        "Recepción de producto": {
          to: "/recepcion-producto",
          icon: faBox,
        },
        "Lista de recepciones": {
          to: "/lista-recepciones",
          icon: faBoxOpen,
        },
        "Alta de ejidos": {
          to: "/nuevo-ejido",
          icon: faTractor,
        },
        "Lista de ejidos": {
          to: "/ejidos",
          icon: faWheatAlt,
        },
        Inventario: {
          to: "/inventario",
          icon: faBoxesStacked,
        },
      },
    },
    FINANZAS: {
      sub: {
        "Lista de Asignación de Cuotas": {
          to: "/lista-asignar-cuotas",
          icon: faClipboardList,
        },
        "Asignar Cuota a Personas": {
          to: "/asignar-cuota",
          icon: faIdCardClip,
        },
        "Relaciones Emisores con Boletas": {
          to: "/relacion-emisores-boletas",
          icon: faFileInvoiceDollar,
        },
        "Boletas Asignadas a Emisores con Pagos": {
          to: "/boletas-emisores-pagos",
          icon: faWarehouse,
        },
        "Generar Pago": {
          to: "/generar-pago",
          icon: faMoneyCheck,
        },
        "Lista de Pagos": {
          to: "/lista-pagos",
          icon: faReceipt,
        },
        "Lista de Facturas": {
          to: "/lista-facturas",
          icon: faTableList,
        },
        "Aplicación de Descuentos y Bonificaciones": {
          to: "/descuentos-bonificaciones",
          icon: faDollarSign,
        },
        "Depósitos a Emisores": {
          to: "/depositos-emisores",
          icon: faMoneyCheckDollar,
        },
      },
    },
    "LOGÍSTICA Y TRANSPORTES": {
      sub: {
        Destinos: {
          to: "/destinos",
          icon: faLocationDot,
        },
        "Alta de cuotas": {
          to: "/alta-cuotas",
          icon: faMoneyCheckDollar,
        },
        "Alta de embarques": {
          to: "/alta-embarques",
          icon: faTruckFront,
        },
        "Lista de embarques": {
          to: "/lista-embarques",
          icon: faTruckPickup,
        },
        "Alta de embarques": {
          to: "/alta-embarques",
          icon: faCirclePlus,
        },
        "Lista de fletes locales": {
          to: "/lista-fletes-locales",
          icon: faTruck,
        },
        "Alta de cuotas": {
          to: "/alta-cuotas",
          icon: faTruckArrowRight,
        },
        "Cálculo de cuotas de fletes": {
          to: "/calculo-cuotas",
          icon: faLocationPin,
        },
        "Lista de cálculos": {
          to: "/lista-cuotas",
          icon: faTruckFast,
        },
        "Nueva temporalidad": {
          to: "/temporalidad",
          icon: faCalendarPlus,
        },
        Temporalidades: {
          to: "/temporalidades",
          icon: faCalendarDays,
        },
      },
    },
    VENTAS: {
      sub: {
        "Captura de Pedidos": {
          to: "/capturar-pedidos",
          icon: faUsers,
        },
        "Lista de Pedidos Capturados": {
          to: "/lista-pedidos-capturados",
          icon: faBoxOpen,
        },
        "Lista de Ventas Capturadas": {
          to: "/lista-ventas",
          icon: faMoneyCheckDollar,
        },
      },
    },
    REPORTES: { sub: {} },
  };
  const [submenu, setSubsubmenu] = useState(menuSchema);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [displayMenu, setDisplayMenu] = useState(false);
  const navigate = useNavigate();
  const { idUnit, idSeason } = useSelector((s) => s.platform);
  const { units = [] } = useSelector((s) => s.catalogues);
  const showSubMenu = (item, clear = false) => {
    const initial = { ...menuSchema };
    if (!clear) {
      initial[item] = true;
    }
    setSubsubmenu(initial);
  };
  const [unit, setUnit] = useState({ seasons: [] });
  const [season, setSeason] = useState({});
  useEffect(() => {
    const foundUnit = units.find((u) => u.idUnit == idUnit) || { seasons: [] };
    setUnit(foundUnit);
    setSeason(foundUnit.seasons.find((s) => s.idSeason == idSeason) || {});
  }, [idUnit, idSeason]);
  return (
    <div
      className="grid gap-4 grid-cols-8
                    grid-rows-1 text-center font-bold text-ntaBlue
                    mx-auto align-middle rounded-b-3xl shadow-xl menuContainer"
    >
      <div>
        <img
          onClick={() => {
            navigate("/inicio");
          }}
          className="py-2 px-2 logoMenu cursor-pointer"
          width="80%"
          src={logo}
        />
      </div>
      <div
        className="btnDisplayMenu"
        style={{ display: "none" }}
        onClick={
          displayMenu ? () => setDisplayMenu(false) : () => setDisplayMenu(true)
        }
      >
        Menú
      </div>
      <div
        className="menuContainerResponsive"
        style={displayMenu ? { display: "block" } : { display: "none" }}
      >
        {Object.keys(items).map((k) => {
          return (
            <>
              <div
                className="self-center cursor-pointer text-base itemMenu2"
                style={{ margin: "1rem 0vw" }}
              >
                <span
                  onMouseEnter={() => {
                    showSubMenu(k);
                  }}
                  onClick={() => {
                    showSubMenu(k);
                  }}
                  className={"text-xl"}
                >
                  {k}
                </span>
                {submenu[k] && (
                  <div
                    className="nta-submenu"
                    onMouseLeave={() => {
                      showSubMenu(k, true);
                    }}
                  >
                    <ul>
                      {Object.keys(items[k].sub).map((s) => {
                        const { to, icon } = items[k].sub[s];
                        return (
                          <li
                            onClick={() => {
                              showSubMenu(k, true);
                              if (idUnit !== "" && idSeason !== "") {
                                navigate(to);
                              } else {
                                Swal.fire(
                                  "Espera",
                                  "Debes elegir Unidad y temporalidad para continuar",
                                  "warning"
                                );
                              }
                            }}
                          >
                            {" "}
                            <FontAwesomeIcon icon={icon} /> {s}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <hr></hr>
            </>
          );
        })}
      </div>
      {Object.keys(items).map((k) => {
        return (
          <div className="self-center cursor-pointer text-base itemMenu">
            <span
              style={{ fontSize: "1rem" }}
              onMouseEnter={() => {
                showSubMenu(k);
              }}
              onClick={() => {
                showSubMenu(k);
              }}
              className={"text-xl"}
            >
              {k}
            </span>
            {submenu[k] && (
              <div
                className="nta-submenu"
                onMouseLeave={() => {
                  showSubMenu(k, true);
                }}
              >
                <ul>
                  {Object.keys(items[k].sub).map((s) => {
                    const { to, icon } = items[k].sub[s];
                    return (
                      <li
                        onClick={() => {
                          showSubMenu(k, true);
                          if (idUnit !== "" && idSeason !== "") {
                            navigate(to);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={icon} /> {s}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        );
      })}
      <div
        className="grid grid-cols-2"
        style={{ gridTemplateColumns: "75% 25%" }}
      >
        <div className="self-center cursor-pointer text-base itemMenu">
          <span
            className="grid grid-rows-2 text-right"
            id="tootltipDates"
            style={{ fontSize: "0.8rem" }}
          >
            <span className="text-ntaGray">
              <b>U:</b> {unit.name || "-"}
            </span>
            <span className="text-ntaGray">
              {season.startDate && (
                <FontAwesomeIcon
                  size={5}
                  icon={faDotCircle}
                  color={season.isActive ? "green" : "red"}
                />
              )}
              <b style={{ marginLeft: "5px" }}>T:</b> {season.name}
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target="tootltipDates"
                toggle={() => {
                  setTooltipOpen(!tooltipOpen);
                }}
              >
                {`${season.startDate || ""} / ${season.endDate || ""}`}
              </Tooltip>
            </span>
          </span>
        </div>
        <ButtonIcon
          icon={faPowerOff}
          onClick={() => {
            endSession();
          }}
        />
      </div>
    </div>
  );
};

export default Menu;
