import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getBallotStatusColor, parseObjectDate } from "../../helpers/utils";
import Button from "../Button";
import ButtonIcon from "../ButtonIcon";
import noop from "../../helpers/noop";
import PageTitle from "../PageTitle";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ProducerBallotCard from "../ProducerBallotCard";

const BallotDetails = ({
  isOpen,
  data,
  toggle,
  onConfirm = noop,
  onCancel = noop,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>
        <PageTitle>DETALLE DE BOLETA</PageTitle>
        <ButtonIcon
          onClick={() => {
            window.print();
          }}
          icon={faPrint}
        />
        <span
          className="float-right px-8 py-2 text-xl rounded-lg text-black "
          style={{
            backgroundColor: getBallotStatusColor(data.idCatBallotStatus),
          }}
        >
          {data.status}
        </span>
      </ModalHeader>
      <ModalBody>
        <table className="ntaTableDataSet">
          <tbody>
            <tr>
              <td colSpan={2}>
                <strong>Folio: </strong>
                <span>{data.folio}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Fecha de Generación: </strong>
                <span>{parseObjectDate(new Date(data.createdAt), 3)}</span>
              </td>
              <td>
                <strong> Fecha de Captura: </strong>
                <span>
                  {data.captureDate
                    ? parseObjectDate(new Date(data.captureDate), 3)
                    : " -- / -- / -- "}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Fecha de Descarga: </strong>
                <span>{parseObjectDate(new Date(data.receptionDate), 3)}</span>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <strong>Producto: </strong>
                <span>{data.product?.name}</span>
              </td>
              <td>
                <strong>Ejido: </strong>
                <span>{data.ejido}</span>
              </td>
            </tr>
            {data.idCatBallotStatus === 3 && (
              <>
                <tr>
                  <td>
                    <strong>Boletero: </strong>
                    <span>{data.ticketCollectorName}</span>
                  </td>
                  <td>
                    <strong>Trillador: </strong>
                    <span>{data.thresherName}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Representante: </strong>
                    <span>{data.comissionAgentName}</span>
                  </td>
                  <td></td>
                </tr>
              </>
            )}
            <tr>
              <td>
                <strong>Placas: </strong>
                <span>{data.vehicle?.plates}</span>
              </td>
              <td>
                <strong>Chofer: </strong>
                <span>{data.freighterName || " - "}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Tipo de vehículo: </strong>
                <span>
                  {data.vehicle?.cat_vehicle?.name +
                    data.vehicle?.cat_vehicle?.description || " - "}
                </span>
              </td>
              <td>
                <strong>Número de ticket: </strong>
                <span>{data.ticket}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Peso Tara: </strong>
                <span>{data.tara} Kg</span>
              </td>
              <td>
                {data.idCatBallotStatus === 3 && (
                  <>
                    <strong>Humedad: </strong>
                    <span>{data.recivedHumidity || " - "} % </span>
                  </>
                )}
              </td>
            </tr>
            {data.idCatBallotStatus === 3 && (
              <>
                <tr>
                  <td>
                    <strong>Peso bruto: </strong>
                    <span>{data.gross} Kg</span>
                  </td>
                  {data.freightDifference && (
                    <td>
                      <strong>Diferencia de flete: </strong>
                      <span>{data.valueFreightDifference || " - "} T</span>
                    </td>
                  )}
                </tr>
                <tr>
                  <td>
                    <strong>Peso Neto: </strong>
                    <span>{data.net} Kg</span>
                  </td>
                </tr>
              </>
            )}
            {data.idCatBallotStatus === 5 && (
              <tr>
                <td>
                  <strong>Comentarios: </strong>
                  <span>{data.cancellationComment}</span>
                </td>
                <td></td>
              </tr>
            )}
            {data.producers?.length > 0 && (
              <tr>
                <td colSpan={2}>
                  <strong>Lista de Productores</strong>
                  {data.producers.map((p) => (
                    <ProducerBallotCard
                      producer={p}
                      recivedHumidity={data.recivedHumidity}
                    />
                  ))}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={2}>
                <strong>Comentarios: </strong>
                <span>{data.comments || " - "}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 w-full btnModal">
          <div />
          <div />
          <Button
            onClick={() => {
              toggle(false);
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

BallotDetails.propTypes = {};

export default BallotDetails;
