import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import noop from "../../helpers/noop";
import PropTypes from "prop-types";
import Button from "../Button";
import PageTitle from "../PageTitle";
import { parseObjectDate } from "../../helpers/utils";
import ButtonIcon from "../ButtonIcon";
import { faBan, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ModalSeasonConfirmed = ({
  isOpen,
  behavior,
  data,
  exportFields = {},
  toggle,
  onConfirm = noop,
}) => {
  const navigate = useNavigate();
  const { units } = useSelector((s) => s.catalogues);
  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalBody>
        <div className="text-center">
          <PageTitle>RESUMEN DE NUEVA TEMPORADA</PageTitle>
          <h3 className="text-2xl text-center mt-4 mb-5">
            Se ha generado correctamente la boleta con los siguientes datos:
          </h3>
        </div>
        <table className="ntaTableDataSet">
          <tbody>
            <tr>
              <td colSpan={2}>
                <strong className="text-2xl ">UNIDAD: </strong>
                <span>{units.find((u) => u.idUnit === data.idUnit)?.name}</span>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong className="text-xl ">NUEVA TEMPORADA: </strong>
                <span>
                  {parseObjectDate(new Date(data.startDate), 3)} -{" "}
                  {parseObjectDate(new Date(data.endDate), 3)}
                </span>
              </td>
              <td></td>
            </tr>
            <tr>
              <td style={{ width: "50%", verticalAlign: "top" }}>
                <p className="text-center">
                  Las siguientes opciones se conservaron en la nueva temporada.
                </p>
                {Object.keys(exportFields).map(
                  (k) =>
                    data[k] && (
                      <div className=" text-xl ">
                        <ButtonIcon icon={faCheckCircle} />
                        <span>{exportFields[k].text}</span>
                      </div>
                    )
                )}
              </td>
              <td style={{ width: "50%", verticalAlign: "top" }}>
                <p className="text-center">
                  Las siguientes opciones se eliminaron en la nueva temporada.
                </p>
                {Object.keys(exportFields).map(
                  (k) =>
                    !data[k] && (
                      <div className=" text-xl ">
                        <ButtonIcon icon={faBan} />
                        <span>{exportFields[k].text}</span>
                      </div>
                    )
                )}
              </td>
              {/*<td style={{ width: "33%" }}>
                <p className="text-center">
                  Las siguientes opciones se regenerarán en la nueva temporada.
                </p>
                    </td>*/}
            </tr>
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 gap-5 btnModal">
          <div />
          <div />
          <Button
            onClick={() => {
              toggle();
              navigate("/temporalidades");
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ModalSeasonConfirmed.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ModalSeasonConfirmed;
