import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "../../helpers/useForm";
import { behaviors, assignQuotaPersonSchema } from "../../helpers/schemas";
import { parseCat } from "../../helpers/utils";
import NtaForm from "../../components/NtaForm";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Menu from "../../components/Menu";
import PageTitle from "../../components/PageTitle";
import "../../App";
import "../../App.css";
import { useSelector } from "react-redux";
import { initialFetch } from "../../redux/actions/auth";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../../helpers/api";
import { Checkbox, FormControlLabel } from "@mui/material";

const AssignQuotasInsert = () => {
  const navigate = useNavigate();
  const { id: idSupplierQuota } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [behavior, setBehavior] = useState(behaviors.insert);
  const {
    catSuppliers = [],
    ejidos = [],
    products = [],
  } = useSelector((s) => s.catalogues);
  const [data, onChangeData, clearData, setAllData] = useForm(
    assignQuotaPersonSchema
  );
  const [persons, setPersons] = useState([]);

  const fetchData = useCallback(async () => {
    const resPersons = await Request(`/supplier?identityKey=INE`, null, "GET");
    if (resPersons.ok) {
      setPersons(resPersons.data);
    }
  }, []);

  const fetchQuota = useCallback(async () => {
    const { ok, data: resData } = await Request(
      `/supplier/detailsupplierquota/` + idSupplierQuota,
      null,
      "GET"
    );
    const suppQuota = resData[0];
    if (ok) {
      setAllData({ ...data, ...suppQuota, applyRetroactive: suppQuota == 1 });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "¿Deseas guardar la cuota?",
      text: idSupplierQuota
        ? "Actualizarás ésta cuota"
        : "Crearás una nueva cuota para ésta persona",
      icon: "info",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        setIsLoading(true);
        const res = await Request(
          `/supplier/supplierquota${
            idSupplierQuota ? "/" + idSupplierQuota : ""
          }`,
          data,
          idSupplierQuota ? "PUT" : "POST"
        );
        setIsLoading(false);
        if (res.ok) {
          Swal.fire({ title: "Registro exitoso", icon: "success" }).then(() => {
            navigate("/lista-asignar-cuotas");
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
    if (idSupplierQuota) {
      setBehavior(behaviors.edit);
      fetchQuota();
    }
  }, []);

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>
          {behavior === behaviors.insert
            ? "ASIGNAR CUOTA A PERSONAS"
            : "EDITAR CUOTA A PERSONAS"}
        </PageTitle>
      </div>
      <NtaForm onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Select
              required={true}
              name="idCatSupplier"
              value={data.idCatSupplier}
              disabled={idSupplierQuota}
              onChange={onChangeData}
              label="Rol"
              arrOptions={[
                { value: "", label: "Elige un elemento" },
                ...parseCat(
                  catSuppliers.filter((cs) =>
                    [2, 6, 3].includes(cs.idCatSupplier)
                  ),
                  "name",
                  "idCatSupplier"
                ),
              ]}
            />
          </div>
          <div>
            <Select
              required={data.idCatSupplier === 3}
              name="idEjido"
              value={data.idEjido}
              onChange={onChangeData}
              disabled={idSupplierQuota}
              arrOptions={[
                { value: "", label: "Elige un elemento" },
                ...parseCat(ejidos, "name", "idEjido"),
              ]}
              label="Ejido"
            />
            {/*Requerido solo para Rol de Trillador*/}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Select
              required={data.idCatSupplier === 3}
              name="idOriginField"
              value={data.idOriginField}
              disabled={idSupplierQuota}
              onChange={onChangeData}
              arrOptions={[
                { value: "", label: "Elige un elemento" },
                ...parseCat(
                  ejidos.find((e) => e.idEjido === data.idEjido)?.origin_fields,
                  "name",
                  "idOriginField"
                ),
              ]}
              label="Campo"
            />
            {/*Requerido solo para Rol de Trillador*/}
          </div>
          <div>
            <Select
              required={false}
              name="idSupplier"
              value={data.idSupplier}
              disabled={idSupplierQuota}
              onChange={onChangeData}
              arrOptions={[
                { value: "", label: "Elige un elemento" },
                ...parseCat(
                  persons.filter((p) =>
                    p.cat_suppliers.find(
                      (r) => r.idCatSupplier === data.idCatSupplier
                    )
                  ),
                  "name",
                  "idSupplier"
                ),
              ]}
              label="Persona"
            />
            {/*Lista de Personas opcional del Rol seleccionado*/}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          {
            data.idCatSupplier === 3 && (
              <Select
                required={true}
                name="idProduct"
                value={data.idProduct}
                disabled={idSupplierQuota}
                onChange={onChangeData}
                arrOptions={[
                  { value: "", label: "Elige un elemento" },
                  ...parseCat(products, "name", "idProduct"),
                ]}
                label="Producto"
              />
            )
            /*  Solo mostrar cuando el rol sea Trillador - Requerido*/
          }
          {
            data.idCatSupplier === 2 && (
              <Select
                required={data.idCatSupplier === 2}
                name="applyBy"
                value={data.applyBy}
                onChange={onChangeData}
                arrOptions={[
                  { value: "", label: "Elige un elemento" },
                  { value: "DAY", label: "Dia" },
                  { value: "BALLOT", label: "Boleta" },
                ]}
                label="Aplica por"
              />
            )
            /*  Solo mostrar cuando el rol sea Boletero - Requerido - Opciones: Día y Boleta*/
          }
          {data.idCatSupplier === 2 && data.applyBy === "BALLOT" ? (
            <>
              <Input
                type="number"
                step={0.01}
                min={0.01}
                required={true}
                value={data.oneTwoBallotQuota}
                name="oneTwoBallotQuota"
                onChange={onChangeData}
                label="Cuota para 1 o 2 boletas"
                placeholder="Cuota"
              />
              <Input
                type="number"
                step={0.01}
                min={0.01}
                required={true}
                value={data.threeBallotQuota}
                name="threeBallotQuota"
                onChange={onChangeData}
                label="Cuota para 3 boletas"
                placeholder="Cuota"
              />
              <Input
                type="number"
                step={0.01}
                min={0.01}
                required={true}
                value={data.fourBallotQuota}
                name="fourBallotQuota"
                onChange={onChangeData}
                label="Cuota para 4 o más boletas"
                placeholder="Cuota"
              />
            </>
          ) : (
            <Input
              type="number"
              step={0.01}
              min={0.01}
              required={true}
              value={data.quota}
              name="quota"
              onChange={onChangeData}
              label="Cuota"
              placeholder="Cuota"
            />
          )}
        </div>
        <div
          className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm"
          style={{ marginTop: "3rem" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={data.applyRetroactive}
                onChange={(e) =>
                  onChangeData({
                    target: {
                      name: "applyRetroactive",
                      value: e.target.checked,
                    },
                  })
                }
              />
            }
            label={"Aplicar Retroactivo"}
          />
          <div />
        </div>
        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4 inputsForm">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            {behavior === behaviors.insert ? "Asignar" : "Actualizar"}
          </Button>
        </div>
      </NtaForm>
    </>
  );
};

export default AssignQuotasInsert;
