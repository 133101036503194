import React from "react";

const Button = ({ children, label, className, onClick, type = "submit", disabled = false, style = {} }) => {
  return (
    <button
      style={style}
      className={`w-full text-2xl appearance-none px-3 my-6 py-3 border-transparent text-white bg-ntaBlue rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-slate-800 font-bold display-initial ${className}`}
      {...{ onClick, type, disabled }}
    >
      {label} {children}
    </button>
  );
};

/* 
    props:
        *label: Texto del button
    *Obligatorios
*/

export default Button;
