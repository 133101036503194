import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import noop from "../../helpers/noop";
import PropTypes from "prop-types";
import Button from "../Button";
import PageTitle from "../PageTitle";
import { parseObjectDate } from "../../helpers/utils";

const ModalBallotConfirmed = ({
  isOpen,
  behavior,
  data,
  toggle,
  onConfirm = noop,
  onCancel = noop,
}) => {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <div className="text-center">
          <PageTitle>
            {" "}
            {behavior === "POST" ? "¡REGISTRO EXITOSO!" : "¡ACTUALIZACIÓN EXITOSA!"}
          </PageTitle>
          <h3 className="text-2xl text-center mt-4 mb-5">
            Se ha generado correctamente la boleta con los siguientes datos:
          </h3>
        </div>
        <table className="ntaTableDataSet">
          <tbody>
            <tr>
              <td>
                <strong>Folio: </strong>
                <span>{data.folio}</span>
              </td>
              <td>
                <strong>Fecha: </strong>
                <span>{parseObjectDate(new Date(data.createdAt), 3)}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Producto: </strong>
                <span>{data.product?.name}</span>
              </td>
              <td>
                <strong>Ejido: </strong>
                <span>{data.ejido}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Peso de la tara: </strong>
                <span>{data.tara} Kg</span>
              </td>
              <td>
                <strong>Chofer: </strong>
                <span>{data.freighter?.name}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Placas: </strong>
                <span>{data.vehicle?.plates}</span>
              </td>
              <td>
                <strong>Número de ticket: </strong>
                <span>{data.ticket}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Tipo de vehículo: </strong>
                <span>
                  {data.vehicle?.cat_vehicle?.name + data.vehicle?.cat_vehicle?.description}
                </span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-2 gap-5 btnModal">
          <Button onClick={onCancel} className={"bg-ntaGray"}>
            Regresar
          </Button>
          <Button onClick={onConfirm}>Aceptar</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ModalBallotConfirmed.propTypes = {};

export default ModalBallotConfirmed;
