import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { parseCat } from "../helpers/utils";
import { useForm } from "../helpers/useForm";
import { getColumnsSuppliers } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Select from "../components/Select";
import Input from "../components/Input";
import SupplierDetails from "../components/modals/SupplierDetails";
import "../App";
import "../App.css";

const SupplierList = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const catalogues = useSelector((s) => s.catalogues);
  const { ejidos, units } = catalogues;
  const [filters, setFilters, resetFilters] = useForm({
    idCatSupplier: "all",
    idEjido: "all",
    idUnit: "all",
  });
  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/supplier?identityKey=RFC&${filterStr}`, null, "GET");
    setAllData(res.data);
    setData(res.data);
  }, []);
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields = ["address", "name", "ejido", "unit", "identityKey"];
    if (lookupField === "all") {
      allData.forEach((d) => {
        let found = false;
        searchFields.forEach((k) => {
          const val = d[k] ? d[k].toLowerCase() : "";
          if (val.indexOf(searchLower) !== -1) {
            found = true;
            return;
          }
        });
        if (found) {
          filteredData.push(d);
        }
      });
    } else {
      allData.forEach((d) => {
        const val = d[lookupField].toLowerCase();
        if (val.indexOf(searchLower) !== -1) {
          filteredData.push(d);
        }
      });
    }
    setData(filteredData);
  };
  const dtFuncs = {
    details: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    edit: (row) => {
      navigate("/editar-proveedor/" + row.idSupplier);
    },
    delete: (row) => {
      const { isActive, idSupplier } = row;
      Swal.fire({
        title: `¿Deseas ${isActive ? "inactivar" : "reactivar"} a éste Proveedor?`,
        icon: "question",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request(
            "/supplier/" + idSupplier,
            {
              isActive: !isActive,
            },
            "PATCH"
          );
          if (res.ok) {
            await Swal.fire({ title: "Modificación exitosa", icon: "success" });
            fetchData(filters);
          }
        }
      });
    },
  };
  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);
  return (
    <>
      <Menu />
      <div className="grid grid-cols-1 gap-4 mx-auto mt-8 containerList" style={{ width: "90%" }}>
        <div className="grid grid-cols-2 containerTituloBtn">
          <PageTitle>LISTA DE PROVEEDORES</PageTitle>
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/nuevo-proveedor");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/nuevo-proveedor");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-5 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por Razón social, RFC, unidad, ejido y dirección"
          />
          {/*<Select
            arrOptions={[{ label: "Todos", value: "all" }, ...parseCat(units, "name", "idUnit")]}
            required={true}
            name="idUnit"
            onChange={setFilters}
            value={filters.idUnit}
            label="Unidad"
          />*/}
          <Select
            arrOptions={[{ label: "Todos", value: "all" }, ...parseCat(ejidos, "name", "idEjido")]}
            required={true}
            name="idEjido"
            onChange={setFilters}
            value={filters.idEjido}
            label="Ejido"
          />
          <Select
            arrOptions={[
              { label: "Todos", value: "all" },
              { label: "Emisor", value: "EMITTER" },
              { label: "No emisor", value: "NONEMITTER" },
            ]}
            required={true}
            name="roleSupplier"
            onChange={setFilters}
            value={filters.roleSupplier}
            label="Tipo Proveedor"
          />
          <div />
          <Button
            style={{ placeSelf: "end", width: "6rem" }}
            onClick={() => {
              fetchData();
              resetFilters();
              setSearch("");
            }}
            className={"w-1/2 px-0 mt-14 mb-2 align-right text-center"}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsSuppliers(dtFuncs)} />
      </div>
      <SupplierDetails isOpen={modalOpen} data={singleData} toggle={setModalOpen} />
    </>
  );
};

export default SupplierList;
