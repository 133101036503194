import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import { behaviors, productSchema, unitSchema } from "../helpers/schemas";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Button from "../components/Button";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import "../App";
import "../App.css";
import { useDispatch } from "react-redux";
import { initialFetch } from "../redux/actions/auth";
import { getFromSession } from "../helpers/storageHandler";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../helpers/api";

const ProductInsert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: idProduct } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [behavior, setBehavior] = useState(behaviors.insert);
  const [newProduct, newProductChange, newProductReset, setAllProduct] = useForm(productSchema);
  const insertProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await Request(`/product${behavior === behaviors.edit ? "/" + idProduct : ""}`, newProduct, behavior === behaviors.insert ? "POST" : "PUT");
    setIsLoading(false);
    if (res.ok) {
      await dispatch(initialFetch(getFromSession("jwt")));
      Swal.fire({
        title: behavior === behaviors.insert ? "Registro exitoso" : "Actualización exitosa",
        icon: "success",
      }).then(() => {
        behavior === behaviors.insert ? newProductReset() : navigate("/productos");
      });
    }
  };
  const fetchData = useCallback(async () => {
    const res = await Request(`/product/${idProduct}`, undefined, "GET");
    if (res.ok) {
      setAllProduct(res.data);
    }
  });
  useEffect(() => {
    if (idProduct) {
      setBehavior(behaviors.edit);
      fetchData();
    }
  }, []);
  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-14 text-center">
        <PageTitle>{behavior === behaviors.edit ? "EDITAR PRODUCTO" : "ALTA DE PRODUCTO"}</PageTitle>
      </div>
      <NtaForm onSubmit={insertProduct}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto mt-8 inputsForm">
          <div>
            <Input type="text" required={true} label="Nombre del producto" placeholder="Nombre del producto" name="name" maxLength={100} onChange={newProductChange} value={newProduct.name} />
            <div />
            <div />
          </div>
          <div>
            <Input
              type="text"
              required={true}
              label="Clave del producto"
              placeholder="Clave del producto"
              name="code"
              minLength={1}
              maxLength={4}
              onChange={newProductChange}
              value={newProduct.code}
            />
          </div>
          <div />
          <div />
        </div>
        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4 inputsForm">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            {behavior === behaviors.edit ? "Guardar cambios" : "Dar de alta"}
          </Button>
        </div>
      </NtaForm>
    </>
  );
};

export default ProductInsert;
