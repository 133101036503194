import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import { productWarehouseSchema, unitSchema } from "../helpers/schemas";

import { behaviors } from "../helpers/schemas";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Button from "../components/Button";
import ButtonIcon from "../components/ButtonIcon";
import { faPlus, faTrash, faEdit, faMinus } from "@fortawesome/free-solid-svg-icons";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import "../App";
import "../App.css";
import ProductInsertParameters from "../components/modals/ProductInsertParameters";
import { useDispatch, useSelector } from "react-redux";
import { initialFetch } from "../redux/actions/auth";
import { getFromSession } from "../helpers/storageHandler";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../helpers/api";
import { parseObjectDate } from "../helpers/utils";

const UnitInsert = () => {
  const { id: idUnit } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newUnit, newUnitChange, newUnitReset, setAllValues] = useForm({
    ...unitSchema,
  });
  const [singleWarehouse, setSingleWarehouse] = useState({});
  const [behavior, setBehavior] = useState(behaviors.insert);
  const { products: productsCat } = useSelector((s) => s.catalogues);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const warehouseManage = (willDetele = false, ix = null) => {
    const { warehouses } = newUnit;
    if (willDetele) {
      warehouses.splice(ix, 1);
    } else {
      warehouses.push({ products: [], name: "" });
    }
    newUnitChange({
      target: { name: "warehouses", value: warehouses },
    });
  };

  const setWarehouseProducts = (wIx, products) => {
    console.log(wIx, products);
    const { warehouses } = newUnit;
    warehouses[wIx].products = products;
    newUnitChange({
      target: { name: "warehouses", value: warehouses },
    });
    setModalOpen(false);
  };

  const setSingleWarehouseProducts = (willDetele = false, ix = null) => {
    const { products } = singleWarehouse;
    if (willDetele) {
      products.splice(ix, 1);
    } else {
      products.push({ ...productWarehouseSchema });
    }
    setSingleWarehouse({ ...singleWarehouse, products });
  };

  const onWarehouseChange = (e, ix) => {
    const { warehouses } = newUnit;
    const warehouse = { ...warehouses[ix] };
    warehouse[e.target.name] = e.target.value;
    warehouses[ix] = warehouse;
    newUnitChange({
      target: { name: "warehouses", value: warehouses },
    });
  };

  const manageSingleWarehouse = (willDetele = false, ix = null) => {
    const { products } = singleWarehouse;
    if (willDetele) {
      products.splice(ix, 1);
    } else {
      products.push({ ...productWarehouseSchema });
    }
    setSingleWarehouse({ ...singleWarehouse, products });
  };

  const onWarehouseProductChange = (e, ix) => {
    const { products } = singleWarehouse;
    if (e.target.name === "idProduct") {
      products[ix]["product"] = productsCat.find((p) => p.idProduct == e.target.value);
    }
    products[ix][e.target.name] = e.target.value;
    setSingleWarehouse({ ...singleWarehouse, products });
  };

  const disableUnit = () => {
    Swal.fire({
      title: "¿Deseas inactivar el almacén?",
      text:
        "Si inactiva el almácen toda su información se inactivará tambien " +
        "afectará sus productos en inventario. " +
        "Podrá consultar los almacenes inactivos y reactivarlos después. ¿Desea continuar?",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#737277",
      confirmButtonColor: "#071C49",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          text: "El almacén ha sido inactivado.",
          confirmButtonColor: "#071C49",
          confirmButtonText: "Aceptar",
        });
      }
    });
  };

  const deleteWarehouse = (ix, w) => {
    if (w.idWarehouse) {
      Swal.fire({
        title: "¿Deseas inactivar el almacén?",
        text: "Si inactivas el almacén no será posible ingresarle más inventario ni generarle nuevas boletas, ¿desea continuar?",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#737277",
        confirmButtonColor: "#071C49",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request("/warehouse/" + w.idWarehouse, { isActive: false }, "PATCH");
          if (res.ok) {
            Swal.fire({
              icon: "success",
              text: "El almacén ha sido inactivado",
              confirmButtonColor: "#071C49",
              confirmButtonText: "Aceptar",
            }).then(() => {
              navigate("/unidades");
            });
          }
        }
      });
    } else {
      warehouseManage(true, ix);
    }
  };

  const insertUnit = async (e) => {
    e.preventDefault();
    const missingWarehouses = newUnit.warehouses.filter((w) => w.products.length === 0);
    if (missingWarehouses.length === 0) {
      setIsLoading(true);
      const res = await Request(
        `/unit${behavior === behaviors.edit ? "/" + idUnit : ""}`,
        {
          ...newUnit,
          startDateSeason: parseObjectDate(new Date(newUnit.startDateSeason), 1),
          endDateSeason: parseObjectDate(new Date(newUnit.endDateSeason), 1),
        },
        behavior === behaviors.insert ? "POST" : "PUT"
      );
      setIsLoading(false);
      if (res.ok) {
        await dispatch(initialFetch(getFromSession("jwt")));
        Swal.fire({
          title: behavior === behaviors.insert ? "Registro exitoso" : "Actualización exitosa",
          icon: "success",
        }).then(() => {
          newUnitReset();
          navigate("/unidades");
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "¡Ops!",
        text: "Tienes aún " + missingWarehouses.length + " almacenes sin productos",
      });
    }
  };

  const fetchData = useCallback(async () => {
    const res = await Request(`/unit/${idUnit}`, undefined, "GET");
    if (res.ok) {
      setAllValues({ name: res.data.name, warehouses: res.data.warehouses });
    }
  });

  useEffect(() => {
    if (idUnit) {
      setBehavior(behaviors.edit);
      fetchData();
    }
  }, []);

  const mapProducts = (w) => {
    return w.products.map((p) => p.product?.name || "").join(", ");
  };

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>{behavior === behaviors.insert ? "ALTA DE UNIDAD" : "EDITAR UNIDAD"}</PageTitle>
      </div>
      <NtaForm onSubmit={insertUnit}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <Input type="text" required={true} label="Nombre de la unidad" placeholder="Nombre de la unidad" name="name" maxLength={100} onChange={newUnitChange} value={newUnit.name} />
          <div className="grid grid-cols-2 gap-4 inputsForm">
            <Input type="date" required={true} label="Fecha inicio Temp" name="startDateSeason" onChange={newUnitChange} value={newUnit.startDateSeason} />
            <Input type="date" required={true} label="Fecha fin Temp" name="endDateSeason" onChange={newUnitChange} value={newUnit.endDateSeason} />
          </div>
          <div>
            <div className="grid grid-cols-2 gap-2 w-full inputAndButton h-14 mt-20">
              <span className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">Almacén</span>
              <span className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">Código de Almacén</span>
              <ButtonIcon
                className={"text-white bg-ntaBlue hover:bg-ntaGray w-14 h-14 relative float-left place-self-start place-content-center grid"}
                icon={faPlus}
                onClick={() => {
                  warehouseManage();
                }}
              />
            </div>
          </div>
        </div>
        {newUnit.warehouses
          .filter((w) => w.isActive || !w.idWarehouse)
          .map((w, ix) => (
            <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
              <div className="grid grid-cols-3 gap-2 w-full inputAndButton">
                <Input
                  type="text"
                  required={true}
                  placeholder="Morelos"
                  name="name"
                  maxLength={100}
                  className={"grid w-full"}
                  onChange={(e) => {
                    onWarehouseChange(e, ix);
                  }}
                  value={w.name}
                />
                <Input
                  type="text"
                  required={true}
                  placeholder="M"
                  name="code"
                  minLength={1}
                  maxLength={4}
                  className={"grid w-full"}
                  onChange={(e) => {
                    onWarehouseChange(e, ix);
                  }}
                  value={w.code}
                />
                <ButtonIcon
                  className={"text-ntaBlue w-14 h-14 relative float-left place-self-start place-content-center grid buttonTrash"}
                  icon={faMinus}
                  onClick={() => {
                    deleteWarehouse(ix, w);
                  }}
                />
              </div>
              <div>
                {w.products?.length === 0 ? (
                  <div className="grid grid-cols-2 gap-2 inputAndButton mt-2">
                    <a
                      className="addProductsLink pt-8 pb-6"
                      onClick={() => {
                        setSingleWarehouse(JSON.parse(JSON.stringify({ ...w, ix })));
                        setModalOpen(true);
                      }}
                    >
                      <span className="text-ntaBlue text-lg pr-2 -mt-4">Añadir productos al almacén</span>
                    </a>
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-2 inputAndButton w-3/4">
                    <span className="grid pt-2 pb-2 mt-7">
                      <span className="text-lime-500 text-lg pr-4 pl-1 font-bold">{mapProducts(w)}</span>
                    </span>
                    <ButtonIcon
                      className={"text-ntaBlue w-8 h-8 relative float-left place-self-start place-content-center mt-8 grid"}
                      onClick={() => {
                        setSingleWarehouse(JSON.parse(JSON.stringify({ ...w, ix })));
                        setModalOpen(true);
                      }}
                      icon={faEdit}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4 inputsForm">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            {behavior === behaviors.insert ? "Dar de alta" : "Actualizar"}
          </Button>
        </div>
      </NtaForm>
      <ProductInsertParameters
        isOpen={modalOpen}
        data={singleWarehouse}
        toggle={setModalOpen}
        manageSingleWarehouse={manageSingleWarehouse}
        onWarehouseProductChange={onWarehouseProductChange}
        onConfirm={setWarehouseProducts}
        manageProduct={setSingleWarehouseProducts}
        onCancel={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default UnitInsert;
