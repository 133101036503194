import reduxThunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { authReducer } from "./reducers/authReducer";
import { cataloguesReducer } from "./reducers/cataloguesReducer";
import platformReducer from "./reducers/platformReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  auth: authReducer,
  catalogues: cataloguesReducer,
  platform: platformReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);
