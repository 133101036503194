import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getBallotStatusColor, parseObjectDate } from "../../helpers/utils";
import Button from "../Button";
import ButtonIcon from "../ButtonIcon";
import noop from "../../helpers/noop";
import PageTitle from "../PageTitle";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ProducerBallotCard from "../ProducerBallotCard";
import { getColumnsAssignSATPurchase } from "../../helpers/datatableHelpers";
import Swal from "sweetalert2";
import NtaDataTable from "../NtaDataTable";
import NtaForm from "../NtaForm";
import Input from "../Input";

const AssingSATPurchase = ({ isOpen, data, toggle, onConfirm = noop, onCancel = noop }) => {
  const dtFuncs = {
    // delete: (row) => {
    //   Swal.fire({
    //     title: "¿Deseas cancelar la boleta?",
    //     text: "Escribe el motivo de cancelación (ésta acción es irreversible)",
    //     icon: "warning",
    //     input: "text",
    //     showCancelButton: true,
    //     inputValidator: (value) => {
    //       if (!value) {
    //         return "Debes introducir un motivo de cancelación";
    //       }
    //     },
    //   }).then(async ({ isConfirmed, value: cancellationComment }) => {
    //     if (isConfirmed) {
    //       const res = await Request(`/ballot/${row.idBallot}`, { cancellationComment }, "PATCH");
    //       if (res.ok) {
    //         Swal.fire({
    //           title: "Boleta cancelada con éxito",
    //           icon: "success",
    //         }).then(() => {
    //           fetchData(filters);
    //         });
    //       }
    //     }
    //   });
    // },
  };
  const data2 = [
    {
      emisor: "Ingrid Braun M.",
      numBoleta: "PT0221548",
      productor: "Jean Padilla V.",
      producto: "Sorgo",
      kilos: "8,588.00",
      montoTotal: "$21,183.00",
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div className="text-ntaBlue text-3xl">
            <span className="font-bold">EMISOR:</span> Ingrid Braun M.
          </div>
          <div style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
            <div className="text-ntaBlue text-2xl">
              <span className="font-bold">FOLIO INTERNO DE FACTURA:</span> RILL22002
            </div>
            <div className="text-ntaBlue text-2xl">
              <span className="font-bold">FOLIO SAT:</span> AAA16ED9-9703-4FC9-B3D6-ED17DD8BA4D9 {/*Solo mostrar dato cuando es número de compra*/}
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "2rem" }}>
        <PageTitle>ASIGNAR FOLIO SAT</PageTitle> {/*Cambia dependiendo si será para asignar folio Sat o número de compra*/}
        <NtaDataTable data={data2} columnsDef={getColumnsAssignSATPurchase(dtFuncs)} />
        <NtaForm>
          <div className="grid grid-cols-2 gap-4 inputsForm">
            <Input type="text" required={true} label="Folio SAT" placeholder="Folio SAT" name="folioSAT" /> {/*Cambia dependiendo si será para asignar folio Sat o número de compra*/}
          </div>
        </NtaForm>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 w-full btnModal">
          <Button
            style={{ width: "10rem" }}
            className={"bg-ntaGray text-lg"}
            onClick={() => {
              toggle(false);
            }}
          >
            Cancelar
          </Button>
          <div />
          <Button
            style={{ width: "12rem", justifySelf: "right" }}
            onClick={() => {
              toggle(false);
            }}
          >
            Asignar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

AssingSATPurchase.propTypes = {};

export default AssingSATPurchase;
