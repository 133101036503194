import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getColumnsSales } from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import NtaDataTable from "../../components/NtaDataTable";
import Menu from "../../components/Menu";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import Input from "../../components/Input";
import SalesDetails from "../../components/modals/SalesDetails";

const SalesList = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const [searcNested, setSearcNested] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [singleData, setSingleData] = useState({});
  const navigate = useNavigate();
  const searchFileds = [
    "folioBoarding",
    "invoice",
    "companyName",
    "origin",
    "destination",
    "product",
  ];

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
    setSearcNested("");
    const filteredData = [];
    const searchLower = searchValue.toLowerCase();
    const searchFields = searchFileds;
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const handleSearcNested = (searchValue) => {
    setSearcNested(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields = searchFileds;
    data.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const fetchData = useCallback(async () => {
    const { ok, data } = await Request(`/client/sales`, null, "GET");
    if (ok) {
      setAllData(data);
      setData(data);
    }
  }, []);

  const dtFuncs = {
    details: (row) => {
      setSingleData(row);
      setIsOpen(true);
    },
    edit: (row) => {
      const { idSale } = row;
      navigate("/editar-venta/" + idSale);
    },
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Menu />
      <div
        className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList"
        style={{ width: "90%" }}
      >
        <div className="grid grid-cols-4 inputsForm">
          <PageTitle className=" col-span-3 ">
            LISTA DE VENTAS CAPTURADAS
          </PageTitle>
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/nueva-venta");
              }}
              className="float-right w-full mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 inputsForm">
          <Input
            label="Primer Filtro"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Búsqueda"
          />
          <Input
            label="Segundo Filtro"
            onChange={(e) => {
              handleSearcNested(e.target.value);
            }}
            disabled={search === ""}
            name="searchNested"
            required={false}
            value={searcNested}
            placeholder="Búsqueda"
          />
          <div />
          <Button
            className={"w-full px-0 mt-14 mb-2 align-right text-center"}
            onClick={() => {}}
          >
            Generar factura
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsSales(dtFuncs)} />
        <SalesDetails
          data={singleData}
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
          }}
        />
      </div>
    </>
  );
};

export default SalesList;
