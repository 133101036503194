import {
  GENERAL_CATALOGUES,
  GENERAL_LOGIN,
  GENERAL_LOGOUT,
  GENERAL_PLATFORM,
} from ".";
import { Request } from "../../helpers/api";
import { endSession, getFromSession } from "../../helpers/storageHandler";

const parseJwt = (jwt) => {
  var base64Url = jwt.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const startLogin =
  (username, password, keepSession, navigation) =>
  async (dispatch, getState) => {
    const res = await Request("/user/login", { username, password }, "POST");
    if (res.ok) {
      const { jwt } = res;
      if (keepSession) {
        localStorage.setItem("jwt", jwt);
      } else {
        sessionStorage.setItem("jwt", jwt);
      }
      dispatch(initialFetch(jwt));
      navigation();
    }
  };

export const initialFetch = (jwt) => async (dispatch, getState) => {
  const res = await Request("/user/catalogues", null, "GET");
  const idUnit = getFromSession("idUnit") || "";
  const idSeason = getFromSession("idSeason") || "";
  if (res.ok) {
    const catalogues = res.data;
    dispatch({ type: GENERAL_LOGIN, payload: { ...parseJwt(jwt) } });
    dispatch({ type: GENERAL_CATALOGUES, payload: catalogues });
    dispatch({ type: GENERAL_PLATFORM, payload: { idUnit, idSeason } });
  }
};

export const startLogout = () => (dispatch) => {
  dispatch({ type: GENERAL_LOGOUT });
  endSession();
};
