import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { parseObjectDate } from "../../helpers/utils";
import Button from "../Button";
import noop from "../../helpers/noop";
import PageTitle from "../PageTitle";

const ClientDetails = ({ isOpen, data, toggle, onConfirm = noop, onCancel = noop }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>
        <PageTitle>DETALLE DE CLIENTE</PageTitle>
        {/*<span
          className="float-right px-8 py-2 text-xl rounded-lg text-black "
          style={{
            backgroundColor: getBallotStatusColor(data.idCatBallotStatus),
          }}
        >
          {data.status}
        </span>*/}
      </ModalHeader>
      <ModalBody>
        <table className="ntaTableDataSet">
          <tbody>
            <tr>
              <td>
                <strong>Razón social: </strong>
                <span>{data.companyName}</span>
              </td>
              <td>
                <strong>Fecha de creación: </strong>
                <span>{parseObjectDate(new Date(data.createdAt), 3)}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Contacto: </strong>
                <span>{data.contactName}</span>
              </td>
              <td>
                <strong>RFC: </strong>
                <span>{data.rfc}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Teléfono: </strong>
                <span>{data.phone}</span>
              </td>
              <td>
                <strong>Correo: </strong>
                <span>{data.email}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Dirección: </strong>
                <span>{data.address}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 w-full btnModal">
          <div />
          <div />
          <Button
            onClick={() => {
              toggle(false);
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ClientDetails.propTypes = {};

export default ClientDetails;
