import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  parseCat,
  parseObjectDate,
  parseVoidFloat,
  parseVoidZeroFloat,
} from "../helpers/utils";
import { useForm } from "../helpers/useForm";
import { Request } from "../helpers/api";
import { ballotSchema, producerTicketSchema } from "../helpers/schemas";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";
import Select from "../components/Select";
import Menu from "../components/Menu";
import NtaForm from "../components/NtaForm";
import Swal from "sweetalert2";
import PageTitle from "../components/PageTitle";
import ButtonIcon from "../components/ButtonIcon";
import "../App";
import "../App.css";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { downloadTypeEnum } from "../helpers/enums";

const BallotCapture = () => {
  const initialCats = useSelector((s) => s.catalogues);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id: idBallot } = useParams();
  const [persons, setPersons] = useState([]);
  const [catalogues, setCatalogues] = useState(initialCats);
  const [warehouses, setWarehouses] = useState([]);
  const [ejidos, setEjidos] = useState([]);
  const [ejFileds, setEjFields] = useState([]);
  const {
    vehicles = [],
    products,
    catVehicles,
    ejidos: catEjidos,
  } = initialCats;
  const {
    producers = [],
    freighters = [],
    comissionAgents = [],
    ticketCollectors = [],
    threshers = [],
  } = catalogues;
  const [newBallot, newBallotChange, newBallotReset, setAllValues] = useForm({
    ...ballotSchema,
  });
  const putData = async () => {
    const { idCatBallotStatus, status, producers } = newBallot;
    if (
      producers.length > 0 ||
      idCatBallotStatus === 1 ||
      idCatBallotStatus === 2
    ) {
      if (
        idCatBallotStatus === 3 &&
        producers.find((p) => p.idProducer === "")
      ) {
        Swal.fire(
          "Error",
          "Debes seleccionar un productor para cada fila",
          "warning"
        );
      } else {
        Swal.fire({
          title: "¿Deseas actualizar la información?",
          text: `El estatus de la Boleta quedará en ${
            idCatBallotStatus === 1 ? "PENDIENTE" : status.toUpperCase()
          }`,
          icon: "info",
          showCancelButton: true,
        }).then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            const res = await Request(
              "/ballot/" + idBallot,
              {
                ...newBallot,
                idCatBallotStatus:
                  idCatBallotStatus === 1 ? 2 : idCatBallotStatus,
              },
              "PUT"
            );
            if (res.ok) {
              Swal.fire({ title: "Boleta guardada", icon: "success" }).then(
                () => {
                  navigate("/boletas");
                }
              );
            }
          }
        });
      }
    } else {
      Swal.fire("Error", "No hay productores en ésta captura", "warning");
    }
  };
  const fetchInitialData = useCallback(async () => {
    const resBallot = await Request(`/ballot/${idBallot}`, undefined, "GET");
    const resPersons = await Request(`/supplier?identityKey=INE`, null, "GET");
    if (resBallot.ok) {
      const ballot = resBallot.ballot;
      const producers = ballot.producers.map((p) => {
        const newP = {};
        Object.keys(p).map((kp) => {
          newP[kp] = p[kp] === 0 ? "" : p[kp];
        });
        return newP;
      });
      setAllValues({
        ...ballot,
        idCatBallotStatus: ballot.idCatBallotStatus,
        status: ballot.status,
        folio: ballot.folio,
        createdAt: ballot.createdAt,
        idProduct: ballot.idProduct,
        idContact: ballot.idContact,
        idEjido: ballot.idEjido,
        ticket: ballot.ticket,
        idWarehouse: ballot.idWarehouse,
        freightDifference: ballot.freightDifference,
        idTicketCollector: ballot.idTicketCollector,
        idComissionAgent: ballot.idComissionAgent,
        idVehicle: ballot.idVehicle,
        idFreighter: ballot.idFreighter,
        tara: ballot.tara,
        gross: ballot.gross,
        net: ballot.net,
        producers,
        recivedHumidity:
          ballot.recivedHumidity === 0 ? "" : ballot.recivedHumidity,
        comments: ballot.comments,
        idBallot: ballot.idBallot,
        receptionDate:
          ballot.receptionDate === null
            ? parseObjectDate(new Date(), 4)
            : parseObjectDate(
                new Date(ballot.receptionDate.replace("Z", "")),
                4
              ),
        idThresher: ballot.idThresher,
        valueFreightDifference: ballot.valueFreightDifference,
        idUnit: ballot.idUnit,
        scales: ballot.scales,
        departures: ballot.departures,
        downloadType: ballot.downloadType,
      });
    }
    if (resPersons.ok) {
      const personsData = resPersons.data;
      const personsOb = {};
      const personsCats = [
        {
          key: "producers",
          data: [],
          id: 4,
        },
        {
          key: "freighters",
          data: [],
          id: 7,
        },
        {
          key: "comissionAgents",
          data: [],
          id: 6,
        },
        {
          key: "ticketCollectors",
          data: [],
          id: 2,
        },
        {
          key: "threshers",
          data: [],
          id: 3,
        },
      ];
      personsData.forEach((p) => {
        const pRoles = p.cat_suppliers.map((c) => c.idCatSupplier);
        personsCats.forEach((pc, ix) => {
          if (pRoles.includes(pc.id)) {
            personsCats[ix].data.push(p);
          }
        });
      });
      personsCats.forEach((pc) => {
        personsOb[pc.key] = pc.data;
      });
      setPersons(personsData);
      setCatalogues({ ...catalogues, ...personsOb });
    }
  });
  const fetchWarehouses = async () => {
    if (newBallot.idProduct && newBallot.idProduct !== "") {
      const res = await Request(
        `/product/ejidoWarehouse?idProduct=${newBallot.idProduct}`,
        null,
        "GET"
      );
      if (res.ok) {
        setWarehouses(res.data.filter((w) => w.isActive));
        newBallotChange({
          target: { name: "idWarehouse", value: newBallot.idWarehouse },
        });
      }
    }
  };
  const capureBallot = async (e) => {
    e.preventDefault();
    const { idBallot, net } = newBallot;
    if (net > 0) {
      Swal.fire({
        title: "¿Deseas finalizar la Captura?",
        text: "El estatus de la boleta quedará en CAPTURADA",
        icon: "info",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          setIsLoading(true);
          const res = await Request(
            "/ballot/" + idBallot,
            { ...newBallot, idCatBallotStatus: 3 },
            "PUT"
          );
          setIsLoading(false);
          if (res.ok) {
            Swal.fire({ title: "Captura exitosa", icon: "success" }).then(
              () => {
                navigate("/boletas");
              }
            );
          }
        }
      });
    } else {
      Swal.fire({
        title: "¡Espera!",
        text: "Debes capturar correctamente el Peso Bruto antes de continuar",
        icon: "warning",
      });
    }
  };
  const modifyProducers = (willDetele = false, ix = null) => {
    let { producers, net, gross, tara, ticket } = newBallot;
    if (net > 0) {
      if (willDetele) {
        producers.splice(ix, 1);
      } else {
        producers.push({ ...producerTicketSchema });
      }
      if (producers.length === 1 && !producers[0].idBallotProducer) {
        producers[0] = {
          ...producers[0],
          gross,
          tara,
          net,
          ticketNo: ticket,
          byHopper: false,
        };
      } else if (producers.length === 2 && !producers[0].idBallotProducer) {
        producers[0] = {
          ...producers[0],
          gross: "",
          tara: "",
          net: "",
          ticketNo: "",
          byHopper: true,
        };
      }
      newBallotChange({
        target: { name: "producers", value: producers },
      });
      updateHopperProducers();
    } else {
      Swal.fire({
        title: "¡Espera!",
        text: "Debes capturar correctamente el Peso Bruto antes de continuar",
        icon: "warning",
      });
    }
  };
  const handleProducerDataChange = (e, ix) => {
    const { producers } = newBallot;
    producers[ix][e.target.name] = e.target.value;
    newBallotChange({ target: { name: "producers", value: producers } });
  };
  const updateHopperProducers = (ix = 0, net = 0) => {
    const { producers, net: ballotNet } = newBallot;
    let hopperCount = 0;
    let additionalCount = net;
    producers.forEach((p, i) => {
      if (p.byHopper) {
        const hopper =
          (parseVoidFloat(p.hopperNum) +
            parseVoidFloat(p.hopperInt) * parseVoidZeroFloat(p.hopperDen)) /
          parseVoidZeroFloat(p.hopperDen);
        additionalCount += parseVoidFloat(p.additionalWeight);
        hopperCount += hopper;
      } else if (i !== ix && p.byGross) {
        additionalCount += parseVoidFloat(p.net);
      }
    });
    const hopperUnit =
      ((ballotNet < 0 ? 0 : ballotNet) - additionalCount) /
      (hopperCount === 0 ? 1 : parseFloat(hopperCount));
    producers.forEach((p, i) => {
      if (p.byHopper) {
        const hopper =
          (parseVoidFloat(p.hopperNum) +
            parseVoidFloat(p.hopperInt) * parseVoidZeroFloat(p.hopperDen)) /
          parseVoidZeroFloat(p.hopperDen);
        handleProducerDataChange(
          {
            target: {
              name: "net",
              value: parseFloat(
                (
                  hopperUnit * hopper +
                  parseVoidFloat(p.additionalWeight)
                ).toFixed(2)
              ),
            },
          },
          i
        );
      }
    });
  };
  const handleWeightsChange = (e, ix) => {
    const { producers, net: ballotNet } = newBallot;
    const value = parseVoidFloat(e.target.value);
    let byHopper = true,
      byGross = true;
    if (
      ["hopperDen", "hopperNum", "additionalWeight", "hopperInt"].includes(
        e.target.name
      )
    ) {
      let hopper, additional;
      switch (e.target.name) {
        case "hopperDen":
          hopper =
            (parseVoidFloat(producers[ix].hopperNum) +
              parseVoidFloat(producers[ix].hopperInt) *
                parseVoidZeroFloat(value)) /
            parseVoidZeroFloat(value);
          additional = parseVoidFloat(producers[ix].additionalWeight);
          break;
        case "hopperNum":
          hopper =
            (parseVoidFloat(value) +
              parseVoidFloat(producers[ix].hopperInt) *
                parseVoidZeroFloat(producers[ix].hopperDen)) /
            parseVoidZeroFloat(producers[ix].hopperDen);
          additional = parseVoidFloat(producers[ix].additionalWeight);
          break;
        case "additionalWeight":
          hopper =
            (parseVoidFloat(producers[ix].hopperNum) +
              parseVoidFloat(producers[ix].hopperInt) *
                parseVoidZeroFloat(producers[ix].hopperDen)) /
            parseVoidZeroFloat(producers[ix].hopperDen);
          additional = value;
          break;
        case "hopperInt":
          hopper =
            (parseVoidFloat(producers[ix].hopperNum) +
              parseVoidFloat(value) *
                parseVoidZeroFloat(producers[ix].hopperDen)) /
            parseVoidZeroFloat(producers[ix].hopperDen);
          additional = parseVoidFloat(producers[ix].additionalWeight);
          break;
      }
      let hopperCount = hopper;
      let additionalCount = additional;
      byGross = !(hopper > 0 || additional > 0);
      producers.forEach((p, i) => {
        if (i !== ix) {
          const prodHopper =
            (parseVoidFloat(p.hopperNum) +
              parseVoidFloat(p.hopperInt) * parseVoidZeroFloat(p.hopperDen)) /
            parseVoidZeroFloat(p.hopperDen);
          if (p.byHopper) {
            additionalCount += parseVoidFloat(p.additionalWeight);
            hopperCount += parseVoidFloat(prodHopper);
          } else if (p.byGross) {
            additionalCount += parseVoidFloat(p.net);
          }
          console.log(hopperCount, additionalCount);
        }
      });
      const hopperUnit =
        (ballotNet - additionalCount) /
        (hopperCount === 0 ? 1 : parseVoidFloat(hopperCount));
      console.log(ix, hopperUnit);
      const hopperNet = parseFloat(
        (hopperUnit * hopper + additional).toFixed(2)
      );
      producers.forEach((p, i) => {
        if (i !== ix && p.byHopper) {
          const prodHopper =
            (parseVoidFloat(p.hopperNum) +
              parseVoidFloat(p.hopperInt) * parseVoidZeroFloat(p.hopperDen)) /
            parseVoidZeroFloat(p.hopperDen);
          console.log(i + "-> ", prodHopper);
          handleProducerDataChange(
            {
              target: {
                name: "net",
                value: parseFloat(
                  (
                    hopperUnit * prodHopper +
                    parseVoidFloat(p.additionalWeight)
                  ).toFixed(2)
                ),
              },
            },
            i
          );
        }
      });
      handleProducerDataChange(
        { target: { name: "net", value: hopperNet } },
        ix
      );
      handleProducerDataChange(
        { target: { name: "hopper", value: hopper } },
        ix
      );
    } else if (["tara", "gross"].includes(e.target.name)) {
      let tara = 0;
      let gross = 0;
      if (e.target.name === "tara") {
        tara = value;
        gross = producers[ix].gross;
      } else {
        gross = value;
        tara = producers[ix].tara;
      }
      byHopper = !(tara > 0 || gross > 0);
      const netBef = parseFloat(parseFloat(gross - tara).toFixed(2));
      const net = netBef < 0 ? 0 : netBef;
      handleProducerDataChange({ target: { name: "net", value: net } }, ix);
      updateHopperProducers(ix, net);
    }
    handleProducerDataChange(
      { target: { name: "byHopper", value: byHopper } },
      ix
    );
    handleProducerDataChange(
      { target: { name: "byGross", value: byGross } },
      ix
    );
    handleProducerDataChange(e, ix);
  };
  const setBallotNet = () => {
    const { tara, gross } = newBallot;
    const net = parseFloat((gross - tara).toFixed(2));
    newBallotChange({ target: { name: "net", value: net < 0 ? 0 : net } });
  };
  const getEjidos = (idWarehouse) => {
    const warehouse = warehouses?.find((w) => w.idWarehouse == idWarehouse);
    if (warehouse && idWarehouse !== "") {
      const filteredEjidos = warehouse.ejidoswarehouse.filter(
        (e) => e.ejido.isActive
      );
      setEjidos(filteredEjidos);
    }
  };
  useEffect(() => {
    setBallotNet();
  }, [newBallot.gross]);
  useEffect(() => {
    fetchInitialData();
  }, []);
  useEffect(() => {
    fetchWarehouses();
  }, [newBallot.idProduct]);
  useEffect(() => {
    getEjidos(newBallot.idWarehouse);
  }, [newBallot.idWarehouse, warehouses]);
  useEffect(() => {
    const { idEjido, name } = newBallot;
    const fields = catEjidos?.find((e) => e.idEjido == idEjido)
      ?.origin_fields || [{ idOriginField: "", name }];
    if (fields.length === 1) {
      newBallotChange({
        target: { name: "idOriginField", value: fields[0].idOriginField },
      });
    }
    setEjFields(fields);
  }, [newBallot.idEjido]);
  return (
    <>
      <Menu />
      <div
        className="font-bold text-ntaBlue text-xl mx-auto mt-10 text-center"
        style={{ width: "90%" }}
      >
        <PageTitle>
          {[1, 2].includes(newBallot.idCatBallotStatus)
            ? "CAPTURAR BOLETA"
            : "EDITAR BOLETA"}
        </PageTitle>
      </div>
      <div
        className="text-ntaBlue text-2xl mx-auto mt-10 text-left"
        style={{ width: "90%" }}
      >
        <p>
          Folio:{" "}
          <strong
            className="cursor-copy"
            onClick={() => {
              navigator.clipboard.writeText(newBallot.folio);
            }}
          >
            {newBallot.folio}
          </strong>
          <br />
          Fecha de Generación:
          <strong>
            {parseObjectDate(new Date(newBallot.createdAt), 3) || ""}
          </strong>
        </p>
      </div>
      <NtaForm onSubmit={capureBallot}>
        <div
          className="font-bold text-ntaBlue text-xl mx-auto text-left mt-10 subtituloForm"
          style={{ width: "90%" }}
        >
          GENERALES
        </div>
        <div
          className="grid grid-cols-2 gap-4 mx-auto inputsForm"
          style={{ width: "90%" }}
        >
          <Select
            arrOptions={[...parseCat(products, "name", "idProduct")]}
            required={true}
            name="idProduct"
            disabled={![1, 2].includes(newBallot.idCatBallotStatus)}
            onChange={newBallotChange}
            value={newBallot.idProduct}
            label="Producto"
          />
          <Input
            type="date"
            required={true}
            disabled={true}
            label="Fecha de captura"
            value={parseObjectDate(new Date(), 4)}
          />
          <Select
            arrOptions={[
              { label: "Seleccione un Contacto", value: "" },
              ...parseCat(
                persons.map((p) => ({
                  ...p,
                  namePhone: `${p.name} - ${p.phone}`,
                })),
                "namePhone",
                "idSupplier"
              ),
            ]}
            disabled={true}
            required={true}
            name="idContact"
            onChange={newBallotChange}
            value={newBallot.idContact}
            label="Contacto "
          />
          <Input
            type="date"
            required={true}
            label="Fecha de descarga"
            name="receptionDate"
            disabled={newBallot.idCatBallotStatus !== 1}
            max={parseObjectDate(new Date(), 4)}
            onChange={newBallotChange}
            value={newBallot.receptionDate}
          />
          <Input
            type="text"
            required={true}
            disabled={true}
            maxLength={50}
            label="Ticket "
            placeholder="1045823T"
            name="ticket"
            onChange={newBallotChange}
            value={newBallot.ticket}
          />
          <Select
            arrOptions={[...parseCat(warehouses, "name", "idWarehouse")]}
            required={true}
            name="idWarehouse"
            onChange={newBallotChange}
            disabled={![1, 2].includes(newBallot.idCatBallotStatus)}
            value={newBallot.idWarehouse}
            label="Almacén"
          />
          <Select
            arrOptions={[...parseCat(ejidos, "name", "idEjido")]}
            required={true}
            name="idEjido"
            disabled={![1, 2].includes(newBallot.idCatBallotStatus)}
            onChange={newBallotChange}
            value={newBallot.idEjido}
            label="Ejido"
          />
          <Select
            arrOptions={[
              //{ label: "Seleccione un campo", value: "" },
              ...parseCat(ejFileds, "name", "idOriginField"),
            ]}
            required={true}
            disabled={newBallot.idWarehouse === ""}
            name="idOriginField"
            onChange={newBallotChange}
            value={newBallot.idOriginField}
            label="Campo"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newBallot.freightDifference}
                onChange={(e) => {
                  newBallotChange({
                    target: {
                      name: "freightDifference",
                      value: e.target.checked,
                    },
                  });
                }}
              />
            }
            label={"Diferencia de flete"}
          />
          <div />
          {newBallot.freightDifference && (
            <>
              <Input
                type="number"
                required={true}
                min={1}
                step={1}
                label="Diferencia (T)"
                placeholder=""
                name="valueFreightDifference"
                onChange={newBallotChange}
                value={newBallot.valueFreightDifference}
              />
              <div />
            </>
          )}
          <Input
            type="number"
            required={false}
            min={0}
            max={newBallot.producers.length}
            step={1}
            label="Básculas"
            placeholder=""
            name="scale"
            onChange={newBallotChange}
            value={newBallot.scale}
          />
          <Input
            type="number"
            required={false}
            min={0}
            max={newBallot.producers.length - 1}
            step={1}
            label="Salidas"
            placeholder=""
            name="departure"
            onChange={newBallotChange}
            value={newBallot.departure}
          />
        </div>
        <div
          className="font-bold text-ntaBlue text-xl mx-auto text-left mt-10 subtituloForm"
          style={{ width: "90%" }}
        >
          SERVICIOS
        </div>
        <div
          className="grid grid-cols-2 gap-4 mx-auto inputsForm"
          style={{ width: "90%" }}
        >
          <Select
            arrOptions={[
              { label: "Seleccione un trillador", value: "" },
              ...parseCat(threshers, "name", "idSupplier"),
            ]}
            required={true}
            name="idThresher"
            onChange={newBallotChange}
            value={newBallot.idThresher}
            label="Trillador "
          />{" "}
          <Select
            arrOptions={[
              { label: "Seleccione un representante", value: "" },
              ...parseCat(comissionAgents, "name", "idSupplier"),
            ]}
            required={true}
            name="idComissionAgent"
            onChange={newBallotChange}
            value={newBallot.idComissionAgent}
            label="Representante "
          />
          <Select
            arrOptions={[
              { label: "Seleccione un boletero", value: "" },
              ...parseCat(ticketCollectors, "name", "idSupplier"),
            ]}
            required={true}
            name="idTicketCollector"
            onChange={newBallotChange}
            value={newBallot.idTicketCollector}
            label="Boletero "
          />
        </div>
        <div
          className="font-bold text-ntaBlue text-xl mx-auto text-left mt-10 subtituloForm"
          style={{ width: "90%" }}
        >
          FLETE
        </div>
        <div
          className="grid grid-cols-2 gap-4 mx-auto inputsForm"
          style={{ width: "90%" }}
        >
          <Select
            arrOptions={parseCat(
              vehicles.map((v) => ({
                ...v,
                plates: `${v.plates} - ${
                  catVehicles.find((cv) => cv.idCatVehicle === v.idCatVehicle)
                    ?.name
                }`,
              })),
              "plates",
              "idVehicle"
            )}
            required={true}
            name="idVehicle"
            disabled={true}
            onChange={newBallotChange}
            value={newBallot.idVehicle}
            label="Placa "
          />
          <Select
            arrOptions={[...parseCat(freighters, "name", "idSupplier")]}
            required={true}
            disabled={true}
            name="idFreighter"
            onChange={newBallotChange}
            value={newBallot.idFreighter}
            label="Chofer"
          />
          <Input
            type="number"
            required={true}
            min={newBallot.tara}
            step={0.01}
            label="Peso bruto (Kg)"
            placeholder="12000"
            name="gross"
            disabled={newBallot.producers.length > 0}
            onChange={newBallotChange}
            value={newBallot.gross}
          />
          <Input
            type="number"
            required={true}
            min={1}
            step={0.01}
            disabled={true}
            label="Peso tara (Kg)"
            placeholder="12000"
            name="tara"
            onChange={newBallotChange}
            value={newBallot.tara}
          />
          <FormControl>
            <label
              id="demo-radio-buttons-group-label"
              className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue"
            >
              Tipo de Descarga
            </label>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="downloadType"
              onChange={newBallotChange}
              defaultValue={newBallot.downloadType || ""}
              value={newBallot.downloadType || ""}
            >
              {downloadTypeEnum.map((en) => (
                <FormControlLabel
                  disabled={![1, 2].includes(newBallot.idCatBallotStatus)}
                  control={<Radio />}
                  value={en.value}
                  label={en.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Input
            type="number"
            required={true}
            disabled={true}
            min={1}
            step={0.01}
            label="Peso neto (Kg)"
            placeholder="- Kg"
            name="net"
            onChange={() => {}}
            value={newBallot.net}
          />
        </div>
        <div
          className="font-bold text-ntaBlue text-xl mx-auto text-left mt-10 subtituloForm"
          style={{ width: "90%" }}
        >
          PRODUCTORES
        </div>
        <div
          className="grid gap-4 mx-auto"
          style={{
            overflowY: "inherit !important",
            width: "90%",
          }}
        >
          <table
            className="vehicles-table w-full searchable"
            style={{ minWidth: "1600px" }}
          >
            <thead>
              <tr>
                <th width="2%">
                  <ButtonIcon
                    disabled={newBallot.idCatBallotStatus === 3}
                    className={"text-green-400"}
                    onClick={() => {
                      modifyProducers();
                    }}
                    icon={faPlus}
                  />
                </th>
                <th width="15%">Productor</th>
                <th width="7%">Humedad C.</th>
                <th width="8%">Superf</th>
                <th width="10%">Tolvas</th>
                <th width="8%">Adic.</th>
                <th width="8%">Bruto</th>
                <th width="8%">Tara</th>
                <th width="8%">Neto</th>
                <th width="8%">Ticket</th>
                <th width="9%">Fecha carga</th>
                <th width="7%">Precio</th>
              </tr>
            </thead>
            <tbody>
              {newBallot.producers?.length === 0 ? (
                <tr>
                  <td colSpan={12}>
                    <p className="my-5 itali text-xl">Nada para mostrar</p>
                  </td>
                </tr>
              ) : (
                newBallot.producers?.map((v, ix) => (
                  <tr>
                    <td className="px-2">
                      <ButtonIcon
                        className={"color-ntaBlue"}
                        disabled={newBallot.idCatBallotStatus === 3}
                        onClick={() => modifyProducers(true, ix)}
                        icon={faTrash}
                      />
                    </td>
                    <td className="px-2">
                      <Select
                        arrOptions={[
                          { label: "Nombre", value: "" },
                          ...parseCat(producers, "name", "idSupplier"),
                        ]}
                        containerClassName={"tableInput"}
                        required={true}
                        name="idProducer"
                        onChange={(e) => handleProducerDataChange(e, ix)}
                        value={v.idProducer}
                      />
                    </td>
                    <td className="px-2">
                      <Input
                        type="number"
                        required={newBallot.recivedHumidity === ""}
                        placeholder="- %"
                        name="recivedHumidity"
                        min={8}
                        max={30}
                        step={0.01}
                        containerClassName={"tableInput"}
                        onChange={(e) => handleProducerDataChange(e, ix)}
                        value={v.recivedHumidity}
                      />
                    </td>
                    <td className="px-2">
                      <Input
                        type="number"
                        required={true}
                        placeholder="- H"
                        min={0}
                        step={0.01}
                        name="treshedSurface"
                        containerClassName={"tableInput"}
                        onChange={(e) => handleProducerDataChange(e, ix)}
                        value={v.treshedSurface}
                      />
                    </td>
                    <td className="px-2">
                      <div className="grid grid-cols-2 gap-2">
                        <Input
                          type="number"
                          required={
                            v.hopperDen === "" &&
                            v.hopperNum === "" &&
                            v.additionalWeight === "" &&
                            v.byHopper
                          }
                          step={1}
                          min={1}
                          placeholder="-"
                          name="hopperInt"
                          containerClassName={"tableInput hopp  erFull grid"}
                          onChange={(e) => handleWeightsChange(e, ix)}
                          value={v.hopperInt}
                          disabled={!v.byHopper}
                          style={{
                            paddingTop: "8px !important",
                            paddingBottom: "8px !important",
                            heigth: "70px",
                          }}
                        />
                        <div>
                          <Input
                            type="number"
                            required={
                              v.hopperInt === "" &&
                              v.hopperDen !== "" &&
                              v.additionalWeight === "" &&
                              v.byHopper
                            }
                            step={1}
                            min={1}
                            placeholder="-"
                            name="hopperNum"
                            containerClassName={
                              "tableInput hopperDivisionBorder"
                            }
                            onChange={(e) => handleWeightsChange(e, ix)}
                            value={v.hopperNum}
                            disabled={!v.byHopper}
                            className="hopperFraction"
                          />
                          <Input
                            type="number"
                            required={
                              v.hopperInt === "" &&
                              v.hopperNum !== "" &&
                              v.additionalWeight === "" &&
                              v.byHopper
                            }
                            step={1}
                            min={1}
                            placeholder="-"
                            name="hopperDen"
                            containerClassName={"tableInput"}
                            onChange={(e) => handleWeightsChange(e, ix)}
                            value={v.hopperDen}
                            disabled={!v.byHopper}
                            className="hopperFraction"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="px-2">
                      <Input
                        type="number"
                        placeholder="- kg"
                        name="additionalWeight"
                        min="0"
                        step={0.01}
                        required={
                          v.hopperDen === "" &&
                          v.hopperNum === "" &&
                          v.hopperInt === "" &&
                          v.byHopper
                        }
                        disabled={!v.byHopper}
                        containerClassName={"tableInput"}
                        onChange={(e) => handleWeightsChange(e, ix)}
                        value={v.additionalWeight}
                      />
                    </td>
                    <td className="px-2">
                      <Input
                        type="number"
                        min={1}
                        step={0.01}
                        placeholder="- Kg"
                        containerClassName={"tableInput"}
                        name="gross"
                        onChange={(e) => handleWeightsChange(e, ix)}
                        value={v.gross}
                        disabled={!v.byGross}
                        required={v.byGross}
                      />
                    </td>
                    <td className="px-2">
                      <Input
                        type="number"
                        min={1}
                        step={0.01}
                        placeholder="- Kg"
                        containerClassName={"tableInput"}
                        name="tara"
                        onChange={(e) => handleWeightsChange(e, ix)}
                        value={v.tara}
                        disabled={!v.byGross}
                        required={v.byHopper}
                      />
                    </td>
                    <td className="px-2">
                      <Input
                        type="number"
                        disabled={true}
                        min={0}
                        step={0.01}
                        placeholder="- Kg"
                        containerClassName={"tableInput"}
                        name="net"
                        onChange={(e) => handleProducerDataChange(e, ix)}
                        value={v.net}
                        required={v.byGross}
                      />
                    </td>
                    <td className="px-2">
                      <Input
                        type="text"
                        required={!v.byHopper}
                        placeholder="Ticket"
                        containerClassName={"tableInput"}
                        name="ticketNo"
                        onChange={(e) => handleProducerDataChange(e, ix)}
                        value={v.ticketNo}
                      />
                    </td>
                    <td className="px-2">
                      <Input
                        type="date"
                        required={true}
                        max={parseObjectDate(new Date(), 4)}
                        placeholder="dd/mm/yyyy"
                        containerClassName={"tableInput"}
                        name="loadingDate"
                        onChange={(e) => handleProducerDataChange(e, ix)}
                        value={v.loadingDate}
                      />
                    </td>
                    <td className="px-2">
                      <Input
                        type="number"
                        required={false}
                        min={0}
                        step={0.01}
                        placeholder="$ -"
                        containerClassName={"tableInput"}
                        name="price"
                        onChange={(e) => handleProducerDataChange(e, ix)}
                        value={v.price}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div
          className="grid grid-cols-2 gap-4 mx-auto inputsForm"
          style={{ width: "90%" }}
        >
          <div>
            <Input
              type="number"
              required={
                newBallot.producers.find((p) => p.recivedHumidity !== "") === 0
              }
              min={8}
              max={30}
              step={0.01}
              label="Humedad del grano recibido (%) "
              placeholder="-%"
              name="recivedHumidity"
              onChange={newBallotChange}
              value={newBallot.recivedHumidity}
            />
          </div>
          <div />
        </div>
        <div className="grid mx-auto" style={{ width: "90%" }}>
          <Input
            type="textarea"
            required={false}
            label="Observaciones "
            placeholder="Alguna observación ..."
            name="comments"
            onChange={newBallotChange}
            value={newBallot.comments}
          />
        </div>
        <div
          className="grid grid-cols-2 gap-4 mx-auto inputsForm"
          style={{ width: "90%" }}
        >
          <div />
          <div className="grid grid-cols-2 gap-4">
            <Button disabled={isLoading} onClick={putData} type="button">
              GUARDAR
            </Button>
            {[1, 2].includes(newBallot.idCatBallotStatus) && (
              <Button disabled={isLoading}>CAPTURAR</Button>
            )}
          </div>
        </div>
      </NtaForm>
    </>
  );
};

export default BallotCapture;
