import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import {
  receptionScaleSchema,
  receptionSchema,
  unitSchema,
  behaviors
} from "../helpers/schemas";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import "../App";
import "../App.css";
import { useSelector } from "react-redux";
import { parseCat, TODAY } from "../helpers/utils";
import ButtonIcon from "../components/ButtonIcon";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Request } from "../helpers/api";
import { useNavigate, useParams } from "react-router-dom";

const ProductReception = () => {
  const { id: idReceptionWithoutContract } = useParams();
  const [formData, changeFromData, formDataReset, setAllForm] = useForm(receptionSchema);
  const [warehouses, setWarehouses] = useState([]);
  const { units, products, catWarehouse } = useSelector((s) => s.catalogues);
  const [suppliers, setSuppliers] = useState([]);
  const navigate = useNavigate();

  const [behavior, setBehavior] = useState(behaviors.insert);

  const fetchSuppliers = useCallback(async () => {
    const res = await Request(`/supplier?identityKey=RFC`, null, "GET");
    if (res.ok) {
      setSuppliers(res.data);
    }
  }, []);

  const fetchParameters = useCallback(async (formData) => {
    const { idWarehouse, idProduct } = formData;
    if (idWarehouse !== "" && idProduct !== "") {
      const res = await Request(`/product/parametersWarehouse?idWarehouse=${idWarehouse}&idProduct=${idProduct}`, null, "GET");
      if (res.ok) {
        const paramsKeys = ["density", "firedGrain", "impurities", "permissibleHumidity", "broken"];
        const sParams = {};
        paramsKeys.forEach((k) => {
          const param = res.data[k];
          if (param /*&& formData[k] === ""*/) {
            sParams[k] = param;
          }
        });
        setAllForm({ ...formData, ...sParams });
      }
    }
  }, []);

  const fetchWarehouses = async () => {
    if (formData.idProduct && formData.idProduct !== "") {
      const res = await Request(`/product/ejidoWarehouse?idProduct=${formData.idProduct}`, null, "GET");
      if (res.ok) {
        setWarehouses(res.data);
        changeFromData({
          target: { name: "idWarehouse", value: idReceptionWithoutContract ? formData.idWarehouse: "" },
        });
        
      }
    }
  };

  const manageScale = (willDelete = false, ix = null) => {
    const { scales } = formData;
    if (willDelete) {
      scales.splice(ix, 1);
    } else {
      scales.push({ ...receptionScaleSchema });
    }
    changeFromData({ target: { name: "scales", value: scales } });
  };

  const scaleFormChange = (e, ix) => {
    const { scales } = formData;
    scales[ix][e.target.name] = e.target.value;
    changeFromData({ target: { name: "scales", value: scales } });
  };

  const inserReception = async (e) => {
    e.preventDefault();
    const selectValidation = ["idUnit", "idWarehouse", "idProduct", "idSupplier"];
    const hasMissingFields = selectValidation.find((sv) => formData[sv] === "");
    if (hasMissingFields) {
      Swal.fire("Error", "Debes ingresar todos los campos requeridos antes de continuar", "warning");
    } else {
      const { scales } = formData;
      let weight = 0;
      const _scales = scales.map((s) => {
        const net = s.gross - s.tara;
        weight += net;
        return { ...s, net };
      });
      const res = await Request(
        `/entrie`,
        {
          ...formData,
          weight,
          scales: _scales,
        },
        "POST"
      );
      if (res.ok) {
        Swal.fire({
          title: "Registro exitoso",
          icon: "success",
        }).then(() => {
          navigate("/inventario");
        });
      }
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  useEffect(() => {
    fetchParameters(formData);
  }, [formData.idWarehouse, formData.idProduct]);

  const fetchData = useCallback(async () => {
    const res = await Request(`/entrie/receptionwithoutcontract/${idReceptionWithoutContract}`, undefined, "GET");
    if (res.ok) {
      setAllForm(res.data);
    }
  });

  useEffect(() => {
    if (idReceptionWithoutContract) {
      setBehavior(behaviors.edit);
      fetchData();
    }
  }, []);

  useEffect(() => {
    fetchWarehouses();
  }, [formData.idProduct]);

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-16 text-center">
        <PageTitle>RECEPCIÓN DE PRODUCTO</PageTitle>
      </div>
      <NtaForm onSubmit={inserReception}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Select
              arrOptions={[{ label: "Seleccione unidad" }, ...parseCat(units, "name", "idUnit")]}
              required={true}
              name="idUnit"
              onChange={changeFromData}
              value={formData.idUnit}
              label="Unidad"
              disabled={behavior === behaviors.edit}
            />
            <Select
              arrOptions={[
                { label: "Seleccione almacén" },
                ...parseCat(
                  warehouses?.filter((e) => e.idUnit === formData.idUnit),
                  "name",
                  "idWarehouse"
                ),
              ]}
              disabled={formData.idUnit === "" || formData.idProduct === "" || behavior === behaviors.edit}
              required={true}
              name="idWarehouse"
              onChange={changeFromData}
              value={formData.idWarehouse}
              label="Almacén"
            />
            
            <Input
              type="number"
              required={true}
              label="Precio"
              placeholder="Precio"
              name="price"
              min={0.01}
              step={0.01}
              onChange={changeFromData}
              value={formData.price}
              disabled={behavior === behaviors.edit}
            />

            <Input
              type="text"
              required={false}
              label="Número de factura"
              placeholder="Número de factura"
              name="invoiceNumber"
              maxLength={20}
              onChange={changeFromData}
              value={formData.invoiceNumber}
              disabled={behavior === behaviors.edit}
            />
          </div>
          <div>
            <Select
              arrOptions={[{ label: "Seleccione producto" }, ...parseCat(products, "name", "idProduct")]}
              required={true}
              name="idProduct"
              onChange={changeFromData}
              value={formData.idProduct}
              label="Producto"
              disabled={behavior === behaviors.edit}
            />
            <Select
              arrOptions={[{ label: "Seleccione proveedor" }, ...parseCat(suppliers, "name", "idSupplier")]}
              required={false}
              name="idSupplier"
              onChange={changeFromData}
              value={formData.idSupplier}
              label="Proveedor"
              disabled={behavior === behaviors.edit}
            />
          </div>
        </div>

        <h3 className="grid w-4/5 mx-auto grid-cols-1 mt-12 text-xl font-bold text-ntaBlue">PARÁMETROS DE CALIDAD</h3>

        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Input
              type="text"
              required={false}
              label="Humedad"
              placeholder="Humedad"
              min={8}
              max={30}
              step={0.01}
              name="permissibleHumidity"
              onChange={changeFromData}
              value={formData.permissibleHumidity}
              disabled={behavior === behaviors.edit}
            />

            <Input
              type="text"
              required={false}
              label="Impurezas"
              placeholder="Impurezas"
              name="impurities"
              onChange={changeFromData}
              value={formData.impurities}
              disabled={behavior === behaviors.edit}
            />
            <Input
              type="text"
              required={false}
              label="Grano fogueado"
              placeholder="Grano fogueado"
              name="firedGrain"
              onChange={changeFromData}
              value={formData.firedGrain}
              disabled={behavior === behaviors.edit}
            />
          </div>

          <div>
            <Input
              type="text"
              required={false}
              label="Densidad"
              placeholder="Densidad"
              name="density"
              maxLength={100}
              onChange={changeFromData}
              value={formData.density}
              disabled={behavior === behaviors.edit}
            />
            <Input
              type="text"
              required={false}
              label="Quebrado"
              placeholder="Quebrado"
              name="broken"
              onChange={changeFromData}
              value={formData.broken}
              disabled={behavior === behaviors.edit}
            />
          </div>

        </div>

        {/*DATOS DE BÁSCULA*/}
        <h3 className="grid w-4/5 mx-auto grid-cols-2 mt-12 text-xl font-bold text-ntaBlue">
          <div>DATOS DE BÁSCULA</div>
          <a
            onClick={() => {
              manageScale();
            }}
            className="place-self-end text-ntaBlue hover:text-ntaBlue underline-offset-8 underline cursor-pointer text-lg"
          >
            Añadir otro peso
          </a>
        </h3>

        {formData.scales.map((s, ix) => (
          <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
            <div>
              <Input
                type="date"
                required={true}
                max={TODAY}
                label="Fecha de embarque"
                name="boardingDate"
                onChange={(e) => {
                  scaleFormChange(e, ix);
                }}
                value={s.boardingDate}
                disabled={behavior === behaviors.edit}
              />
              <Input
                type="number"
                required={true}
                step={0.01}
                label="Peso Bruto (Kg)"
                placeholder="Peso bruto"
                name="gross"
                maxLength={100}
                onChange={(e) => {
                  scaleFormChange(e, ix);
                }}
                value={s.gross}
                disabled={behavior === behaviors.edit}
              />
              <Input
                type="number"
                required={true}
                label="Peso Neto (Kg)"
                placeholder="Peso neto"
                name="net"
                min={0.01}
                disabled={true}
                onChange={(e) => {
                  scaleFormChange(e, ix);
                }}
                value={s.gross - s.tara < 0 ? 0 : s.gross - s.tara}
              />
            </div>

            <div>
              <Input
                type="text"
                required={true}
                label="Número de ticket"
                placeholder="Número de ticket"
                name="ticket"
                onChange={(e) => {
                  scaleFormChange(e, ix);
                }}
                value={s.ticket}
                disabled={behavior === behaviors.edit}
              />
              <Input
                type="number"
                required={true}
                label="Peso Tara (Kg)"
                step={0.01}
                placeholder="Peso tara"
                name="tara"
                onChange={(e) => {
                  scaleFormChange(e, ix);
                }}
                value={s.tara}
                disabled={behavior === behaviors.edit}
              />
              <ButtonIcon
                className="float-right mt-6"
                disabled={formData.scales.length === 1 || behavior === behaviors.edit}
                onClick={() => {
                  manageScale(true, ix);
                }}
                icon={faTrashCan}
              />
            </div>
          </div>
        ))}

        <div className="w-4/5 mx-auto">
          <Input
            type="textarea"
            required={false}
            label="Observaciones"
            placeholder="Observaciones"
            name="comments"
            onChange={changeFromData}
            value={formData.comments}
            disabled={behavior === behaviors.edit} 
          />
          <Input type="textarea" required={false} label="Observaciones" placeholder="Observaciones" name="comments" onChange={changeFromData} value={formData.comments} />
        </div>

        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4 inputsForm">
          <div />
          <div />
          <Button disabled={behavior === behaviors.edit}>Aceptar</Button>
        </div>
      </NtaForm>
    </>
  );
};

export default ProductReception;
