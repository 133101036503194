import React from "react";
import PropTypes from "prop-types";

const Input = (props) => {
  const {
    containerClassName = "",
    className = "",
    imgBtnRigth,
    label = "",
    placeholder = label,
    name,
    value = "",
    required,
    max,
    min,
    maxLength,
    minLength,
    type,
    isValid = false,
    onChange,
    disabled = false,
    step,
    style = {},
  } = props;

  const InputBtnRight = imgBtnRigth ? (
    <div class="flex -mr-px items-end justify-end -mt-10">
      <span className="flex items-center leading-normal rounded rounded-l-none border-0 px-3 whitespace-no-wrap">
        <img
          src={imgBtnRigth}
          width="25"
          className="relative right-2 -mt-1 cursor-pointer"
        />
      </span>
    </div>
  ) : (
    <></>
  );

  const Element = type === "textarea" ? type : "input";
  return (
    <div className={`mx-auto w-full mt-6 ${containerClassName}`} style={style}>
      {label && (
        <label className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">
          {label} {required ? "*" : ""}
        </label>
      )}
      <Element
        className={`${className} inputBorder placeholder-ntaGray bg-ntaInput py-3 px-4 my-2 rounded-lg w-full opacity-70 focus:ring-ntaGray focus:outline-none focus:border-ntaGray`}
        onWheel={(e) => e.target.blur()}
        {...{
          name,
          value,
          required,
          max,
          min,
          maxLength,
          minLength,
          type,
          placeholder,
          onChange,
          disabled,
          step,
        }}
      />
      {/*isValid && value !== "" && <>Error en éste campo</>*/}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  type: PropTypes.oneOf([
    "password",
    "text",
    "email",
    "number",
    "date",
    "tel",
    "textarea",
  ]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  required: PropTypes.bool.isRequired,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlud: PropTypes.func,
  disabled: PropTypes.bool,
  step: PropTypes.number,
};

export default Input;
