import React, { useCallback, useEffect, useState } from "react";
import { parseCat, parseObjectDate, TODAY } from "../helpers/utils";
import { useNavigate } from "react-router-dom";
import { getColumnsInventory } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Input from "../components/Input";
import Select from "../components/Select";
import Kardex from "../components/modals/Kardex";
import { useSelector } from "react-redux";
import { useForm } from "../helpers/useForm";
import "../App";
import "../App.css";

const getFirstDateMonth = (today) => {
  return today;
  if (today) {
    return today.substring(0, today.length - 2) + "01";
  }
};

const Inventory = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { startDate } = useSelector((s) => s.platform);
  const [filters, setFilters, resetFilters] = useForm({
    idUnit: "all",
    idProduct: "all",
    startDate: getFirstDateMonth(startDate),
    endDate: TODAY,
  });
  let { units, products } = useSelector((s) => s.catalogues);
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const filteredData = [];
    const searchLower = searchValue.toLowerCase();
    const searchFields =
      lookupField === "all" ? ["warehouse", "unit", "product"] : [lookupField];

    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/inventory?${filterStr}`, null, "GET");
    setAllData(res.data);
    setData(res.data);
  }, []);

  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);

  const dtFuncs = {
    details: (row) => {
      setSingleData({ idInventory: row.idInventory, ...filters });
      setModalOpen(true);
    },
  };

  return (
    <>
      <Menu />
      <div
        className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList"
        style={{ width: "90%" }}
      >
        <div className="grid grid-cols-2">
          <PageTitle>INVENTARIO</PageTitle>
        </div>
        <div className="grid grid-cols-6 gap-4 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por almacén"
          />
          <Select
            arrOptions={[
              { value: "all", label: "Todos" },
              ...parseCat(units, "name", "idUnit"),
            ]}
            required={false}
            name="idUnit"
            onChange={setFilters}
            value={filters.idUnit}
            label="Unidad"
          />
          <Select
            arrOptions={[
              { value: "all", label: "Todos" },
              ...parseCat(products, "name", "idProduct"),
            ]}
            required={false}
            name="idProduct"
            onChange={setFilters}
            value={filters.idProduct}
            label="Producto"
          />
          <Input
            type="date"
            required={false}
            label="Fecha inicio"
            name="startDate"
            max={TODAY}
            onChange={setFilters}
            value={filters.startDate}
          />
          <Input
            type="date"
            required={false}
            label="Fecha Fin"
            name="endDate"
            onChange={setFilters}
            value={filters.endDate}
          />
          <Button
            style={{ placeSelf: "end", width: "6rem" }}
            className={"w-1/2 px-0 mt-14 mb-2 align-right text-center"}
            onClick={() => {
              fetchData();
              resetFilters();
              setSearch("");
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsInventory(dtFuncs)} />
      </div>
      <Kardex
        isOpen={modalOpen}
        data={singleData}
        toggle={setModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default Inventory;
