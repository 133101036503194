import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import noop from "../../helpers/noop";
import PropTypes from "prop-types";
import Button from "../Button";
import PageTitle from "../PageTitle";
import ProductInsertContent from "./ProductInsertContent";
import NtaForm from "../NtaForm";

const ProductInsert = ({ isOpen, data = { products: [] }, toggle, onConfirm = noop, onCancel = noop, manageSingleWarehouse, onWarehouseProductChange }) => {
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} className={"mt-10"}>
      <NtaForm
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm(data.ix, data.products);
          toggle();
        }}
      >
        <ModalHeader>
          <div className="text-left ml-4 mt-3">
            <PageTitle>UNIDAD</PageTitle>
            <h3 className="text-2xl text-left text-ntaBlue mt-2 mb-0">ALMACÉN</h3>
            <a className="relative float-right right-10 addProductsLink">
              <h3
                onClick={() => {
                  manageSingleWarehouse();
                }}
                className="text-lg text-ntaBlue font-semibold px-1"
              >
                Añadir otro producto
              </h3>
            </a>
          </div>
        </ModalHeader>
        <ModalBody className="modalProductInsert">
          {data.products?.map((p, ix) => (
            <ProductInsertContent
              data={{ ...p, ix }}
              allProducts={data.products?.map((p) => p.idProduct || [])}
              manageProduct={manageSingleWarehouse}
              productChange={onWarehouseProductChange}
              wIx={data.ix}
            />
          ))}
        </ModalBody>
        <ModalFooter>
          <div className="grid grid-cols-2 gap-5 relative float-right">
            <Button type="button" onClick={onCancel} className={"bg-ntaGray text-lg"}>
              Cancelar
            </Button>
            <Button type="submit" className="text-lg">
              Agregar a almácen
            </Button>
          </div>
        </ModalFooter>
      </NtaForm>
    </Modal>
  );
};

ProductInsert.propTypes = {};

export default ProductInsert;
