import React, { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import { useForm } from "../../helpers/useForm";
import { Request } from "../../helpers/api";
import {
  parseAvailableDaysToArray,
  parseCat,
  parseObjectDate,
  santizeDateTime,
} from "../../helpers/utils";
import {
  behaviors,
  orderClientSchema,
  orderSchema,
} from "../../helpers/schemas";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import NtaForm from "../../components/NtaForm";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Menu from "../../components/Menu";
import PageTitle from "../../components/PageTitle";
import "../../App";
import "../../App.css";
import { useSelector } from "react-redux";
import ButtonIcon from "../../components/ButtonIcon";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderCapture = () => {
  const { id: idClientOrder } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { catWarehouse, products, catVehicles } = useSelector(
    (s) => s.catalogues
  );
  const [newOrder, newOrderChange, newOrderReset, setAllOrder] =
    useForm(orderSchema);
  const [behavior, setBehavior] = useState(behaviors.insert);
  const [clients, setClients] = useState([]);
  const [clientData, setClientData] = useState({});
  const [destinations, setDesintations] = useState([]);

  const manageClientOrders = (willDetele = false, ix = null) => {
    const { detailclientorders } = newOrder;
    if (willDetele) {
      detailclientorders.splice(ix, 1);
    } else {
      if (
        parseInt(newOrder.totalFrequency) > 1 &&
        newOrder.detailclientorders.length < parseInt(newOrder.totalFrequency)
      ) {
        detailclientorders.push({ ...orderClientSchema });
      }
    }
    newOrderChange({
      target: { name: "detailclientorders", value: detailclientorders },
    });
  };

  const onFrequencyChange = (e, ix) => {
    const { detailclientorders } = newOrder;
    const detailclientorder = { ...detailclientorders[ix] };
    detailclientorder[e.target.name] = e.target.value;
    detailclientorders[ix] = detailclientorder;
    newOrderChange({
      target: { name: "detailclientorders", value: detailclientorders },
    });
  };

  const fetchDataDestinations = useCallback(async () => {
    const resC = await Request("/client/destinations", null, "GET");
    const resClients = await Request(`/client`);
    setDesintations(resC.destinations);
    if (resClients.ok) {
      setClients(resClients.data);
    }
  });

  const fetchClientData = async (idClient) => {
    const { ok, data } = await Request("/client/" + idClient);
    if (ok) {
      setClientData(data);
    }
  };

  const isClientAvailable = (date) => {
    const day = date.getDay();
    return parseAvailableDaysToArray(clientData).includes(day);
  };

  const fetchQuota = (ix) => async (e) => {
    onFrequencyChange(e, ix);
    const { idWarehouseOrigin, idClientDestination } = newOrder;
    const { value: idCatVehicle, name } = e.target;
    if (
      idCatVehicle !== "" &&
      idClientDestination !== "" &&
      idWarehouseOrigin !== ""
    ) {
      let res1 = await Request(
        `/boarding/getQuota?idCatVehicle=${idCatVehicle}&idClientDestination=${idClientDestination}&idWarehouseOrigin=${idWarehouseOrigin}`,
        null,
        "GET"
      );
      if (res1.ok) {
        const quotaRes = res1.data?.[0] || {};
        const { quota, idFreightQuota } = quotaRes;
        const { detailclientorders } = newOrder;
        const detailclientorder = { ...detailclientorders[ix] };
        detailclientorder.quota = quota;
        detailclientorder.idFreightQuota = idFreightQuota;
        detailclientorders[ix] = detailclientorder;
        newOrderChange({
          target: { name: "detailclientorders", value: detailclientorders },
        });
      }
    }
  };

  const fetchData = useCallback(async () => {
    if (idClientOrder) {
      const { data, ok } = await Request(
        `/clientorder/${idClientOrder}`,
        null,
        "GET"
      );
      if (ok) {
        setAllOrder({
          ...data,
          detailclientorders: data.detailclientorders.map((dco) => ({
            ...dco,
            receptionDate: new Date(santizeDateTime(dco.receptionDate)),
            uploadDate: new Date(santizeDateTime(dco.uploadDate)),
          })),
        });
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await Request(
      `/clientorder${behavior === behaviors.insert ? "" : "/" + idClientOrder}`,
      {
        ...newOrder,
        detailclientorders: newOrder.detailclientorders.map((dco) => ({
          ...dco,
          uploadDate: parseObjectDate(new Date(dco.uploadDate), 1),
          receptionDate: parseObjectDate(new Date(dco.receptionDate), 1),
        })),
      },
      behavior === behaviors.insert ? "POST" : "PUT"
    );
    setIsLoading(false);
    if (res.ok) {
      Swal.fire({
        title: `${
          behavior === behaviors.insert
            ? "Registro exitoso"
            : "Actualización exitosa"
        }`,
        icon: "success",
      }).then(() => {
        navigate("/lista-pedidos-capturados");
      });
    } else {
      console.log(res);
      Swal.fire("¡Ha ocurrido un error!", res.error, "error");
    }
  };

  useEffect(() => {
    if (idClientOrder) {
      fetchData();
    }
    console.log("fetching");
  }, [fetchData]);

  useEffect(() => {
    fetchClientData(newOrder.idClient);
  }, [newOrder.idClient]);

  useEffect(() => {
    fetchDataDestinations();
    if (idClientOrder) {
      setBehavior(behaviors.edit);
    }
  }, []);

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>
          {" "}
          {behavior === behaviors.insert
            ? "CAPTURA DE PEDIDOS"
            : "EDITAR PEDIDO"}
        </PageTitle>
      </div>
      <NtaForm onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <Select
            arrOptions={[
              { label: "Elige un origen" },
              ...parseCat(catWarehouse, "name", "idWarehouse"),
            ]}
            required={true}
            label="Origen"
            name="idWarehouseOrigin"
            onChange={newOrderChange}
            value={newOrder.idWarehouseOrigin}
          />
          <Select
            arrOptions={[
              { label: "Elige un cliente", value: "" },
              ...parseCat(
                clients?.filter((u) => u.isActive),
                "companyName",
                "idClient"
              ),
            ]}
            required={true}
            label="Cliente"
            placeholder="Seleccione cliente"
            name="idClient"
            onChange={newOrderChange}
            value={newOrder.idClient}
          />
          <Select
            required={true}
            label="Destino"
            name="idClientDestination"
            onChange={newOrderChange}
            value={newOrder.idClientDestination}
            arrOptions={[
              { label: "Elige un destino" },
              ...parseCat(
                destinations
                  .filter((d) => d.idClient === newOrder.idClient)
                  .map((d) => ({
                    v: d.idDestination,
                    l: d.clientDestination,
                  })),
                "l",
                "v"
              ),
            ]}
          />
          <Input
            type="text"
            label="Pedido"
            name="idClientOrder"
            disabled
            style={
              behavior === behaviors.insert
                ? { display: "none" }
                : { display: "block" }
            }
            onChange={newOrderChange}
            value={newOrder.idClientOrder}
          />
          <Select
            arrOptions={[
              { label: "Seleccione producto" },
              ...parseCat(products, "name", "idProduct"),
            ]}
            required={true}
            label="Producto"
            name="idProduct"
            onChange={newOrderChange}
            value={newOrder.idProduct}
          />
          <Input
            type="number"
            step={0.5}
            required={false}
            label="Kilogramos"
            name="kilogram"
            onChange={newOrderChange}
            value={newOrder.kilogram}
          />
          <Input
            type="number"
            step={0.1}
            required={false}
            label="Precio en Facturación"
            placeholder="0"
            name="invoicePrice"
            onChange={newOrderChange}
            value={newOrder.invoicePrice}
          />
          <Input
            type="number"
            required={true}
            label="Humedad %"
            min={8}
            max={clientData.permissibleHumidity || 30}
            name="humidity"
            onChange={newOrderChange}
            value={newOrder.humidity}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div className="grid grid-cols-2 gap-4">
            <Input
              type="number"
              required={false}
              label="Frecuencia"
              placeholder="0"
              name="totalFrequency"
              min={newOrder.detailclientorders.length}
              onChange={newOrderChange}
              value={newOrder.totalFrequency}
            />
            <Select
              arrOptions={[
                { label: "Seleccione un tipo", value: "" },
                { label: "Mensual", value: "MONTH" },
                { label: "Semanal", value: "WEEK" },
              ]}
              required={false}
              label="Tipo"
              placeholder="Seleccione una frecuencia"
              name="frequency"
              onChange={newOrderChange}
              value={newOrder.frequency}
            />
          </div>
          <div
            className="mx-auto w-full"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "4rem",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <ButtonIcon
                  className={
                    "text-white bg-ntaBlue hover:bg-ntaGray w-14 h-14 relative float-left place-self-start place-content-center grid"
                  }
                  icon={faPlus}
                  disabled={
                    newOrder.detailclientorders.length >=
                    newOrder.totalFrequency
                  }
                  onClick={() => {
                    manageClientOrders();
                  }}
                />
              </div>
              <label className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">
                Añadir Embarques
              </label>
            </div>
            {newOrder.detailclientorders.length > 1 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <ButtonIcon
                    className={
                      "text-white bg-ntaBlue hover:bg-ntaGray w-14 h-14 relative float-left place-self-start place-content-center grid"
                    }
                    icon={faTrash}
                    onClick={() => {
                      manageClientOrders(true);
                    }}
                  />
                </div>
                <label className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">
                  Remover Embarques
                </label>
              </div>
            )}
          </div>
        </div>

        {newOrder.detailclientorders
          .filter((w) => w.isActive || !w.idWarehouse)
          .map((w, ix) => (
            <div className="grid grid-cols-5 gap-4 w-4/5 mx-auto inputsForm">
              <Input
                type="text"
                required={true}
                label="Kilogramos en Emb."
                name="kilogram"
                onChange={(e) => {
                  onFrequencyChange(e, ix);
                }}
                value={w.kilogram}
              />
              <div className="mx-auto w-full mt-6">
                <label className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">
                  Fecha de carga
                </label>
                <DatePicker
                  className={`inputBorder placeholder-ntaGray bg-ntaInput py-3 px-4 my-2 rounded-lg w-full opacity-70 focus:ring-ntaGray focus:outline-none focus:border-ntaGray`}
                  selected={w.uploadDate}
                  required={false}
                  name="uploadDate"
                  onChange={(date) => {
                    onFrequencyChange(
                      { target: { name: "uploadDate", value: date } },
                      ix
                    );
                  }}
                  placeholderText="Fecha de Carga"
                />
              </div>
              <div className="mx-auto w-full mt-6">
                <label className="text-basic-gray text-xl labelForm p-2 font-semibold text-ntaBlue">
                  Fecha de recepción
                </label>
                <DatePicker
                  className={`inputBorder placeholder-ntaGray bg-ntaInput py-3 px-4 my-2 rounded-lg w-full opacity-70 focus:ring-ntaGray focus:outline-none focus:border-ntaGray`}
                  selected={w.receptionDate}
                  required={false}
                  name="receptionDate"
                  onChange={(date) => {
                    onFrequencyChange(
                      { target: { name: "receptionDate", value: date } },
                      ix
                    );
                  }}
                  filterDate={isClientAvailable}
                  placeholderText="Fecha de recepción"
                />
              </div>
              <Select
                arrOptions={[
                  { label: "Seleccione vehículo" },
                  ...parseCat(catVehicles, "name", "idCatVehicle"),
                ]}
                disabled={
                  newOrder.idClientDestination === "" ||
                  newOrder.idWarehouseOrigin === ""
                }
                required={true}
                label="Vehículo"
                name="idCatVehicle"
                onChange={fetchQuota(ix)}
                value={w.idCatVehicle}
              />
              <Input
                type="text"
                required={false}
                label="Cuota"
                name="quota"
                onChange={() => {}}
                disabled={true}
                value={w.quota}
              />
            </div>
          ))}
        <div className="grid grid-cols-1 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Input
              type="textarea"
              required={false}
              label="Observaciones"
              name="comments"
              onChange={newOrderChange}
              value={newOrder.comments}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div />
          <Button disabled={isLoading}>
            {behavior === behaviors.insert
              ? "CAPTURA DE PEDIDOS"
              : "EDITAR PEDIDO"}
          </Button>
        </div>
      </NtaForm>
    </>
  );
};

export default OrderCapture;
