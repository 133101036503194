export const downloadTypeEnum = [
  {
    label: "Descarga Total",
    value: "FULLDOWNLOAD",
  },
  {
    label: "Descarga Parcial",
    value: "PARTIALDOWNLOAD",
  },
  {
    label: "Carga Parcial",
    value: "PARTIALLOAD",
  },
  {
    label: "Viaje Directo",
    value: "DIRECTTRIP",
  },
];

export const loadTypeEnum = [
  {
    label: "Carga Total",
    value: "FULLLOAD",
  },
  {
    label: "Carga Parcial",
    value: "PARTIALLOAD",
  },
  {
    label: "Descarga Parcial",
    value: "PARTIALDOWNLOAD",
  },
  {
    label: "Viaje Directo",
    value: "DIRECTTRIP",
  },
];

export const vehicleTypesQuotas = {
  1: "pickup",
  2: "rabon",
  3: "tortonRedilas",
  4: "tortonVolteo",
  5: "trailer",
  6: "volteo",
};

export const statesMx = [
  { clave: "AGS", value: "AGUASCALIENTES", label: "AGUASCALIENTES" },
  { clave: "BC", value: "BAJA CALIFORNIA", label: "BAJA CALIFORNIA" },
  { clave: "BCS", value: "BAJA CALIFORNIA SUR", label: "BAJA CALIFORNIA SUR" },
  { clave: "CHI", value: "CHIHUAHUA", label: "CHIHUAHUA" },
  { clave: "CHS", value: "CHIAPAS", label: "CHIAPAS" },
  { clave: "CMP", value: "CAMPECHE", label: "CAMPECHE" },
  { clave: "CMX", value: "CIUDAD DE MEXICO", label: "CIUDAD DE MEXICO" },
  { clave: "COA", value: "COAHUILA", label: "COAHUILA" },
  { clave: "COL", value: "COLIMA", label: "COLIMA" },
  { clave: "DGO", value: "DURANGO", label: "DURANGO" },
  { clave: "GRO", value: "GUERRERO", label: "GUERRERO" },
  { clave: "GTO", value: "GUANAJUATO", label: "GUANAJUATO" },
  { clave: "HGO", value: "HIDALGO", label: "HIDALGO" },
  { clave: "JAL", value: "JALISCO", label: "JALISCO" },
  { clave: "MCH", value: "MICHOACAN", label: "MICHOACAN" },
  { clave: "MEX", value: "ESTADO DE MEXICO", label: "ESTADO DE MEXICO" },
  { clave: "MOR", value: "MORELOS", label: "MORELOS" },
  { clave: "NAY", value: "NAYARIT", label: "NAYARIT" },
  { clave: "NL", value: "NUEVO LEON", label: "NUEVO LEON" },
  { clave: "OAX", value: "OAXACA", label: "OAXACA" },
  { clave: "PUE", value: "PUEBLA", label: "PUEBLA" },
  { clave: "QR", value: "QUINTANA ROO", label: "QUINTANA ROO" },
  { clave: "QRO", value: "QUERETARO", label: "QUERETARO" },
  { clave: "SIN", value: "SINALOA", label: "SINALOA" },
  { clave: "SLP", value: "SAN LUIS POTOSI", label: "SAN LUIS POTOSI" },
  { clave: "SON", value: "SONORA", label: "SONORA" },
  { clave: "TAB", value: "TABASCO", label: "TABASCO" },
  { clave: "TLX", value: "TLAXCALA", label: "TLAXCALA" },
  { clave: "TMS", value: "TAMAULIPAS", label: "TAMAULIPAS" },
  { clave: "VER", value: "VERACRUZ", label: "VERACRUZ" },
  { clave: "YUC", value: "YUCATAN", label: "YUCATAN" },
  { clave: "ZAC", value: "ZACATECAS", label: "ZACATECAS" },
];

export const clientInvoiceTypes = [
  {
    value: "ORIGINWEIGHT",
    label: "Precio origen",
  },
  {
    value: "DESTINATIONWEIGHT",
    label: "Precio destino",
  },
];

export const weekDays = [
  {
    value: "sunday",
    label: "Domingo",
    shortLabel: "D",
  },
  {
    value: "monday",
    label: "Lunes",
    shortLabel: "L",
  },
  {
    value: "tuesday",
    label: "Martes",
    shortLabel: "M",
  },
  {
    value: "wednesday",
    label: "Miércoles",
    shortLabel: "Mi",
  },
  {
    value: "thursday",
    label: "Jueves",
    shortLabel: "J",
  },
  {
    value: "friday",
    label: "Viernes",
    shortLabel: "V",
  },
  {
    value: "saturday",
    label: "Sábado",
    shortLabel: "S",
  },
];
