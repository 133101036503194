import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getColumnsClients, getColumnsUnits } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { useForm } from "../helpers/useForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { behaviors } from "../helpers/schemas";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Input from "../components/Input";
import Select from "../components/Select";
import "../App";
import "../App.css";
import Swal from "sweetalert2";

const UnitList = () => {
  const [allData, setAllData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { id: idUnit } = useParams();
  const [behavior, setBehavior] = useState(behaviors.insert);

  const [isActive, setIsActive] = useState(true);

  const [filters, setFilters, resetFilters] = useForm({
    idCatSupplier: "all",
    idEjido: "all",
    idUnit: "all",
  });

  const fetchData = useCallback(async (filters) => {
    /*const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");*/

    const res = await Request(`/unit`, null, "GET");
    setAllData(res.data);
    const filteredData = [];
    res.data.forEach((d) => {
      if ((d.unitIsActive === 1) === isActive) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
    //setData(res.data);
  }, []);

  useEffect(() => {
    if (idUnit) {
      setBehavior(behaviors.edit);
      fetchData();
    }
  }, []);

  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields = lookupField === "all" ? ["unit", "name"] : [lookupField];

    const filteredDataActive = [];
    allData.forEach((d) => {
      if ((d.unitIsActive === 1) === isActive) {
        filteredDataActive.push(d);
      }
    });
    //setData(filteredDataActive);

    filteredDataActive.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const handleActive = (act) => {
    if (act !== isActive) {
      let filteredData = [];
      setIsActive(act);
      handleSearch("all", search);
      allData.forEach((d) => {
        if ((d.unitIsActive == 1) === act) {
          filteredData.push(d);
        }
      });
      //setData(filteredData);
      const searchLower = search.toLowerCase();
      const searchFields = ["unit", "name"];

      let filteredDataFinal = [];
      filteredData.forEach((d) => {
        let found = false;
        searchFields.forEach((k) => {
          const val = d[k] ? d[k].toLowerCase() : "";
          if (val.indexOf(searchLower) !== -1) {
            found = true;
            return;
          }
        });
        if (found) {
          filteredDataFinal.push(d);
        }
      });
      setData(filteredDataFinal);
    }
  };

  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);

  const dtFuncs = {
    details: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    edit: (row) => {
      const { idUnit } = row;
      navigate("/editar-unidad/" + idUnit);
    },
    delete: (row) => {
      const { idUnit } = row;
      Swal.fire({
        title: `¿Deseas ${row.unitIsActive === 1 ? "in" : ""}activar esta unidad?`,
        icon: "question",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request("/unit/" + idUnit, { isActive: !(row.unitIsActive === 1) }, "PATCH");
          if (res.ok) {
            await Swal.fire({ title: "Modificación exitosa", icon: "success" });
            setIsActive(true);
            fetchData();
          }
        }
      });
    },
  };

  return (
    <>
      <Menu />
      <div className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList">
        <div className="grid grid-cols-3 pt-4 containerTituloBtn inputsForm">
          <PageTitle>LISTA DE UNIDADES</PageTitle>
          <div className="text-xl text-ntaGray font-semibold mt-2 cursor-pointer">
            <h5>
              <a
                className={isActive ? "activeUnitSelected" : ""}
                onClick={() => {
                  handleActive(true);
                }}
              >
                ACTIVOS
              </a>
              |
              <a
                onClick={() => {
                  handleActive(false);
                }}
                className={!isActive ? "activeUnitSelected" : ""}
              >
                INACTIVOS
              </a>
            </h5>
          </div>
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/nueva-unidad");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/nueva-unidad");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 -mt-8 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por nombre de la unidad"
          />
          {/*<Select
            arrOptions={[
              { label: "Todos", value: "all" }, { label: "2022", value: "2022" },
            ]}
            required={true}
            name="year"
            label="Año"
          />
          <Select
            arrOptions={[
              { label: "Todas", value: "all" },
            ]}
            required={true}
            name="harvest"
            label="Cosecha"
          />*/}
          <Button
            style={{ placeSelf: "end", width: "6rem" }}
            className={"w-1/3 px-0 mt-14 mb-2 align-right text-center"}
            onClick={() => {
              fetchData();
              resetFilters();
              setSearch("");
              setIsActive(true);
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsUnits(dtFuncs)} />
      </div>
    </>
  );
};

export default UnitList;
