import React from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { customStyles } from "../helpers/datatableHelpers";
import "./NtaDataTable.css";

const NtaDataTable = ({ columnsDef, data, pagination = 10 }) => {
  return (
    <div
      className="containerTable"
      style={{ width: "100%", margin: "20px auto" }}
    >
      <DataTable
        pagination={pagination}
        columns={columnsDef}
        responsive={true}
        highlightOnHover={true}
        customStyles={customStyles}
        data={data}
        striped={true}
        noDataComponent={
          <p style={{ fontStyle: "italic", fontSize: "1.2rem" }}>
            No hay registros para mostrar
          </p>
        }
      />
      <span className="text-ntaBlue font-bold" style={{ fontSize: "1.5rem" }}>
        Total elementos: {data.length}
      </span>
    </div>
  );
};

NtaDataTable.propTypes = {
  columnsDef: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default NtaDataTable;
