import React from "react";
import PropTypes from "prop-types";

const ActiveFilter = ({ isActive, handleActive }) => {
  return (
    <div className="text-xl text-ntaGray font-semibold mt-2 cursor-pointer">
      <h5>
        <a
          className={isActive && "activeUnitSelected"}
          onClick={() => {
            handleActive(true);
          }}
        >
          ACTIVOS
        </a>
        |
        <a
          onClick={() => {
            handleActive(false);
          }}
          className={!isActive && "activeUnitSelected"}
        >
          INACTIVOS
        </a>
      </h5>
    </div>
  );
};

ActiveFilter.propTypes = {
  isActive: PropTypes.bool,
  handleActive: PropTypes.func,
};

export default ActiveFilter;
