import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountProps, parseCat } from "../helpers/utils";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { behaviors, personSchema, vehicleSchema } from "../helpers/schemas";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useForm } from "../helpers/useForm";
import { Request } from "../helpers/api";
import Input from "../components/Input";
import Button from "../components/Button";
import Select from "../components/Select";
import Menu from "../components/Menu";
import NtaForm from "../components/NtaForm";
import Swal from "sweetalert2";
import ButtonIcon from "../components/ButtonIcon";
import PageTitle from "../components/PageTitle";
import "../App";
import "../App.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { getFromSession } from "../helpers/storageHandler";
import { initialFetch } from "../redux/actions/auth";

const PersonInsert = () => {
  const catalogues = useSelector((s) => s.catalogues);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: idPerson } = useParams();
  const [behavior, setBehavior] = useState(behaviors.insert);
  const { units, ejidos, catSuppliers = [], banks, catVehicles } = catalogues;
  const [newPerson, newPersonChange, newPersonReset, setAllPerson] =
    useForm(personSchema);
  const [isLoading, setIsLoading] = useState(false);
  const insertPerson = async (e) => {
    e.preventDefault();
    const {
      roles,
      vehicles,
      idBank,
      bankAccountKey,
      bankAccountType,
      idEjido,
    } = newPerson;
    const rolesList = getRolesList(roles);
    if (!(roles[4] && idEjido === "")) {
      if (rolesList.length > 0) {
        if (
          idBank !== null &&
          (bankAccountType === "" || bankAccountKey === "")
        ) {
          Swal.fire(
            "Error",
            "Debes ingresar todos los campos requeridos antes de continuar",
            "warning"
          );
        } else {
          setIsLoading(true);
          const res = await Request(
            `/supplier${behavior === behaviors.edit ? "/" + idPerson : ""}`,
            {
              ...newPerson,
              idCatSupplier: rolesList,
              vehicles,
            },
            behavior === behaviors.insert ? "POST" : "PUT"
          );
          setIsLoading(false);
          if (res.ok) {
            await dispatch(initialFetch(getFromSession("jwt")));
            Swal.fire({
              title:
                behavior === behaviors.insert
                  ? "Registro exitoso"
                  : "Actualización exitosa",
              icon: "success",
            }).then(() => {
              behavior === behaviors.insert
                ? newPersonReset()
                : navigate("/personas");
            });
          }
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "¡Ops!",
          text: "Debes seleccionar al menos un Rol",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "¡Ops!",
        text: "El Ejido es requerido",
      });
    }
  };
  const getRolesList = (roles) => {
    return Object.keys(roles).filter((r) => roles[r]);
  };
  const handleRolesChange = (e) => {
    let roles = { ...newPerson.roles };
    roles[e.target.name] = e.target.checked;
    if (getRolesList(roles).length <= 3 || true) {
      // Saltada regla de los 3 roles
      newPersonChange({ target: { name: "roles", value: roles } });
    } else {
      Swal.fire({
        icon: "warning",
        title: "¡Ops!",
        text: "No puedes seleccionar más de tres Roles por persona",
      });
    }
  };
  const modifyVehicles = (willDetele = false, ix = null) => {
    let { vehicles } = newPerson;
    if (willDetele) {
      if (vehicles.length > 1) {
        vehicles.splice(ix, 1);
      }
    } else {
      vehicles.push({ ...vehicleSchema });
    }
    newPersonChange({
      target: { name: "vehicles", value: vehicles },
    });
  };
  const handleVehicleDataChange = (e, ix) => {
    const { vehicles } = newPerson;
    vehicles[ix][e.target.name] = e.target.value;
    newPersonChange({ target: { name: "vehicles", value: vehicles } });
  };
  const fetchData = useCallback(async () => {
    const res = await Request(`/supplier/${idPerson}`, undefined, "GET");
    if (res.ok) {
      const roles = {};
      res.data.cat_suppliers.forEach((cs) => {
        roles[cs.idCatSupplier] = true;
      });
      setAllPerson({ ...res.data, roles });
    }
  });
  useEffect(() => {
    const { roles, vehicles } = newPerson;
    if (roles[5] && vehicles.length === 0) {
      newPersonChange({
        target: { name: "vehicles", value: [{ ...vehicleSchema }] },
      });
    }
  }, [newPerson]);
  useEffect(() => {
    if (idPerson) {
      setBehavior(behaviors.edit);
      fetchData();
    }
  }, []);
  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>ALTA DE PERSONAS</PageTitle>
      </div>
      <NtaForm onSubmit={insertPerson}>
        <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto text-left mt-10 subtituloForm">
          ROLES
        </div>
        <div className="w-4/5 mx-auto mt-3">
          <FormGroup>
            <div className="grid grid-cols-4 gap-2 w-full checkRoles">
              {catSuppliers.map((c, ix) => {
                return (
                  <FormControlLabel
                    key={ix}
                    control={
                      <Checkbox
                        checked={newPerson.roles[c.idCatSupplier] || false}
                        onChange={handleRolesChange}
                        name={c.idCatSupplier}
                      />
                    }
                    label={c.name}
                  />
                );
              })}
            </div>
          </FormGroup>
        </div>
        <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto text-left mt-10 subtituloForm">
          DATOS GENERALES
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Select
              arrOptions={[
                { label: "Seleccione una unidad", value: "" },
                ...parseCat(units, "name", "idUnit"),
              ]}
              required={true}
              name="idUnit"
              onChange={newPersonChange}
              value={newPerson.idUnit}
              label="Unidad "
            />
            <Input
              type="text"
              required={true}
              label="Nombre Completo (INE)"
              placeholder="Nombre Completo"
              name="name"
              maxLength={100}
              onChange={newPersonChange}
              value={newPerson.name}
            />
            <Input
              type="phone"
              required={false}
              label="Teléfono"
              name="phone"
              maxLength={10}
              onChange={newPersonChange}
              value={newPerson.phone}
            />
          </div>
          <div>
            {newPerson.roles[4] && (
              <Select
                arrOptions={[
                  { label: "Seleccione un ejido", value: "" },
                  ...parseCat(
                    ejidos?.filter(
                      (e) => e.isActive && e.idUnit === newPerson.idUnit
                    ),
                    "name",
                    "idEjido"
                  ),
                ]}
                required={true}
                disabled={newPerson.idUnit === ""}
                name="idEjido"
                onChange={newPersonChange}
                value={newPerson.idEjido}
                label="Ejido "
              />
            )}
            <Input
              type="text"
              required={true}
              label="Número de INE"
              name="identityKey"
              maxLength={13}
              minLength={9}
              onChange={newPersonChange}
              value={newPerson.identityKey}
            />
            <Input
              type="text"
              required={true}
              label="Dirección (INE)"
              placeholder="Dirección"
              maxLength={250}
              minLength={20}
              name="address"
              onChange={newPersonChange}
              value={newPerson.address}
            />
          </div>
        </div>
        <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto text-left mt-10 subtituloForm">
          DATOS BANCARIOS
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Select
              arrOptions={[
                { label: "Seleccione un banco", value: "" },
                { label: "SIN BANCO", value: null },
                ...parseCat(banks, "name", "idBank"),
              ]}
              required={true}
              name="idBank"
              value={newPerson.idBank}
              onChange={newPersonChange}
              label="Banco"
            />
            {newPerson.idBank !== null && (
              <Input
                disabled={newPerson.bankAccountType === ""}
                required={true}
                type="text"
                value={newPerson.bankAccountKey}
                name="bankAccountKey"
                onChange={newPersonChange}
                label="Cuenta bancaria"
                {...getAccountProps(newPerson.bankAccountType)}
              />
            )}
          </div>
          {newPerson.idBank !== null && (
            <div>
              <Select
                arrOptions={[
                  { label: "Seleccione un tipo", value: "" },
                  { label: "Tarjeta de Crédito", value: "TARJETAC" },
                  { label: "Tarjeta de Débito", value: "TARJETAD" },
                  { label: "Número de cuenta", value: "CUENTA" },
                  { label: "CLABE Interbancaria", value: "CLABE" },
                ]}
                value={newPerson.bankAccountType}
                required={true}
                onChange={newPersonChange}
                name="bankAccountType"
                label="Tipo de Cuenta"
              />
            </div>
          )}
        </div>
        {newPerson.roles[5] && (
          <>
            <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto text-left mt-10 subtituloForm">
              VEHICULOS
            </div>
            <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
              {newPerson.roles[5] && (
                <div>
                  <table className="vehicles-table w-full">
                    <thead>
                      <tr>
                        <th width="45%">Tipo</th>
                        <th width="45%">Placas</th>
                        <th width="10%">
                          <ButtonIcon
                            className={"text-green-400"}
                            onClick={() => modifyVehicles()}
                            icon={faPlus}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {newPerson.vehicles.map((v, ix) => (
                        <tr key={`vehicle_${ix}`}>
                          <td>
                            <Select
                              arrOptions={[
                                ...parseCat(
                                  catVehicles.map((c) => ({
                                    ...c,
                                    name: c.name + " - " + c.description,
                                  })),
                                  "name",
                                  "idCatVehicle"
                                ),
                              ]}
                              containerClassName={"tableInput"}
                              required={true}
                              name="idCatVehicle"
                              onChange={(e) => handleVehicleDataChange(e, ix)}
                              value={v.idCatVehicle}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              required={true}
                              placeholder="XXX-XX-XX"
                              minLength="6"
                              maxLength="10"
                              name="plates"
                              containerClassName={"tableInput"}
                              onChange={(e) => handleVehicleDataChange(e, ix)}
                              value={v.plates}
                            />
                          </td>
                          <td>
                            <ButtonIcon
                              className={"color-ntaBlue"}
                              onClick={() => modifyVehicles(true, ix)}
                              icon={faTrash}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </>
        )}
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div />
          <Button disabled={isLoading} type="submit">
            Continuar
          </Button>
        </div>
      </NtaForm>
    </>
  );
};

export default PersonInsert;
