import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getColumnsPersons } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { useForm } from "../helpers/useForm";
import { parseCat } from "../helpers/utils";
import PersonDetails from "../components/modals/PersonDetails";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Select from "../components/Select";
import Input from "../components/Input";
import "../App";
import "../App.css";
import Swal from "sweetalert2";

const PersonList = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const catalogues = useSelector((s) => s.catalogues);
  const { ejidos, catSuppliers, units } = catalogues;
  const [filters, setFilters, resetFilters] = useForm({
    idCatSupplier: "all",
    idEjido: "all",
    idUnit: "all",
  });
  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/supplier?identityKey=INE&${filterStr}`, null, "GET");
    setAllData(res.data);
    setData(res.data);
  }, []);
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields = lookupField === "all" ? ["name", "ejido", "unit"] : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const dtFuncs = {
    details: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    edit: (row) => {
      navigate("/editar-persona/" + row.idSupplier);
    },
    delete: (row) => {
      const { isActive, idSupplier } = row;
      Swal.fire({
        title: `¿Deseas ${isActive ? "inactivar" : "reactivar"} a ésta Persona?`,
        icon: "question",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request(
            "/supplier/" + idSupplier,
            {
              isActive: !isActive,
            },
            "PATCH"
          );
          if (res.ok) {
            await Swal.fire({ title: "Modificación exitosa", icon: "success" });
            fetchData();
          }
        }
      });
    },
  };
  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);
  return (
    <>
      <Menu />
      <div className="grid grid-cols-1 gap-4 mx-auto mt-8 containerList" style={{ width: "90%" }}>
        <div className="grid grid-cols-2 containerTituloBtn">
          <PageTitle>LISTA DE PERSONAS</PageTitle>
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/nueva-persona");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/nueva-persona");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-5 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por nombre, unidad y ejido"
          />
          {/*<Select
            arrOptions={[{ label: "Todos", value: "all" }, ...parseCat(units, "name", "idUnit")]}
            required={true}
            name="idUnit"
            onChange={setFilters}
            value={filters.idUnit}
            label="Unidad"
          />*/}
          <Select
            arrOptions={[{ label: "Todos", value: "all" }, ...parseCat(ejidos, "name", "idEjido")]}
            required={true}
            name="idEjido"
            onChange={setFilters}
            value={filters.idEjido}
            label="Ejido"
          />
          <Select
            arrOptions={[
              { label: "Todos", value: "all" },
              ...parseCat(catSuppliers, "name", "idCatSupplier"),
            ]}
            required={true}
            name="idCatSupplier"
            onChange={setFilters}
            value={filters.idCatSupplier}
            label="Rol"
          />
          <div />
          <Button
            style={{ placeSelf: "end", width: "6rem" }}
            onClick={() => {
              fetchData();
              resetFilters();
              setSearch("");
            }}
            className={"w-1/2 px-0 mt-14 mb-2 align-right text-center"}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsPersons(dtFuncs)} />
      </div>
      <PersonDetails isOpen={modalOpen} data={singleData} toggle={setModalOpen} />
    </>
  );
};

export default PersonList;
