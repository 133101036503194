import React, { useCallback, useEffect, useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import Select from "../components/Select";
import Menu from "../components/Menu";
import { useSelector } from "react-redux";
import "../App";
import "../App.css";
import { parseCat } from "../helpers/utils";
import NtaForm from "../components/NtaForm";
import { useForm } from "../helpers/useForm";
import { Request } from "../helpers/api";
import { ballotSchema } from "../helpers/schemas";
import PageTitle from "../components/PageTitle";
import ModalBallotConfirmed from "../components/modals/BallotConfirmed";
import Swal from "sweetalert2";

const BallotGenerate = () => {
  const catalogues = useSelector((s) => s.catalogues);
  //const [warehouses, setWarehouses] = useState([]);
  const [ejidos, setEjidos] = useState([]);
  const [ejFileds, setEjFields] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const { vehicles, products, ejidos: catEjidos } = catalogues;
  const [persons, setPersons] = useState([]);
  const [newBallot, newBallotChange, newBallotReset, setAllData] =
    useForm(ballotSchema);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [method, setMethod] = useState("POST");
  const postData = async (e) => {
    const reqSelects = {
      idProduct: newBallot.idProduct,
      idContact: newBallot.idContact,
      idEjido: newBallot.idEjido,
      idWarehouse: newBallot.idWarehouse,
      idFreighter: newBallot.idFreighter,
      idVehicle: newBallot.idVehicle,
    };
    e.preventDefault();
    if (!Object.keys(reqSelects).find((k) => reqSelects[k] === "")) {
      setIsLoading(true);
      const res = await Request(
        `/ballot${method === "PUT" ? "/" + newBallot.idBallot : ""}`,
        newBallot,
        method
      );
      setIsLoading(false);
      if (res.ok) {
        setAllData({ ...newBallot, ...res.ballot });
        setModalOpen(true);
      }
    } else {
      Swal.fire(
        "Error",
        "No se han capturado todos los campos requeridos",
        "warning"
      );
    }
  };
  const fetchPersons = useCallback(async () => {
    const res = await Request(`/supplier?identityKey=INE`, null, "GET");
    if (res.ok) {
      setPersons(res.data);
    }
  }, []);
  const fetchWarehouses = async () => {
    if (newBallot.idProduct && newBallot.idProduct !== "") {
      const res = await Request(
        `/product/ejidoWarehouse?idProduct=${newBallot.idProduct}`,
        null,
        "GET"
      );
      if (res.ok) {
        setWarehouses(res.data.filter((w) => w.isActive));
        newBallotChange({
          target: { name: "idWarehouse", value: "" },
        });
        newBallotChange({
          target: { name: "idEjido", value: "" },
        });
      }
    }
  };
  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);
  useEffect(() => {
    fetchWarehouses();
  }, [newBallot.idProduct]);
  useEffect(() => {
    const { idEjido, name } = newBallot;
    const fields = catEjidos?.find((e) => e.idEjido == idEjido)
      ?.origin_fields || [{ idOriginField: "", name }];
    if (fields.length === 1) {
      newBallotChange({
        target: { name: "idOriginField", value: fields[0].idOriginField },
      });
    }
    setEjFields(fields);
  }, [newBallot.idEjido]);
  useEffect(() => {
    const warehouse = warehouses.find(
      (w) => w.idWarehouse === newBallot.idWarehouse
    );
    if (warehouse && newBallot.idWarehouse !== "") {
      const filteredEjidos = warehouse.ejidoswarehouse.filter(
        (e) => e.ejido.isActive
      );
      setEjidos(filteredEjidos);
    }
  }, [newBallot.idWarehouse]);
  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>GENERAR BOLETA</PageTitle>
      </div>
      <NtaForm onSubmit={postData}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <Select
            arrOptions={[
              { label: "Seleccione un producto", value: "" },
              ...parseCat(products, "name", "idProduct"),
            ]}
            required={true}
            name="idProduct"
            onChange={newBallotChange}
            value={newBallot.idProduct}
            label="Producto"
          />
          <Select
            arrOptions={[
              { label: "Seleccione un almacén", value: "" },
              ...parseCat(warehouses, "name", "idWarehouse"),
            ]}
            disabled={newBallot.idProduct === ""}
            required={true}
            onChange={newBallotChange}
            name="idWarehouse"
            value={newBallot.idWarehouse}
            label="Almacén"
          />
          <Select
            arrOptions={[
              { label: "Seleccione un ejido", value: "" },
              ...parseCat(ejidos, "name", "idEjido"),
            ]}
            required={true}
            disabled={newBallot.idWarehouse === ""}
            name="idEjido"
            onChange={newBallotChange}
            value={newBallot.idEjido}
            label="Ejido"
          />
          <Select
            arrOptions={[
              //{ label: "Seleccione un campo", value: "" },
              ...parseCat(ejFileds, "name", "idOriginField"),
            ]}
            required={true}
            disabled={newBallot.idWarehouse === ""}
            name="idOriginField"
            onChange={newBallotChange}
            value={newBallot.idOriginField}
            label="Campo"
          />
          <Select
            arrOptions={[
              { label: "Seleccione una Placa", value: "" },
              ...parseCat(vehicles, "plates", "idVehicle"),
            ]}
            required={true}
            name="idVehicle"
            onChange={newBallotChange}
            value={newBallot.idVehicle}
            label="Placa"
          />
          <Select
            arrOptions={[
              { label: "Seleccione un Contacto", value: "" },
              ...parseCat(
                persons
                  .filter((p) => {
                    const catSuppliers = p.cat_suppliers.map(
                      (r) => r.idCatSupplier
                    );
                    return !(
                      catSuppliers.includes(7) || catSuppliers.includes[5]
                    );
                  })
                  .map((p) => ({
                    ...p,
                    namePhone: `${p.name} - ${p.phone}`,
                  })),
                "namePhone",
                "idSupplier"
              ),
            ]}
            required={true}
            name="idContact"
            onChange={newBallotChange}
            value={newBallot.idContact}
            label="Contacto"
          />
          <Select
            arrOptions={[
              { label: "Seleccione un Chofer", value: "" },
              ...parseCat(
                persons.filter((p) =>
                  p.cat_suppliers.map((r) => r.idCatSupplier).includes(7)
                ),
                "name",
                "idSupplier"
              ),
            ]}
            required={true}
            name="idFreighter"
            onChange={newBallotChange}
            value={newBallot.idFreighter}
            label="Chofer"
          />
          <Input
            type="number"
            required={true}
            min={1}
            step={0.1}
            label="Peso tara (Kg)"
            placeholder=""
            name="tara"
            onChange={newBallotChange}
            value={newBallot.tara}
          />
          <Input
            type="text"
            required={true}
            maxLength={50}
            label="Ticket"
            placeholder=""
            name="ticket"
            onChange={newBallotChange}
            value={newBallot.ticket}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div />
          <Button disabled={isLoading}>Generar Boleta</Button>
        </div>
      </NtaForm>
      <ModalBallotConfirmed
        behavior={method}
        isOpen={modalOpen}
        data={newBallot}
        toggle={setModalOpen}
        onCancel={() => {
          setMethod("PUT");
          setModalOpen(false);
        }}
        onConfirm={() => {
          newBallotReset();
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default BallotGenerate;
