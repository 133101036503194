import React, { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import Input from "../components/Input";
import Button from "../components/Button";
import Select from "../components/Select";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import NtaForm from "../components/NtaForm";
import { useDispatch, useSelector } from "react-redux";
import { getAccountProps, parseCat } from "../helpers/utils";
import { useForm } from "../helpers/useForm";
import { Request } from "../helpers/api";
import { behaviors, supplierSchema } from "../helpers/schemas";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import "../App";
import "../App.css";
import { initialFetch } from "../redux/actions/auth";
import { getFromSession } from "../helpers/storageHandler";

const SupplierInsert = () => {
  const navigate = useNavigate();
  const catalogues = useSelector((s) => s.catalogues);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { id: idSupplier } = useParams();
  const [behavior, setBehavior] = useState(behaviors.insert);
  const { units, ejidos, banks } = catalogues;
  const [newPerson, newPersonChange, newPersonReset, setAllPerson] =
    useForm(supplierSchema);
  const insertPerson = async (e) => {
    e.preventDefault();
    if (newPerson.bankAccountType === "" || newPerson.bankAccountKey === "") {
      Swal.fire(
        "Error",
        "Debes ingresar todos los campos requeridos antes de continuar",
        "warning"
      );
    } else {
      setIsLoading(true);
      const res = await Request(
        `/supplier${behavior === behaviors.edit ? "/" + idSupplier : ""}`,
        newPerson,
        behavior === behaviors.insert ? "POST" : "PUT"
      );
      setIsLoading(false);
      if (res.ok) {
        await dispatch(initialFetch(getFromSession("jwt")));
        Swal.fire({
          title:
            behavior === behaviors.insert
              ? "Registro exitoso"
              : "Actualización exitosa",
          icon: "success",
        }).then(() => {
          behavior === behaviors.insert
            ? newPersonReset()
            : navigate("/proveedores");
        });
      }
    }
  };
  const fetchData = useCallback(async () => {
    const res = await Request(`/supplier/${idSupplier}`, undefined, "GET");
    if (res.ok) {
      setAllPerson(res.data);
    }
  });
  useEffect(() => {
    if (idSupplier) {
      setBehavior(behaviors.edit);
      fetchData();
    }
  }, []);
  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>ALTA DE PROVEEDORES</PageTitle>
      </div>
      <NtaForm onSubmit={insertPerson}>
        <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto text-left mt-10 subtituloForm">
          DATOS GENERALES
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto  inputsForm">
          <div>
            <Select
              arrOptions={[
                { label: "Seleccione un rol", value: "" },
                { label: "Emisor", value: "EMITTER" },
                { label: "No Emisor", value: "NONEMITTER" },
              ]}
              required={true}
              name="roleSupplier"
              onChange={newPersonChange}
              value={newPerson.roleSupplier}
              label="Tipo de proveedor"
            />
            <Select
              arrOptions={[
                { label: "Seleccione un ejido", value: "" },
                { label: "No aplica", value: "1" },
                ...parseCat(
                  ejidos?.filter(
                    (e) =>
                      e.isActive &&
                      (e.idUnit === newPerson.idUnit || e.idEjido == 1)
                  ),
                  "name",
                  "idEjido"
                ),
              ]}
              required={true}
              disabled={newPerson.idUnit === ""}
              name="idEjido"
              onChange={newPersonChange}
              value={newPerson.idEjido}
              label="Ejido"
            />
            <Input
              type="text"
              required={true}
              label="RFC"
              placeholder="RFC"
              maxLength="13"
              minLength="12"
              name="identityKey"
              onChange={newPersonChange}
              value={newPerson.identityKey}
            />
            <Input
              type="tel"
              required={true}
              label="Teléfono"
              name="phone"
              maxLength={10}
              minLength={10}
              onChange={newPersonChange}
              value={newPerson.phone}
            />
            <Input
              type="number"
              required={true}
              label="Código postal"
              name="postalCode"
              maxLength={5}
              minLength={5}
              onChange={newPersonChange}
              value={newPerson.postalCode}
            />
          </div>
          <div>
            <Select
              arrOptions={[
                { label: "Seleccione una unidad", value: "" },
                ...parseCat(units, "name", "idUnit"),
              ]}
              required={true}
              name="idUnit"
              onChange={newPersonChange}
              value={newPerson.idUnit}
              label="Unidad"
            />

            <Input
              type="text"
              required={true}
              label="Nombre o Razón social"
              name="name"
              maxLength={100}
              onChange={newPersonChange}
              value={newPerson.name}
            />
            <Input
              type="text"
              required={true}
              label="Dirección (RFC)"
              placeholder="Av Gral 123"
              name="address"
              maxLength={250}
              minLength={20}
              onChange={newPersonChange}
              value={newPerson.address}
            />
            <Input
              type="number"
              required={true}
              label="Monto máximo a facturar"
              placeholder="Sin límite"
              step={0.1}
              min={0}
              name="defBillingLimit"
              onChange={newPersonChange}
              value={newPerson.defBillingLimit}
            />
          </div>
        </div>
        <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto text-left mt-10 subtituloForm">
          DATOS BANCARIOS
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div>
            <Select
              arrOptions={[
                { label: "Seleccione un banco", value: "" },
                ...parseCat(banks, "name", "idBank"),
              ]}
              required={true}
              name="idBank"
              value={newPerson.idBank}
              onChange={newPersonChange}
              label="Banco"
            />
            <Input
              disabled={newPerson.bankAccountType === ""}
              required={true}
              type="text"
              value={newPerson.bankAccountKey}
              name="bankAccountKey"
              onChange={newPersonChange}
              label="Dato bancario"
              {...getAccountProps(newPerson.bankAccountType)}
            />
          </div>
          <div>
            <Select
              arrOptions={[
                { label: "Seleccione un tipo", value: "" },
                { label: "Tarjeta de Crédito", value: "TARJETAC" },
                { label: "Tarjeta de Débito", value: "TARJETAD" },
                { label: "Número de cuenta", value: "CUENTA" },
                { label: "CLABE Interbancaria", value: "CLABE" },
              ]}
              value={newPerson.bankAccountType}
              required={true}
              onChange={newPersonChange}
              name="bankAccountType"
              label="Tipo de dato bancario"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <div />
          <Button disabled={isLoading}>Continuar</Button>
        </div>
      </NtaForm>
    </>
  );
};

export default SupplierInsert;
