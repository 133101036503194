import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getColumnsSeasons } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { useForm } from "../helpers/useForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Input from "../components/Input";
import "../App";
import "../App.css";
import { TODAY } from "../helpers/utils";
import SeasonDetails from "../components/modals/SeasonDetails";

const SeasonList = () => {
  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { id: idSeason } = useParams();

  const [filters, setFilters, resetFilters] = useForm({
    startDate: TODAY,
    endDate: TODAY,
  });

  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/unit/season?${filterStr}`, null, "GET");
    setAllData(res.data);
    const filteredData = res.data;
    setData(filteredData);
  }, []);

  useEffect(() => {
    if (idSeason) {
      fetchData();
    }
  }, []);

  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields = lookupField === "all" ? ["name", "startDate", "endDate"] : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);

  const dtFuncs = {
    details: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    edit: (row) => {
      const { idSeason, idUnit } = row;
      navigate("/temporalidad/" + idUnit + "-" + idSeason);
    },
  };

  return (
    <>
      <Menu />
      <div className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList" style={{ width: "90%" }}>
        <div className="grid grid-cols-2 pt-4">
          <PageTitle>LISTA DE TEMPORALIDADES</PageTitle>
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/temporalidad");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Nueva temporalidad
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/temporalidad");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 -mt-8 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por nombre de la unidad"
          />
          <Input type="date" required={true} label="Fecha inicio" name="startDate" onChange={setFilters} value={filters.startDate} />
          <Input type="date" required={true} label="Fecha fin" name="endDate" onChange={setFilters} value={filters.endDate} />
          <Button
            style={{ placeSelf: "end", width: "6rem" }}
            className={"w-1/3 px-0 mt-14 mb-2 align-right text-center"}
            onClick={() => {
              fetchData();
              resetFilters();
              setSearch("");
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsSeasons(dtFuncs)} />
        <SeasonDetails
          isOpen={modalOpen}
          toggle={() => {
            setModalOpen(!modalOpen);
          }}
          data={singleData}
        />
      </div>
    </>
  );
};

export default SeasonList;
