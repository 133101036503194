import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getBallotStatusColor, parseObjectDate } from "../../helpers/utils";
import Button from "../Button";
import ButtonIcon from "../ButtonIcon";
import noop from "../../helpers/noop";
import PageTitle from "../PageTitle";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ProducerBallotCard from "../ProducerBallotCard";
import { getColumnsInvoiceDetails } from "../../helpers/datatableHelpers";
import Swal from "sweetalert2";
import NtaDataTable from "../NtaDataTable";
import NtaForm from "../NtaForm";
import Select from "../../components/Select";

const ReassingIssuers = ({ isOpen, data, toggle, onConfirm = noop, onCancel = noop }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>
        <PageTitle>REASIGNACIÓN DE EMISOR</PageTitle>
      </ModalHeader>
      <ModalBody style={{ padding: "2rem" }}>
        <div className="text-ntaBlue text-3xl">
          <span className="font-bold">BOLETA:</span> P01T02L01 <span className="font-bold">EJIDO:</span> Jonacatepec
        </div>
        <div className="text-ntaBlue text-3xl">
          <span className="font-bold">PRODUCTOR:</span> Jean Padilla V.
        </div>
        <div className="text-ntaBlue text-3xl">
          <span className="font-bold">EMISOR: Ingrid Braun M.</span>
        </div>
        <div className="text-2xl" style={{ color: "#58AE30" }}>
          <span className="font-bold">SALDO DISPONIBLE:</span> 17,000.00 {/*Pintar de Rojo cuando sea 5,000.00 o menos */}
        </div>
        <NtaForm>
          <div className="grid grid-cols-2 gap-4 inputsForm">
            <Select required={true} name="idEmisor" label="Emisores Disponibles" />{" "}
            {/*Primero se muestra el listado de emisores pertenecientes al mismo ejido y campo. Puede cambiar a todos los emisores existentes */}
            <div style={{ alignSelf: "center", justifySelf: "right", textDecoration: "underline", cursor: "pointer" }} className="text-ntaBlue text-2xl">
              Listar todos los emisores
            </div>
          </div>
        </NtaForm>
        <div className="text-ntaBlue text-4xl mt-5">
          <span className="font-bold">NUEVO EMISOR: Fernando Rivera L.</span>
        </div>
        <div className="text-3xl" style={{ color: "#58AE30" }}>
          <span className="font-bold">NUEVO SALDO: 70,000.00.</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 w-full btnModal">
          <Button
            style={{ width: "10rem" }}
            className={"bg-ntaGray text-lg"}
            onClick={() => {
              toggle(false);
            }}
          >
            Cancelar
          </Button>
          <div />
          <Button
            style={{ width: "12rem", justifySelf: "right" }}
            onClick={() => {
              toggle(false);
            }}
          >
            Reasignar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ReassingIssuers.propTypes = {};

export default ReassingIssuers;
