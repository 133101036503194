import React from "react";
import Input from "../Input";
import { useForm } from "../../helpers/useForm";
import { unitSchema } from "../../helpers/schemas";
import Select from "../Select";
import { useSelector } from "react-redux";
import { parseCat } from "../../helpers/utils";
import ButtonIcon from "../ButtonIcon";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const ProductInsertContent = ({ data, wIx, productChange, manageProduct, allProducts }) => {
  const { products } = useSelector((s) => s.catalogues);
  return (
    <div className="w-11/12 mx-auto bg-ntaBlue backgroundInputProducts">
      <div className="grid grid-cols-2 gap-4 inputsForm">
        <Select
          arrOptions={[
            { label: "Seleccione un producto", value: "" },
            ...parseCat(
              products.filter((p) => !allProducts.includes(p.idProduct) || p.idProduct === data.idProduct),
              "name",
              "idProduct"
            ),
          ]}
          required={true}
          name="idProduct"
          onChange={(e) => {
            productChange(e, data.ix, wIx, products);
          }}
          value={data.idProduct}
          label="Producto"
        />
      </div>
      <div className="grid grid-cols-3 gap-x-6 gap-y-0 inputProductsContainerPt2 inputsForm">
        <Input
          type="number"
          required={true}
          label="Humedad permitida"
          placeholder="Humedad permitida"
          name="permissibleHumidity"
          onChange={(e) => {
            productChange(e, data.ix, wIx, products);
          }}
          value={data.permissibleHumidity}
          className="grid"
        />
        <Input
          type="text"
          required={false}
          label="Impurezas"
          placeholder="Impurezas"
          name="impurities"
          onChange={(e) => {
            productChange(e, data.ix, wIx, products);
          }}
          value={data.impurities}
          className="grid"
        />
        <Input
          type="text"
          required={false}
          label="Grano fogueado"
          placeholder="Grano fogueado"
          name="firedGrain"
          onChange={(e) => {
            productChange(e, data.ix, wIx, products);
          }}
          value={data.firedGrain}
          className="grid"
        />
        <Input
          type="text"
          required={false}
          label="Densidad"
          placeholder="Densidad"
          name="density"
          onChange={(e) => {
            productChange(e, data.ix, wIx, products);
          }}
          value={data.density}
          className="grid -mt-2"
        />
        <Input
          type="text"
          required={false}
          label="Quebrado"
          placeholder="Quebrado"
          name="broken"
          onChange={(e) => {
            productChange(e, data.ix, wIx, products);
          }}
          value={data.broken}
          className="grid"
        />
        <div className="grid grid-cols-3">
          <div />
          <div />
          <ButtonIcon
            onClick={() => {
              manageProduct(true, data.ix);
            }}
            icon={faTrashCan}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductInsertContent;
