import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { parseCat, TODAY } from "../../helpers/utils";
import Button from "../Button";
import noop from "../../helpers/noop";
import PageTitle from "../PageTitle";
import { getColumnsSeasonDetails } from "../../helpers/datatableHelpers";
import NtaSelect from "../Select";
import { useForm } from "../../helpers/useForm";
import NtaDataTable from "../NtaDataTable";

const SeasonDetails = ({
  isOpen,
  data,
  toggle,
  onConfirm = noop,
  onCancel = noop,
}) => {
  const [seasonOne, setSeasonOne] = useState([]);
  const [seasonTwo, setSeasonTwo] = useState([]);
  const [filters, setFilters] = useForm({});
  const fetchData = useEffect(() => {}, [filters.idSeasonOne]);
  useEffect(() => {}, [filters.idSeasonTwo]);
  const units = [];
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>
        <div className="text-left ml-4 mt-3">
          <PageTitle>DETALLE DE TEMPORALIDAD</PageTitle>
          <h3 className="text-2xl text-left text-ntaBlue mt-2 mb-0 font-bold">
            UNIDAD: <span className="font-normal">{data.unit}</span>
          </h3>
          <h3 className="text-2xl text-left text-ntaBlue mt-2 mb-0 font-bold">
            FECHA DE CONSULTA: <span className="font-normal">{TODAY}</span>
          </h3>
        </div>
      </ModalHeader>
      <ModalBody className="modalKardexBody -mt-4 ml-4">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <span>Temporada 1</span>
            <br />
            <span>
              {TODAY} - {TODAY}
            </span>
          </div>
          <div />
          <NtaSelect
            arrOptions={[...parseCat(units, "name", "idUnit")]}
            required={false}
            name="idSeasonOne"
            onChange={setFilters}
            value={filters.idSeasonOne}
            label="Unidad"
          />
        </div>
        <div className="w-full mt-8">
          <h4 className="text-xl text-ntaBlue font-bold my-2">Entradas</h4>
          <NtaDataTable
            data={data.entries}
            columnsDef={getColumnsSeasonDetails()}
            className="mx-auto"
          />
        </div>
        <br />
        <div className="grid grid-cols-3 gap-4">
          <div>
            <span>Temporada 2</span>
            <br />
            <span>
              {TODAY} - {TODAY}
            </span>
          </div>
          <div />
          <NtaSelect
            arrOptions={[...parseCat(units, "name", "idUnit")]}
            required={false}
            name="idSeasonTwo"
            onChange={setFilters}
            value={filters.idSeasonTwo}
            label="Unidad"
          />
        </div>
        <div className="w-full mt-8">
          <h4 className="text-xl text-ntaBlue font-bold my-2">Salidas</h4>
          <NtaDataTable
            data={data.exits}
            columnsDef={getColumnsSeasonDetails()}
            className="mx-auto"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="grid grid-cols-3 w-full btnModal">
          <div />
          <div />
          <Button
            onClick={() => {
              toggle(false);
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

SeasonDetails.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SeasonDetails;
