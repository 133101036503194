import React from "react";
import PropTypes from "prop-types";

const PageTitle = ({ children, className = "" }) => {
  return (
    <span className={`text-ntaBlue text-4xl font-bold ${className} tituloPantallas`}>
      {children}
    </span>
  );
};

PageTitle.propTypes = {};

export default PageTitle;
