import { parseObjectDate, TODAY } from "./utils";

export const initialPlatformSchema = {
  idUnit: "",
  idSeason: "",
};

export const behaviors = {
  insert: "INSERT",
  edit: "EDIT",
};

export const vehicleSchema = {
  idCatVehicle: "",
  plates: "",
};

export const personSchema = {
  roles: {},
  address: "",
  idUnit: "",
  idEjido: "",
  idVehicle: "",
  idBank: "",
  name: "",
  identityType: "INE",
  identityKey: "",
  phone: "",
  bankAccountKey: "",
  bankAccountType: "",
  accountType: "",
  vehicles: [],
  defBillingLimit: "",
};

export const clientSchema = {
  address: "",
  contactName: "",
  companyName: "",
  creditLimit: 0,
  email: "",
  phone: "",
  rfc: "",
  address: "",
  zipCode: "",
  state: "",
  suburb: "",
  permissibleHumidity: 8,
  invoiceType: "ORIGINPRICE",
  availableMonday: true,
  availableTuesday: true,
  availableWednesday: true,
  availableThursday: true,
  availableFriday: true,
  availableSaturday: true,
  availableSunday: true,
};

export const supplierSchema = {
  ...personSchema,
  identityType: "RFC",
  roleSupplier: "",
  idEjido: "",
};

export const producerTicketSchema = {
  idProducer: "",
  ticketNo: "",
  recivedHumidity: "",
  tara: "",
  gross: "",
  net: "",
  hopper: "",
  hopperDen: "",
  hopperNum: "",
  hopperInt: "",
  additionalWeight: 0,
  price: "",
  loadingDate: parseObjectDate(new Date(), 4),
  byHopper: true,
  byGross: true,
};

export const ballotSchema = {
  idBallot: "",
  idProduct: "",
  idContact: "",
  idEjido: "",
  idOriginField: "",
  idVehicle: "",
  idFreighter: "",
  idCatBallotStatus: "",
  idComissionAgent: "",
  idThresher: "",
  idTicketCollector: "",
  idWarehouse: "",
  ticket: "",
  tara: "",
  gross: "",
  net: "",
  producers: [],
  receptionDate: "",
  excess: "",
  recivedHumidity: "",
  treshedSurface: "",
  comments: "",
  freightDifference: false,
  valueFreightDifference: "",
  scales: "",
  departures: "",
  boardingDate: TODAY,
};

export const productWarehouseSchema = {
  idProduct: "",
  permissibleHumidity: "",
  impurities: "",
  firedGrain: "",
  broken: "",
  density: "",
};

export const warehouseSchema = {
  name: "",
  products: [],
};

export const unitSchema = {
  name: "",
  startDateSeason: "",
  endDateSesason: "",
  warehouses: [],
};

export const ejidoSchema = {
  idUnit: "",
  name: "",
  warehouses: [],
  origin_fields: [],
};

export const ejidoWarehouseSchema = {
  idWarehouse: "",
};

export const productSchema = {
  name: "",
  code: "",
};

export const receptionScaleSchema = {
  boardingDate: "",
  ticket: "",
  tara: "",
  gross: "",
  net: "",
};

export const receptionSchema = {
  idUnit: "",
  idWarehouse: "",
  idProduct: "",
  idSupplier: "",
  weight: "",
  price: "",
  invoiceNumber: "",
  permissibleHumidity: "",
  density: "",
  impurities: "",
  broken: "",
  firedGrain: "",
  comments: "",
  scales: [{ ...receptionScaleSchema }],
  //finalPrice: "",
};

export const destinationItemSchema = {
  idDestination: "",
  clientDestination: "",
};

export const destinationSchema = {
  idClient: "",
  rfc: "",
  destinations: [],
};

export const freightQuotaSchema = {
  idEjidoOrigin: "",
  idWarehouseOrigin: "",
  idOrigin: "", // PIVOT FIELD
  idOriginField: "",
  originType: "",
  idDestination: "",
  originField: "",
  applyRetroactive: "",
  pickup: 0,
  volteo: 0,
  rabon: 0,
  tortonVolteo: 0,
  tortonRedilas: 0,
  trailer: 0,
  additionalQuota: 0,
};

export const calculateFreightSchema = {
  idOrigin: "",
  idDestination: "",
};

export const originFieldSchema = {
  name: "",
  idOriginField: "",
};

export const seasonSchema = {
  idUnit: "",
  startDate: "",
  endDate: "",
  ejidos: false,
  suppliers: false,
  warehouses: false,
  numberBallots: false,
  numberReceptions: false,
  numberBoardings: false,
};

export const freightSchema = {
  boardingDate: "",
  idCatVehicle: "",
  idUnit: "",
  idProduct: "",
  idWarehouse: "",
  idOrigin: "",
  idEjidoOrigin: "",
  idWarehouseOrigin: "",
  originType: "",
  destinationType: "",
  idDestination: "",
  uploadDate: "",
  uploadType: "",
  contactPhone: "",
  contactName: "",
  state: "",
  rfc: "",
  postalCode: "",
  idVehicle: "",
  idFreighter: "",
  idFreightQuotaOne: "",
  quotaOne: "",
  idFreightQuotaTwo: "",
  quotaTwo: "",
  invoice: "",
  humidity: "",
  density: "",
  impurities: "",
  broken: "",
  firedGrain: "",
  color: "",
  smell: "",
  volumetricWeight: "",
  aflatoxinas: "",
  certificateSigningManager: "",
  comments: "",
  destinationWeightTicket: "",
  destinationWeightTara: "",
  destinationWeightNet: "",
  destinationWeightGross: "",
  boardingTickets: [],
  idCatBoardingStatus: "",
  isDestinationWarehouse: false,
  // PROPS FOR CONNECTING CLIENT ORDERS
  applyClientOrder: false,
  idDetailClientOrder: "",
};

export const boardingTicketSchema = {
  applyBallot: true,
  idBallot: "",
  ticket: "",
  gross: "",
  tara: "",
  net: "",
};

export const orderSchema = {
  idClientOrder: "",
  idWarehouseOrigin: "",
  idClientDestination: "",
  idClient: "",
  idProduct: "",
  idDestination: "",
  kilogram: "",
  invoicePrice: "",
  humidity: "",
  frequency: "MONTH",
  totalFrequency: 1,
  comments: "",
  detailclientorders: [
    {
      uploadDate: "",
      receptionDate: "",
      kilogram: "",
      idCatVehicle: "",
      idFreightQuota: "",
      quota: "",
    },
  ],
};

export const orderClientSchema = {
  uploadDate: "",
  receptionDate: "",
  kilogram: "",
  quota: "",
  idCatVehicle: "",
  idFreightQuota: "",
  idDetailClientOrder: "",
};

export const assignQuotaPersonSchema = {
  idCatSupplier: "",
  idEjido: "",
  idOriginField: "",
  idSupplier: "",
  idProduct: "",
  quota: "",
  applyRetroactive: false,
  applyBy: "",
  oneTwoBallotQuota: "",
  threeBallotQuota: "",
  fourBallotQuota: "",
};
