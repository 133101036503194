import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getColumnsFreightQuotas } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { freightQuotaSchema } from "../helpers/schemas";
import {
  parseOrigin,
  parseDestination,
  parseReverseOrigin,
  parseReverseDestination,
} from "../helpers/utils";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Input from "../components/Input";
import Swal from "sweetalert2";
import "../App";
import "../App.css";
import "./FreightQuotaInsert.css";

const FreughtQuotationInsert = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [ejidos, setEjidos] = useState([]);
  const [destinations, setDesintations] = useState([]);
  const [search, setSearch] = useState("");
  const catalogues = useSelector((s) => s.catalogues);
  const handleNewQuota = () => {
    const newData = [...data];
    newData.unshift({ ...freightQuotaSchema });
    setData(newData);
  };
  const hadleQuotaChange = (e, ix) => {
    const { name, value } = e.target;
    console.log(e.target, ix);
    const newData = [...data];
    const row = newData[ix];
    row[name] = value;
    newData[ix] = row;
    setData(newData);
  };
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields =
      lookupField === "all"
        ? ["ejidoOriginName", "warehouseDestinationName", "warehouseOriginName"]
        : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const fetchData = useCallback(async () => {
    const res = await Request(`/localfreight/freightquota`, null, "GET");
    const resC = await Request("/client/destinations", null, "GET");
    const resE = await Request(`/ejido`, null, "GET");
    const trasnformedData = res.data.map((d) => ({
      ...d,
      ...parseReverseOrigin(d),
      ...parseReverseDestination(d),
    }));
    setData(trasnformedData);
    setAllData(trasnformedData);
    setDesintations(resC.destinations);
    if (resE.ok) {
      setEjidos(resE.data.filter((e) => e.isActive));
    }
  }, []);
  const submitData = async (row) => {
    const idUpdate = row.idFreightQuota;
    const res = await Request(
      `/localfreight/freightquota${idUpdate ? "/" + idUpdate : ""}`,
      { ...row, ...parseOrigin(row), ...parseDestination(row) },
      idUpdate ? "PUT" : "POST"
    );
    if (res.ok) {
      Swal.fire(
        `${idUpdate ? "Registro exitoso" : "Actualización exitosa"}`,
        "",
        "success"
      ).then(() => {
        fetchData();
      });
    }
  };
  const funcs = {
    save: (row) => {
      const hasMissing = ["idOrigin", "idDestination"].find(
        (k) => row[k] === ""
      );
      if (!hasMissing) {
        Swal.fire({
          title: "¿Deseas guardar los datos?",
          text: "",
          icon: "info",
          showCancelButton: true,
        }).then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            await submitData(row);
          }
        });
      } else {
        Swal.fire(
          "Debes llenar todos los campos antes de continuar",
          "",
          "error"
        );
      }
    },
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      <Menu />
      <div
        className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList freightQuotaInsert"
        style={{ width: "90%" }}
      >
        <div className="grid grid-cols-2">
          <PageTitle>ALTA DE CUOTAS PARA FLETES</PageTitle>
        </div>
        <div className="grid grid-cols-4 gap-5 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por origen o destino"
          />
          <div />
          <div />
          <Button
            disabled={data.find((d) => !d.idFreightQuota)}
            onClick={handleNewQuota}
          >
            Añadir
          </Button>
        </div>
        <NtaDataTable
          data={data}
          pagination={false}
          columnsDef={getColumnsFreightQuotas(funcs, {
            onChange: hadleQuotaChange,
            catalogues: { ...catalogues, destinations },
          })}
        />
      </div>
    </>
  );
};

export default FreughtQuotationInsert;
