import React, { useCallback, useEffect, useState } from "react";
import { parseCat, parseObjectDate, TODAY } from "../helpers/utils";
import { getColumnsReceptions } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Input from "../components/Input";
import Select from "../components/Select";
import { useSelector } from "react-redux";
import { useForm } from "../helpers/useForm";
import "../App";
import "../App.css";

const ReceptionList = () => {
  const { catWarehouse } = useSelector((s) => s.catalogues);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const navigate  = useNavigate();
  const [filters, setFilters, resetFilters] = useForm({
    idUnit: "all",
    idProduct: "all",
    idWarehouse: "all",
    startDate: TODAY,
    endDate: TODAY,
  });
  let { products, warehouses } = useSelector((s) => s.catalogues);
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const filteredData = [];
    const searchLower = searchValue.toLowerCase();
    const searchFields =
      lookupField === "all" ? ["supplier", "unit"] : [lookupField];

    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };

  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/entrie/receptionwithoutcontract?${filterStr}`, null, "GET");
    setAllData(res.data);
    setData(res.data);
  }, []);

  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);

  const dtFuncs = {
    details: (row) => {
        navigate("/editar-recepcion/" + row.idReceptionWithoutContract);
    },
  };

  return (
    <>
      <Menu />
      <div className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8">
        <div className="grid grid-cols-2">
          <PageTitle>LISTA DE RECEPCIONES</PageTitle>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por unidad o proveedor"
          />
          <Select
            arrOptions={[{ label: "Todos", value: "all" }, ...parseCat(catWarehouse, "name", "idWarehouse")]}
            required={false}
            name="idWarehouse"
            onChange={setFilters}
            value={filters.idWarehouse}
            label="Almacén"
          />
          <Select
            arrOptions={[
              { value: "all", label: "Todos" },
              ...parseCat(products, "name", "idProduct"),
            ]}
            required={false}
            name="idProduct"
            onChange={setFilters}
            value={filters.idProduct}
            label="Producto"
          />
          <Button
            className={"w-1/3 px-0 mt-14 mb-2 mx-auto text-center"}
            onClick={() => {
              fetchData();
              resetFilters();
              setSearch("");
            }}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsReceptions(dtFuncs)} className="text-lg" />
      </div>
    </>
  );
};

export default ReceptionList;
