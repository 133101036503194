import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import {
  boardingTicketSchema,
  behaviors,
  freightSchema,
} from "../helpers/schemas";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getNet,
  parseCat,
  parseDestination,
  parseObjectDate,
  parseOrigin,
  parseReverseDestination,
  parseReverseOrigin,
  parseInputDate,
  TODAY,
} from "../helpers/utils";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import { Request } from "../helpers/api";
import { useSelector } from "react-redux";
import QualityCertificate from "../components/modals/QualityCertificate";
import FreightPrinter from "../components/modals/FreightPrinter";
import "../App";
import "../App.css";
import { loadTypeEnum, statesMx } from "../helpers/enums";
import { Checkbox, FormControlLabel } from "@mui/material";

const FreightInsert = () => {
  const [newFreight, newFreightChange, newFreightReset, setAllFreight] =
    useForm(freightSchema);
  const { id: idBoarding } = useParams();
  const navigate = useNavigate();
  const [destinations, setDestintations] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [ballots, setBallots] = useState([]);
  const [ballotsAll, setBallotsAll] = useState([]);
  const [persons, setPersons] = useState([]);
  const [clients, setClients] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPrint, setModalPrint] = useState(false);
  //const catalogues = useSelector((s) => s.catalogues);
  const [fetchWCount, setFetchWCount] = useState(0);
  const {
    catWarehouse = [],
    ejidos = [],
    catVehicles = [],
    units = [],
    products = [],
    vehicles = [],
  } = useSelector((s) => s.catalogues);
  const [isLoading, setIsLoading] = useState(false);
  const [behavior, setBehavior] = useState(behaviors.insert);
  const { state: orderData } = useLocation();

  const fetchData = useCallback(async () => {
    const resC = await Request("/client/destinations", null, "GET");
    const resPersons = await Request(`/supplier?identityKey=INE`, null, "GET");
    const resClients = await Request(`/client`);
    setDestintations(resC.destinations);
    if (resPersons.ok) {
      setPersons(resPersons.data);
    }
    if (resClients.ok) {
      setClients(resClients.data);
    }
    fetchAllBallots();
  }, []);

  const fetchInfo = useCallback(async () => {
    const res = await Request(`/boarding/${idBoarding}`, undefined, "GET");
    const { data, ok } = res;
    if (ok) {
      const { boardingDate: bD, uploadDate: uD } = data;
      const boardingDate = bD
        ? parseObjectDate(new Date(bD.replace("Z", "")), 4)
        : "";
      const uploadDate = uD
        ? parseObjectDate(new Date(uD.replace("Z", "")), 4)
        : TODAY;
      await fetchAllBallots();
      await fetchQuota();
      setAllFreight({
        ...data,
        ...parseReverseOrigin(data),
        ...parseReverseDestination(data),
        boardingDate,
        uploadDate,
      });
      handleUploadChange();
      handleClientChange();
    }
  });

  const fetchWarehouses = async () => {
    if (newFreight.idProduct && newFreight.idProduct !== "") {
      const res = await Request(
        `/product/ejidoWarehouse?idProduct=${newFreight.idProduct}`,
        null,
        "GET"
      );
      if (res.ok) {
        setWarehouses(res.data);
        if (!(behavior === behaviors.edit)) {
          /*newFreightChange({
            target: { name: "idWarehouse", value: "" },
          });*/
        }
        setFetchWCount(fetchWCount + 1);
      }
    }
  };

  const fetchBallots = async () => {
    const { uploadType } = newFreight;
    if (uploadType !== "") {
      const res = await Request(
        `/ballot/typeUpload?typeUpload=${uploadType}`,
        null,
        "GET"
      );
      if (res.ok) {
        setBallots(res.data);
      }
    }
  };

  const fetchAllBallots = async () => {
    const res = await Request(`/ballot`, null, "GET");
    if (res.ok) {
      setBallotsAll(res.data);
    }
  };

  const fetchQuota = async () => {
    const {
      idCatVehicle,
      idDestination,
      idOrigin,
      uploadType,
      boardingTickets,
      isDestinationWarehouse,
      idFreightQuotaOne,
      idFreightQuotaTwo,
      quota,
    } = newFreight;
    const idBallot = boardingTickets[0]?.idBallot || "";
    if (idCatVehicle !== "" && idDestination !== "" && idOrigin !== "") {
      const [id, originType] = idOrigin.split("_");
      const [idDest] = idDestination.split("_");
      let res1 = {},
        res2 = {};
      const originParam =
        originType === "EJIDO"
          ? "idEjidoOrigin=" + id
          : "idWarehouseOrigin=" + id;
      const destinationParam = !isDestinationWarehouse
        ? "idClientDestination=" + idDest
        : "idWarehouseDestination=" + idDest;
      if (uploadType === "PARTIALLOAD") {
        const ballot =
          [...ballots, ...ballotsAll].find((b) => b.idBallot == idBallot) || {};
        const { idEjido, idWarehouse } = ballot;
        if (idEjido && idWarehouse) {
          res1 = await Request(
            `/boarding/getQuota?idCatVehicle=${idCatVehicle}&idEjidoOrigin=${idEjido}&${destinationParam}`,
            null,
            "GET"
          );
          res2 = await Request(
            `/boarding/getQuota?idCatVehicle=${idCatVehicle}&idWarehouseOrigin=${idWarehouse}&${destinationParam}`,
            null,
            "GET"
          );
        }
      } else if (uploadType === "PARTIALDOWNLOAD") {
        const ballot =
          [...ballots, ...ballotsAll].find((b) => b.idBallot == idBallot) || {};
        const { idEjido, idWarehouse } = ballot;
        if (idEjido && idWarehouse) {
          res1 = await Request(
            `/boarding/getQuota?idCatVehicle=${idCatVehicle}&idEjidoOrigin=${idEjido}&idWarehouseDestination=${idWarehouse}`,
            null,
            "GET"
          );
          res2 = await Request(
            `/boarding/getQuota?idCatVehicle=${idCatVehicle}&idEjidoOrigin=${idEjido}&${destinationParam}`,
            null,
            "GET"
          );
        }
      } else {
        res1 = await Request(
          `/boarding/getQuota?idCatVehicle=${idCatVehicle}&${originParam}&${destinationParam}`,
          null,
          "GET"
        );
      }
      if (res1.ok) {
        const quota1 = res1.data?.[0] || {};
        const quota2 = res2.data?.[0] || {};
        const quotaOne = quota1?.quota || 0;
        const quotaTwo = quota2?.quota || 0;
        const totalQuota = quotaOne + quotaTwo;
        if (
          idFreightQuotaOne !== quota1.idFreightQuota ||
          idFreightQuotaTwo !== quota2.idFreightQuota ||
          quota !== totalQuota
        ) {
          setAllFreight({
            ...newFreight,
            quotaOne,
            quotaTwo,
            quota: totalQuota,
            idFreightQuotaOne: quota1.idFreightQuota || "",
            idFreightQuotaTwo: quota2.idFreightQuota || "",
          });
        }
      }
    }
  };

  const fetchParameters = async () => {
    const { idWarehouse, idProduct } = newFreight;
    if (idWarehouse !== "" && idProduct !== "") {
      const res = await Request(
        `/product/parametersWarehouse?idWarehouse=${idWarehouse}&idProduct=${idProduct}`,
        null,
        "GET"
      );
      if (res.ok) {
        const paramsKeys = [
          "density",
          "firedGrain",
          "impurities",
          "permissibleHumidity",
          "broken",
        ];
        const sParams = {};
        paramsKeys.forEach((k) => {
          const param = res.data[k];
          if (param && newFreight[k] === "") {
            sParams[k] = param;
          }
        });
        setAllFreight({ ...newFreight, ...sParams });
      }
    }
  };

  const handleUploadChange = () => {
    let { uploadType } = newFreight;
    const boardingTickets = [];
    switch (uploadType) {
      case "FULLLOAD":
        boardingTickets.push({ ...boardingTicketSchema, applyBallot: false });
        break;
      case "PARTIALLOAD":
        boardingTickets.push({ ...boardingTicketSchema, applyBallot: true });
        boardingTickets.push({ ...boardingTicketSchema, applyBallot: false });
        break;
      case "PARTIALDOWNLOAD":
        boardingTickets.push({ ...boardingTicketSchema, applyBallot: true });
        boardingTickets.push({ ...boardingTicketSchema, applyBallot: false });
        break;
      case "DIRECTTRIP":
        boardingTickets.push({ ...boardingTicketSchema, applyBallot: true });
        break;
    }
    if (uploadType !== "FULLLOAD") {
      fetchBallots();
    }
    setAllFreight({ ...newFreight, boardingTickets });
  };

  const getOwner = () => {
    const { idVehicle } = newFreight;
    const vehicle = vehicles.find((v) => v.idVehicle === idVehicle) || {};
    const person = persons.find((p) => p.idSupplier == vehicle.idOwner) || {};
    return person.name || "";
  };

  const handleNestedChange = (e, ix) => {
    const { name, value } = e.target;
    const boardingTickets = [...newFreight.boardingTickets];
    let item = { ...boardingTickets[ix] };
    item[name] = value;
    if (["tara", "gross"].includes(name)) {
      getNet(item, (net) => {
        item["net"] = net;
      });
    }
    boardingTickets[ix] = item;
    setAllFreight({ ...newFreight, boardingTickets });
  };

  const handleClientChange = () => {
    const value = newFreight.idClient;
    const {
      contactName,
      phone,
      state = "",
      zipCode = "",
    } = clients.find((c) => c.idClient === value) || {};
    setAllFreight({
      ...newFreight,
      contactName,
      contactPhone: phone,
      state,
      postalCode: zipCode,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredSelects = [
      "uploadType",
      "idUnit",
      "idProduct",
      "idWarehouse",
      "idOrigin",
    ];
    if (!requiredSelects.find((r) => newFreight[r] == "")) {
      const { uploadDate: uD, boardingDate: bD } = newFreight;
      const uploadDate = parseObjectDate(
        new Date(parseInputDate(uD === "" ? TODAY : uD)),
        1
      );
      const boardingDate =
        bD !== "" ? parseObjectDate(new Date(parseInputDate(bD)), 1) : "";
      setIsLoading(true);
      const res = await Request(
        `/boarding${idBoarding ? "/" + idBoarding : ""}`,
        {
          ...newFreight,
          uploadDate,
          boardingDate,
          ...parseOrigin(newFreight),
          ...parseDestination(newFreight),
        },
        behavior === behaviors.edit ? "PUT" : "POST"
      );
      setIsLoading(false);
      if (res.ok) {
        Swal.fire(
          "¡Excelente!",
          `Embarque ${
            behavior === behaviors.edit ? "actualizado" : "creado"
          } exitosamente`,
          "success"
        ).then(() => {
          if (behavior === behaviors.insert) {
            window.location.reload();
          } else {
            navigate("/lista-embarques");
          }
        });
      }
    } else {
      Swal.fire(
        "¡Ops!",
        "Debes llenar todos los campos requeridos antes de continuar",
        "warning"
      );
    }
  };

  const getBallotOriginDestinations = (uploadType, ballot) => {
    let res = { lockOrigin: false };
    const { idEjido, idWarehouse } = ballot;
    switch (uploadType) {
      case "PARTIALLOAD":
        res = {
          lockOrigin: true,
          idOrigin1: idEjido,
          idOrigin: idWarehouse + "_WAREHOUSE",
          idDestination1: undefined,
        };
        break;
      case "PARTIALDOWNLOAD":
        res = {
          lockOrigin: true,
          idOrigin: idEjido + "_EJIDO",
          idOrigin1: idEjido,
          idDestination1: idWarehouse + "_WAREHOUSE",
        };
        break;
      case "DIRECTTRIP":
        res = {
          lockOrigin: true,
          idOrigin: idEjido + "_EJIDO",
          idDestination1: undefined,
        };
        break;
    }
    return res;
  };

  const getDestination1Value = () => {
    let r = "";
    const { uploadType, idDestination, boardingTickets, idBallot } = newFreight;
    if (uploadType === "PARTIALLOAD") {
      r = idDestination;
    } else if (uploadType === "PARTIALDOWNLOAD") {
      r =
        ballotsAll.find(
          ({ idBallot }) => idBallot === boardingTickets[0]?.idBallot
        )?.idWarehouse + "_WAREHOUSE" || "";
    }
    return r;
  };

  const handleBallotChange = (ix) => (e) => {
    const { uploadType, boardingTickets: bt } = newFreight;
    const { name, value } = e.target;
    const boardingTickets = [...bt];
    const ballot = [...ballots, ...ballotsAll].find(
      (b) => b.idBallot === value
    );
    let additionalObject = {};
    let item = boardingTickets[ix];
    if (ballot) {
      const { idProduct, idWarehouse, idVehicle, idCatVehicle, idFreighter } =
        ballot;
      if (
        ["PARTIALLOAD", "PARTIALDOWNLOAD", "DIRECTTRIP"].includes(uploadType)
      ) {
        const vehicle = vehicles.find((v) => v.idVehicle === idVehicle);
        const person =
          persons.find((p) => p.idSupplier === vehicle?.idOwner) || {};
        additionalObject = {
          idProduct,
          idWarehouse,
          idVehicle,
          idCatVehicle,
          idFreighter,
          owner: person.name || "",
          ...getBallotOriginDestinations(uploadType, ballot),
        };
      }
      const { net, tara, gross } = ballot;
      item = { ...item, net, tara, gross };
    } else {
      item = { ...item, net: "", tara: "", gross: "" };
    }
    item[name] = value;
    boardingTickets[ix] = item;
    setAllFreight({ ...newFreight, boardingTickets, ...additionalObject });
  };

  const getFieldsDisabled = () => {
    const { uploadType, idBallot, idCatBoardingStatus } = newFreight;
    if (uploadType !== "FULLLOAD" && idBallot !== "") {
      return true;
    } else {
      return idCatBoardingStatus >= 2;
    }
  };

  const getDestinations = (shouldFilter = true) => {
    if (shouldFilter) {
      return [
        { label: "Elige un destino", value: "" },
        ...(newFreight.isDestinationWarehouse
          ? parseCat(
              catWarehouse
                .filter((w) => {
                  const id = ballotsAll.find(
                    ({ idBallot }) =>
                      idBallot === newFreight.boardingTickets[0]?.idBallot
                  )?.idWarehouse;
                  const [idO, type] = newFreight.idOrigin.split("_");
                  return !(
                    id === w.idWarehouse ||
                    (type === "WAREHOUSE" && idO == w.idWarehouse)
                  );
                })
                .map(({ idWarehouse, name }) => ({
                  v: `${idWarehouse}_WAREHOUSE`,
                  l: name,
                })),
              "l",
              "v"
            )
          : parseCat(
              destinations
                .filter((d) => d.idClient === newFreight.idClient)
                .map((d) => ({
                  v: d.idDestination + "_CLIENT",
                  l: d.clientDestination,
                })),
              "l",
              "v"
            )),
      ];
    }
    return [
      { label: "Elige un destino", value: "" },
      ...parseCat(
        catWarehouse.map(({ idWarehouse, name }) => ({
          v: `${idWarehouse}_WAREHOUSE`,
          l: name,
        })),
        "l",
        "v"
      ),
      ...parseCat(
        destinations.map((d) => ({
          v: d.idDestination + "_CLIENT",
          l: d.clientDestination,
        })),
        "l",
        "v"
      ),
    ];
  };

  const getQuotaPR = () => {
    const { quotaOne, quotaTwo, boardingTickets } = newFreight;
    const [{ net: net1 = 0 } = {}, { net: net2 = 0 } = {}] = boardingTickets;
    const totalWeight = parseFloat(net1 + net2);
    return parseFloat(
      (quotaOne * net1 + quotaTwo * net2) / totalWeight
    )?.toFixed(2);
  };

  useEffect(() => {
    if (idBoarding) {
      setBehavior(behaviors.edit);
      fetchInfo();
    } else {
      setBehavior(behaviors.insert);
    }
  }, []);

  useEffect(() => {
    fetchWarehouses();
  }, [newFreight.idProduct]);

  useEffect(() => {
    handleUploadChange();
  }, [newFreight.uploadType]);

  useEffect(() => {
    fetchParameters();
  }, [newFreight.idWarehouse, newFreight.idProduct]);

  useEffect(() => {
    handleClientChange();
  }, [newFreight.idClient]);

  useEffect(() => {
    fetchQuota();
  }, [newFreight.idOrigin, newFreight.idDestination, newFreight.idCatVehicle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const { destinationWeightTara: tara, destinationWeightGross: gross } =
      newFreight;
    getNet({ tara, gross }, (net) => {
      newFreightChange({
        target: { value: net, name: "destinationWeightNet" },
      });
    });
  }, [newFreight.destinationWeightGross, newFreight.destinationWeightTara]);

  useEffect(() => {
    if (orderData) {
      const { idProduct, idDetailClientOrder, idCatVehicle } = orderData;
      setAllFreight({
        ...newFreight,
        applyClientOrder: true,
        idProduct,
        //idCatVehicle,
        idDetailClientOrder,
      });
    }
  }, [orderData]);

  const selectedClient =
    clients.find((c) => c.idClient === newFreight.idClient) || {};
  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>
          {behavior === behaviors.edit
            ? "EDICIÓN DE EMBARQUE"
            : "ALTA DE EMBARQUES"}
        </PageTitle>
      </div>
      <NtaForm onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
          {behavior === behaviors.edit && (
            <>
              <Input
                type="folio"
                label="Folio"
                name="folio"
                disabled={true}
                value={newFreight.folio}
              />
              <div />
            </>
          )}
          <Select
            arrOptions={[
              { label: "Elige una unidad", value: "" },
              ...parseCat(units, "name", "idUnit"),
            ]}
            required={true}
            name="idUnit"
            onChange={newFreightChange}
            disabled={newFreight.idCatBoardingStatus >= 1}
            value={newFreight.idUnit}
            label="Unidad"
          />
          <Select
            arrOptions={[
              { value: "", label: "Elige un tipo de descarga" },
              ...loadTypeEnum,
            ]}
            required={true}
            name="uploadType"
            onChange={newFreightChange}
            disabled={newFreight.idCatBoardingStatus >= 1}
            value={newFreight.uploadType}
            label="Tipo de carga"
          />
        </div>
        {newFreight.boardingTickets?.map((bt, ix) => (
          <>
            <h3 className="grid w-4/5 mx-auto grid-cols-2 mt-12 text-xl font-bold text-ntaBlue">
              <div>
                {bt.applyBallot ? "TICKET DE BOLETA" : "TICKET ADICIONAL"}
              </div>
            </h3>

            <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
              {bt.applyBallot && (
                <Select
                  arrOptions={[
                    { label: "Elige una boleta", value: "" },
                    ...parseCat(
                      [
                        ...ballots,
                        ...(behavior === behaviors.edit ? ballotsAll : []),
                      ]?.map((b) => ({
                        ...b,
                        name: b.folio + " - " + b.ticket,
                      })),
                      "name",
                      "idBallot"
                    ),
                  ]}
                  disabled={behavior === behaviors.edit}
                  required={true}
                  name="idBallot"
                  onChange={handleBallotChange(ix)}
                  value={bt.idBallot}
                  label="Número de boleta - ticket"
                />
              )}
              {!bt.applyBallot && (
                <Input
                  type="text"
                  required={false}
                  placeholder=""
                  label="Ticket"
                  name="ticket"
                  maxLength={100}
                  className={"grid w-full"}
                  onChange={(e) => {
                    handleNestedChange(e, ix);
                  }}
                  value={bt.ticket}
                  disabled={bt.applyBallot}
                />
              )}
              <Input
                type="number"
                required={true}
                placeholder=""
                label="Bruto"
                name="gross"
                maxLength={100}
                className={"grid w-full"}
                onChange={(e) => {
                  handleNestedChange(e, ix);
                }}
                value={bt.gross}
                disabled={bt.applyBallot}
              />
              <Input
                type="number"
                required={bt.gross && bt.gross !== ""}
                placeholder=""
                label="Tara"
                name="tara"
                maxLength={100}
                className={"grid w-full"}
                onChange={(e) => {
                  handleNestedChange(e, ix);
                }}
                value={bt.tara}
                disabled={bt.applyBallot}
              />
              <Input
                type="number"
                required={true}
                placeholder=""
                label="Neto"
                name="net"
                className={"grid w-full"}
                onChange={(e) => {
                  handleNestedChange(e, ix);
                }}
                value={bt.net}
                disabled={true}
              />
            </div>
          </>
        ))}
        {newFreight.boardingTickets.length === 2 ? (
          <h3 className="w-4/5 mx-auto mt-12 text-xl font-bold text-ntaBlue">
            SUMA DE TICKETS:{" "}
            <b className="inline-block">
              {newFreight.uploadType === "PARTIALDOWNLOAD"
                ? parseInt(
                    newFreight.boardingTickets[0].net -
                      newFreight.boardingTickets[1].net
                  )
                : parseInt(
                    newFreight.boardingTickets[0].net +
                      newFreight.boardingTickets[1].net
                  )}{" "}
              KG
            </b>
          </h3>
        ) : null}
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
          <Select
            arrOptions={[
              { label: "Elige un producto", value: "" },
              ...parseCat(products, "name", "idProduct"),
            ]}
            required={true}
            disabled={getFieldsDisabled()}
            name="idProduct"
            onChange={newFreightChange}
            value={newFreight.idProduct}
            label="Producto"
          />
          <Select
            arrOptions={[
              { label: "Seleccione almacén" },
              ...parseCat(
                warehouses?.filter((e) => e.idUnit === newFreight.idUnit),
                "name",
                "idWarehouse"
              ),
            ]}
            disabled={
              newFreight.idUnit === "" ||
              newFreight.idProduct === "" ||
              getFieldsDisabled()
            }
            required={true}
            name="idWarehouse"
            onChange={newFreightChange}
            value={newFreight.idWarehouse}
            label="Almacén"
          />
          <Select
            arrOptions={[
              ...parseCat(
                clients?.filter((u) => u.isActive),
                "companyName",
                "idClient"
              ),
            ]}
            disabled={newFreight.idCatBoardingStatus >= 2}
            name="idClient"
            onChange={newFreightChange}
            value={newFreight.idClient}
            label="Cliente"
          />
          <Select
            arrOptions={[
              ...parseCat(
                clients?.filter((u) => u.isActive),
                "rfc",
                "idClient"
              ),
            ]}
            required={true}
            disabled={newFreight.idCatBoardingStatus >= 2}
            name="idClient"
            onChange={newFreightChange}
            value={newFreight.idClient}
            label="RFC"
          />
          <Input
            type="text"
            placeholder="Contacto"
            label="Contacto"
            name="contactName"
            maxLength={100}
            onChange={newFreightChange}
            disabled={true}
            value={selectedClient.contactName}
          />
          <Input
            type="text"
            placeholder="55 0000 0000"
            label="Teléfono de contacto"
            name="contactPhone"
            maxLength={14}
            onChange={newFreightChange}
            disabled={true}
            value={selectedClient.phone}
          />
          <Input
            type="text"
            placeholder="Código postal"
            label="Código postal"
            name="postalCode"
            maxLength={5}
            disabled={true}
            onChange={newFreightChange}
            value={selectedClient.zipCode}
          />
          <Select
            arrOptions={[{ value: "", label: "Elige un estado" }, ...statesMx]}
            name="state"
            disabled={true}
            onChange={newFreightChange}
            value={selectedClient.state}
            label="Estado"
          />

          {!["PARTIALLOAD"].includes(newFreight.uploadType) && (
            <>
              <div />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newFreight.isDestinationWarehouse}
                    onChange={(e) => {
                      newFreightChange({
                        target: {
                          name: "isDestinationWarehouse",
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={"Destino es Almacén"}
              />
            </>
          )}

          {newFreight.boardingTickets.length > 1 && (
            <>
              <Select
                arrOptions={[
                  { label: "Elige un origen", value: "" },
                  ...parseCat(ejidos, "name", "idEjido"),
                ]}
                disabled={true}
                name="idOrigin1"
                label="Origen 1"
                onChange={newFreightChange}
                value={
                  ballotsAll.find(
                    ({ idBallot }) =>
                      idBallot === newFreight.boardingTickets[0]?.idBallot
                  )?.idEjido || newFreight.idOrigin1
                }
              />
              <Select
                onChange={newFreightChange}
                label="Destino 1"
                disabled={true}
                arrOptions={getDestinations(false)}
                name="idDestination1"
                value={getDestination1Value()}
              />
            </>
          )}

          <Select
            arrOptions={[
              { label: "Elige un origen", value: "" },
              ...parseCat(
                ejidos
                  .filter((e) => e.isActive)
                  ?.map((e) => ({ v: `${e.idEjido}_EJIDO`, l: e.name })),
                "l",
                "v"
              ),
              ...parseCat(
                catWarehouse?.map(({ idWarehouse, name }) => ({
                  v: `${idWarehouse}_WAREHOUSE`,
                  l: name,
                })),
                "l",
                "v"
              ),
            ]}
            disabled={
              (behavior === behaviors.edit &&
                newFreight.idCatBoardingStatus > 1) ||
              newFreight.lockOrigin
            }
            required={true}
            name="idOrigin"
            onChange={newFreightChange}
            value={newFreight.idOrigin}
            label={`Origen ${
              newFreight.boardingTickets.length > 1 ? "2" : ""
            } `}
          />
          <Select
            value={newFreight.idDestination}
            onChange={newFreightChange}
            label={`Destino ${
              newFreight.boardingTickets.length > 1 ? "2" : ""
            } `}
            disabled={newFreight.idCatBoardingStatus >= 2}
            arrOptions={getDestinations()}
            name="idDestination"
          />
          <Input
            type="date"
            label="Fecha de carga"
            name="uploadDate"
            disabled={newFreight.idCatBoardingStatus >= 2}
            required={true}
            max={TODAY}
            onChange={newFreightChange}
            value={newFreight.uploadDate === "" ? TODAY : newFreight.uploadDate}
          />
          <Input
            type="date"
            disabled={newFreight.idCatBoardingStatus >= 2}
            label="Fecha de embarque"
            name="boardingDate"
            min={newFreight.uploadDate !== "" ? newFreight.uploadDate : TODAY}
            onChange={newFreightChange}
            value={newFreight.boardingDate}
          />
        </div>
        <h3 className="grid w-4/5 mx-auto grid-cols-1 mt-12 text-xl font-bold text-ntaBlue">
          DATOS DEL VEHÍCULO
        </h3>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
          <div>
            <Select
              arrOptions={[...parseCat(catVehicles, "name", "idCatVehicle")]}
              name="idCatVehicle"
              onChange={newFreightChange}
              value={newFreight.idCatVehicle}
              label="Tipo de vehículo"
              disabled={getFieldsDisabled()}
            />
            <Input
              type="text"
              placeholder="Nombre de Propietario"
              label="Propietario"
              name="owner"
              disabled={true}
              maxLength={100}
              onChange={() => {}}
              value={getOwner()}
            />
            <div>
              <Input
                type="number"
                placeholder="Cuota"
                label="Cuota"
                name="quota"
                disabled={true}
                onChange={newFreightChange}
                value={newFreight.quota}
              />
              {newFreight.uploadType === "PARTIALLOAD" && (
                <h3 className="w-full mx-auto mt-12 text-xl text-ntaBlue">
                  Cuota 1:{" "}
                  <b className="inline-block"> {newFreight.quotaOne} </b> <br />
                  <>
                    Cuota 2:{" "}
                    <b className="inline-block"> {newFreight.quotaTwo} </b>
                    <br />
                  </>
                  Cuota pro-rateada:{" "}
                  <b className="inline-block"> {getQuotaPR()} </b>
                </h3>
              )}
            </div>
          </div>

          <div>
            <Select
              arrOptions={[
                ...parseCat(
                  vehicles.filter(
                    (v) => v.idCatVehicle === newFreight.idCatVehicle
                  ),
                  "plates",
                  "idVehicle"
                ),
              ]}
              disabled={getFieldsDisabled()}
              name="idVehicle"
              onChange={newFreightChange}
              value={newFreight.idVehicle}
              label="Placas"
            />
            <Select
              arrOptions={[
                ...parseCat(
                  persons.filter((p) =>
                    p.cat_suppliers.find((r) => r.idCatSupplier === 7)
                  ),
                  "name",
                  "idSupplier"
                ),
              ]}
              name="idFreighter"
              disabled={getFieldsDisabled()}
              onChange={newFreightChange}
              value={newFreight.idFreighter}
              label="Chofer"
            />
            <Input
              type="text"
              placeholder="Factura"
              label="Factura"
              name="invoice"
              maxLength={100}
              className={"grid w-full"}
              onChange={newFreightChange}
              value={newFreight.invoice}
            />
          </div>
        </div>
        <h3 className="grid w-4/5 mx-auto grid-cols-2 mt-12 text-xl font-bold text-ntaBlue">
          <div>CALIDAD</div>
          <a
            onClick={() => {
              setModalOpen(true);
            }}
            className="place-self-end text-ntaBlue hover:text-ntaBlue underline-offset-8 underline cursor-pointer text-lg"
          >
            Obtener certificado de calidad
          </a>
        </h3>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
          <Select
            arrOptions={[
              { label: "Elige un producto", value: "" },
              ...parseCat(products, "name", "idProduct"),
            ]}
            name="idProduct"
            disabled={true}
            onChange={newFreightChange}
            value={newFreight.idProduct}
            label="Producto"
          />
          <Input
            type="number"
            placeholder="0"
            label="Densidad"
            name="density"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.density}
          />
          <Input
            type="number"
            placeholder="0"
            label="Humedad"
            name="humidity"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.humidity}
          />
          <Input
            type="number"
            placeholder="0"
            label="Quebrado"
            name="broken"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.broken}
          />
          <Input
            type="number"
            placeholder="0"
            label="Impurezas"
            name="impurities"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.impurities}
          />
          <Input
            type="number"
            placeholder="0"
            label="Grano fogueado"
            name="firedGrain"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.firedGrain}
          />

          <Input
            type="text"
            placeholder="Color"
            label="Color"
            name="color"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.color}
          />
          <Input
            type="text"
            placeholder="Olor"
            label="Olor"
            name="smell"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.smell}
          />
          <Input
            type="number"
            placeholder="0"
            label="Peso volumétrico"
            name="volumetricWeight"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.volumetricWeight}
          />
          <Input
            type="text"
            placeholder="Aflatoxinas"
            label="Aflatoxinas"
            name="aflatoxinas"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.aflatoxinas}
          />
          <Input
            type="text"
            placeholder="Nombre"
            label="Responsable que firma el certificado"
            name="certificateSigningManager"
            maxLength={100}
            className={"grid w-full"}
            onChange={newFreightChange}
            value={newFreight.certificateSigningManager}
          />
        </div>
        <div className="w-4/5 mx-auto">
          <Input
            type="textarea"
            required={false}
            label="Observaciones"
            placeholder="Observaciones"
            name="comments"
            onChange={newFreightChange}
            value={newFreight.comments}
          />
        </div>
        {newFreight.idCatBoardingStatus >= 2 && (
          <>
            <h3 className="grid w-4/5 mx-auto grid-cols-2 mt-12 text-xl font-bold text-ntaBlue">
              <div>PESO DESTINO</div>
            </h3>
            <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto">
              <Input
                type="text"
                required={false}
                placeholder=""
                label="Ticket"
                name="destinationWeightTicket"
                maxLength={100}
                className={"grid w-full"}
                onChange={newFreightChange}
                value={newFreight.destinationWeightTicket}
                disabled={newFreight.idCatBoardingStatus >= 4}
              />
              <Input
                type="number"
                required={false}
                placeholder=""
                label="Bruto"
                name="destinationWeightGross"
                maxLength={100}
                className={"grid w-full"}
                onChange={newFreightChange}
                value={newFreight.destinationWeightGross}
                disabled={newFreight.idCatBoardingStatus >= 4}
              />
              <Input
                type="number"
                required={false}
                placeholder=""
                label="Tara"
                name="destinationWeightTara"
                maxLength={100}
                className={"grid w-full"}
                onChange={newFreightChange}
                value={newFreight.destinationWeightTara}
                disabled={newFreight.idCatBoardingStatus >= 4}
              />
              <Input
                type="number"
                required={false}
                placeholder=""
                label="Neto"
                min={0}
                disabled={true}
                name="destinationWeightNet"
                className={"grid w-full"}
                onChange={newFreightChange}
                value={newFreight.destinationWeightNet}
              />
            </div>
          </>
        )}
        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4">
          <div />
          <Button
            className="bg-ntaGray"
            onClick={() => {
              setModalPrint(true);
            }}
          >
            Imprimir
          </Button>

          <Button type="submit" disabled={isLoading}>
            {behavior === behaviors.insert ? "Capturar" : "Actualizar"}
          </Button>
        </div>
      </NtaForm>

      <FreightPrinter
        isOpen={modalPrint}
        toggle={setModalPrint}
        onConfirm={() => {
          setModalPrint(false);
        }}
        onCancel={() => {
          setModalPrint(false);
        }}
      />

      <QualityCertificate
        isOpen={modalOpen}
        data={newFreight}
        catalogues={{ products, vehicles, persons }}
        toggle={setModalOpen}
        onConfirm={() => {
          setModalOpen(false);
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default FreightInsert;
