import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import { useForm } from "../helpers/useForm";
import { seasonSchema } from "../helpers/schemas";
import { behaviors } from "../helpers/schemas";
import NtaForm from "../components/NtaForm";
import Input from "../components/Input";
import Button from "../components/Button";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import "../App";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { initialFetch } from "../redux/actions/auth";
import { getFromSession } from "../helpers/storageHandler";
import { useParams } from "react-router-dom";
import { Request } from "../helpers/api";
import { parseCat, parseObjectDate } from "../helpers/utils";
import ButtonIcon from "../components/ButtonIcon";
import { faCheckCircle, faMinus } from "@fortawesome/free-solid-svg-icons";
import Select from "../components/Select";
import ModalSeasonConfirmed from "../components/modals/SeasonConfirmed";

const SeasonInsert = () => {
  const { id: idUnitSeason } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [data, dataChange, dataReset, setAllData] = useForm({
    ...seasonSchema,
  });
  const [behavior, setBehavior] = useState(behaviors.insert);
  const { units } = useSelector((s) => s.catalogues);
  const dispatch = useDispatch();
  const exportFields = {
    ejidos: { text: "Ejidos" },
    warehouses: { text: "Almacenes" },
    numberBallots: { text: "Consecutivo de Boletas" },
    numberReceptions: { text: "Consecutivo de recepciones" },
    numberBoardings: { text: "Concecutivo de Embarques" },
  };

  const submit = async (e) => {
    e.preventDefault();
    const { idUnit } = data;
    if (idUnit !== "") {
      setIsLoading(true);
      const res = await Request(
        `/unit/${idUnit}/season${
          behavior === behaviors.edit ? "/" + idUnitSeason.split("-")[1] : ""
        }`,
        {
          ...data,
          startDate: parseObjectDate(new Date(data.startDate), 1),
          endDate: parseObjectDate(new Date(data.endDate), 1),
        },
        behavior === behaviors.insert ? "POST" : "PUT"
      );
      setIsLoading(false);
      if (res.ok) {
        await dispatch(initialFetch(getFromSession("jwt")));
        Swal.fire({
          title:
            behavior === behaviors.insert
              ? "Registro exitoso"
              : "Actualización exitosa",
          icon: "success",
        }).then(() => {
          dataReset();
          setSingleData(data);
          setModalOpen(true);
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "¡Ops!",
        text: "Debes elegir una unidad para poder continuar",
      });
    }
  };

  const fetchData = useCallback(async () => {
    const [idUnit, idSeason] = idUnitSeason.split("-");
    const res = await Request(
      `/unit/${idUnit}/season/${idSeason}`,
      undefined,
      "GET"
    );
    if (res.ok) {
      setAllData({ name: res.data.name, warehouses: res.data.warehouses });
    }
  });

  const handleExportClick = (name, value) => {
    if (behavior !== behaviors.edit) {
      dataChange({ target: { value, name } });
    }
  };

  useEffect(() => {
    if (idUnitSeason) {
      setBehavior(behaviors.edit);
      fetchData();
    }
  }, []);

  return (
    <>
      <Menu />
      <div className="font-bold text-ntaBlue text-xl w-4/5 mx-auto mt-10 text-center">
        <PageTitle>
          {behavior === behaviors.insert
            ? "TEMPORALIDAD"
            : "EDITAR TEMPORALIDAD"}
        </PageTitle>
      </div>
      <NtaForm onSubmit={submit}>
        <div className="grid grid-cols-2 gap-4 w-4/5 mx-auto inputsForm">
          <Select
            arrOptions={[
              { label: "Seleccione una unidad", value: "" },
              ...parseCat(units, "name", "idUnit"),
            ]}
            disabled={behavior === behaviors.edit}
            required={true}
            name="idUnit"
            onChange={dataChange}
            value={data.idUnit}
            label="Unidad"
          />
          <div />
          <div className="font-bold text-ntaBlue text-xl mx-auto mt-10 subtituloForm">
            DATOS DE TEMPORADA
          </div>
          <div />
          <Input
            type="date"
            required={true}
            label="Fecha inicio Temp"
            name="startDate"
            onChange={dataChange}
            value={data.startDate}
          />
          <Input
            type="date"
            required={true}
            label="Fecha fin Temp"
            name="endDate"
            onChange={dataChange}
            value={data.endDate}
          />
          {behavior === behaviors.insert && (
            <>
              <div className="border-r-2 border-ntaBlue">
                <p
                  className="text-xl mb-5 mt-3 text-center"
                  style={{ color: "#6B6B6B" }}
                >
                  Seleccione las opciones que desea conservar para iniciar la
                  nueva temporada.
                </p>
                <div>
                  {Object.keys(exportFields).map(
                    (k) =>
                      !data[k] && (
                        <div
                          onClick={() => {
                            handleExportClick(k, true);
                          }}
                          className="text-xl cursor-pointer"
                        >
                          <ButtonIcon icon={faCheckCircle} />
                          <span>{exportFields[k].text}</span>
                        </div>
                      )
                  )}
                </div>
              </div>
              <div>
                <p
                  className="text-xl mb-5 mt-3 text-center"
                  style={{ color: "#6B6B6B" }}
                >
                  Las opciones listadas se conservarán en la siguiente
                  temporada:
                </p>
                <div>
                  {Object.keys(exportFields).map(
                    (k) =>
                      data[k] && (
                        <div
                          onClick={() => {
                            handleExportClick(k, false);
                          }}
                          className="text-xl cursor-pointer"
                        >
                          <ButtonIcon icon={faMinus} />
                          <span>{exportFields[k].text}</span>
                        </div>
                      )
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="grid grid-cols-3 gap-4 w-4/5 mx-auto mt-4 inputsForm">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            {behavior === behaviors.insert
              ? "Iniciar temporada"
              : "Actualizar Temporada"}
          </Button>
        </div>
      </NtaForm>
      <ModalSeasonConfirmed
        isOpen={modalOpen}
        data={singleData}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
        exportFields={exportFields}
      />
    </>
  );
};

export default SeasonInsert;
