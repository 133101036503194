import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getColumnsFreights } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { useForm } from "../helpers/useForm";
import { parseCat } from "../helpers/utils";
import NtaDataTable from "../components/NtaDataTable";
import Menu from "../components/Menu";
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Select from "../components/Select";
import Input from "../components/Input";
import "../App";
import "../App.css";
import Swal from "sweetalert2";

const FreightList = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState(allData);
  const [search, setSearch] = useState("");
  const [searchSec, setSearchSec] = useState("");
  const navigate = useNavigate();
  const { catBoardingStatus } = useSelector((s) => s.catalogues);
  const [filters, setFilters, resetFilters] = useForm({
    idCatBoardingStatus: "",
  });
  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/boarding?${filterStr}`, null, "GET");
    setAllData(res.data);
    setData(res.data);
  }, []);
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields =
      lookupField === "all" ? ["origin", "destination"] : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const handleSearchSec = (lookupField, searchValue) => {
    setSearchSec(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields = lookupField === "all" ? ["freighter"] : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const dtFuncs = {
    edit: (row) => {
      navigate("/editar-embarque/" + row.idBoarding);
    },
    delete: (row) => {
      // PERMISSION REQUIRED
      Swal.fire({
        title: "¿Deseas cancelar este embarque " + row.folio + "?",
        text: "Esta acción es irreversible",
        icon: "warning",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const { ok } = await Request(
            "/boarding/" + row.idBoarding,
            {},
            "PATCH"
          );
          if (ok) {
            Swal.fire("Embarque cancelado exitosamente", row.folio, "success");
          }
        }
      });
    },
  };
  useEffect(() => {
    fetchData(filters);
  }, [fetchData, filters]);

  return (
    <>
      <Menu />
      <div
        className="grid grid-cols-1 gap-4 mx-auto mt-8 containerList"
        style={{ width: "90%" }}
      >
        <div className="grid grid-cols-2 containerTituloBtn">
          <PageTitle>LISTA DE EMBARQUES</PageTitle>
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/alta-embarques");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Agregar
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/nuevo-embarque");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-5 inputsForm">
          <Input
            label="Buscar origen/destino"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por origen o destino"
          />
          <Input
            label="Buscar por chofer"
            onChange={(e) => {
              handleSearchSec("all", e.target.value);
            }}
            name="search"
            required={false}
            value={searchSec}
            placeholder="Buscar por chofer"
          />
          <Select
            arrOptions={[
              { value: "", label: "Todos" },
              ...parseCat(catBoardingStatus, "name", "idCatBoardingStatus"),
            ]}
            required={true}
            name="idCatBoardingStatus"
            onChange={setFilters}
            value={filters.idCatBoardingStatus}
            label="Estatus de flete"
          />
        </div>
        <NtaDataTable data={data} columnsDef={getColumnsFreights(dtFuncs)} />
      </div>
    </>
  );
};

export default FreightList;
