import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getColumnsAssignQuotas } from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { useForm } from "../../helpers/useForm";
import { parseCat } from "../../helpers/utils";
import NtaDataTable from "../../components/NtaDataTable";
import Menu from "../../components/Menu";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import Select from "../../components/Select";
import Input from "../../components/Input";
import "../../App";
import "../../App.css";
import BallotDetails from "../../components/modals/BallotDetails";
import Swal from "sweetalert2";

const AssignQuotas = () => {
  const [allData, setAllData] = useState([]);
  const [nestedData, setNestedData] = useState([]);
  const [data, setData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchSec, setSearchSec] = useState("");
  const navigate = useNavigate();
  const fetchData = useCallback(async () => {
    const { data } = await Request(`/supplier/supplierquota/`, null, "GET");
    setData(data);
    setAllData(data);
    setNestedData(data);
  }, []);
  const handleSearch = (lookupField, searchValue) => {
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields =
      lookupField === "all"
        ? ["originField", "product", "role", "supplier", "ejido"]
        : [lookupField];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setNestedData(filteredData);
    setData(filteredData);
  };
  const handleSearchSec = (lookupField, searchValue) => {
    setSearchSec(searchValue);
    const searchLower = searchValue.toLowerCase();
    const filteredData = [];
    const searchFields =
      lookupField === "all"
        ? ["originField", "product", "role", "supplier", "ejido"]
        : [lookupField];
    nestedData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
          return;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const dtFuncs = {
    details: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    edit: (row) => {
      navigate("/editar-cuota/" + row.idSupplierQuota);
    },
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Menu />
      <div
        className="grid grid-cols-1 gap-4 w-4/5 mx-auto mt-8 containerList"
        style={{ width: "90%" }}
      >
        <div className="grid grid-cols-2">
          <PageTitle>LISTA DE CUOTAS ASIGNADAS</PageTitle>
          <div className="w-full">
            <Button
              onClick={() => {
                navigate("/asignar-cuota");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList"
            >
              <FontAwesomeIcon icon={faPlus} /> Asignar Cuota
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                navigate("/asignar-cuota");
              }}
              className="float-right w-1/2 mt-0 text-lg btnList2"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-5 inputsForm">
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearch("all", e.target.value);
            }}
            name="search"
            required={false}
            value={search}
            placeholder="Buscar por ejido, campo, rol, persona, producto..."
          />
          <Input
            label="Buscar"
            onChange={(e) => {
              handleSearchSec("all", e.target.value);
            }}
            name="search"
            required={false}
            value={searchSec}
            placeholder="Buscar por ejido, campo, rol, persona, producto..."
          />

          <Button
            style={{ placeSelf: "end", width: "6rem" }}
            onClick={() => {
              fetchData();
            }}
            className={"w-1/2 px-0 mt-14 mb-2 align-right text-center"}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </Button>
        </div>
        <NtaDataTable
          data={data}
          columnsDef={getColumnsAssignQuotas(dtFuncs)}
        />
      </div>
      <BallotDetails
        isOpen={modalOpen}
        data={singleData}
        toggle={setModalOpen}
      />
    </>
  );
};

export default AssignQuotas;
